/* eslint-disable @typescript-eslint/no-explicit-any */
import { Application, ApplicationAnswers } from '../../types/applications'
import { BASE_API_URL } from '../constants'

export interface ApplicationData {
  organizationId: number
  patientId: string
  serviceId: string
  firstName: string
  lastName: string
  birthDate: string
  zipCode: string
}

interface ArchiveApplicationInput {
  id: number
  archived: boolean
}

export const postApplicationAnswers = async (
  appData: ApplicationAnswers,
): Promise<ApplicationAnswers> => {
  const token = localStorage.getItem('token')
  const response = await fetch(
    `${BASE_API_URL}/v1/surveys/${appData.surveyId}/answer`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(appData),
    },
  )
  if (!response.ok) {
    throw new Error('Failed to submit answers')
  }
  return response.json()
}

export const getApplicationsByOrgId = async (
  id: string,
): Promise<Application[]> => {
  const token = localStorage.getItem('token')
  const response = await fetch(
    `${BASE_API_URL}/v1/organizations/${id}/surveys`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  )
  if (!response.ok) {
    throw new Error('Failed to fetch applications')
  }
  return response.json()
}
// export const getApplicationsByOrgId = async (
//   id: string,
// ): Promise<Application[]> => {
//   const token = localStorage.getItem('token')
//   const response = await fetch(
//     `${BASE_API_URL}/v1/organizations/${id}/surveys`,
//     {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         'Content-Type': 'application/json',
//       },
//     },
//   )
//   if (!response.ok) {
//     throw new Error('Failed to fetch applications')
//   }
//   return response.json()
// }

export const getApplicationsByMemberId = async (
  id: string,
): Promise<Application[]> => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_API_URL}/v1/users/${id}/surveys`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch applications')
  }
  return response.json()
}

export const createApplication = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appData: any,
): Promise<void> => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_API_URL}/v1/surveys`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(appData),
  })
  if (!response.ok) {
    throw new Error('Failed to create application')
  }
  return response.json()
}
interface UpdateApplicationAnswersInput {
  data: any
  id: number
}
export const updateApplicationAnswers = async (
  input: UpdateApplicationAnswersInput,
): Promise<void> => {
  const token = localStorage.getItem('token')
  const res = await fetch(
    `${BASE_API_URL}/v1/survey-answers/${input.id}/answer`,
    {
      method: 'PUT',
      body: JSON.stringify(input.data),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  )
  if (!res.ok) {
    throw new Error('Failed to update application')
  }
  return res.json()
}

export const archiveApplication = async (
  input: ArchiveApplicationInput,
): Promise<void> => {
  const token = localStorage.getItem('token')
  const res = await fetch(`${BASE_API_URL}/v1/surveys/${input.id}/archive`, {
    method: 'POST',
    body: `${input.archived}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!res.ok) {
    throw new Error('Failed to update application archived status')
  }
  return res.json()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateApplication = async (data: any): Promise<void> => {
  const token = localStorage.getItem('token')
  const res = await fetch(`${BASE_API_URL}/v1/surveys/${data.id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!res.ok) {
    throw new Error('Failed to update application')
  }
  return res.json()
}

export const getApplicationById = async (id: number): Promise<Application> => {
  const res = await fetch(`${BASE_API_URL}/v1/surveys/${id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  if (!res.ok) {
    throw new Error('Failed to get application')
  }
  return res.json()
}

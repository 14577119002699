import { Grid } from '@mui/material'
import React from 'react'
import OrgToolbar from '../../toolbars/OrgToolbar'
import MemberInfo from './MemberInfo'
import MemberReferrals from './MemberReferrals'
import MemberNotes from './MemberNotes'
import { useParams } from 'react-router-dom'
import MemberApplications from './MemberApplications'

export default function MemberDashboard(): JSX.Element {
  const { memberId } = useParams()
  return (
    <Grid container sx={{ backgroundColor: 'rgb(239,239,239)' }}>
      <OrgToolbar title='Member Dashboard' />
      <Grid container margin={1} spacing={2}>
        <Grid container item xs={3} spacing={2}>
          <Grid item xs={12}>
            <MemberInfo id={memberId || ''} />
          </Grid>
          <Grid item xs={12}>
            <MemberNotes />
          </Grid>
        </Grid>
        <Grid container item xs={9} spacing={4}>
          <Grid item xs={12}>
            <MemberReferrals memberId={memberId || ''} />
          </Grid>
          <Grid item xs={12}>
            <MemberApplications memberId={memberId || ''} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

import React from 'react'
import { Grid, Typography } from '@mui/material'
import OrgToolbar from '../toolbars/OrgToolbar'

const faq = [
  {
    question: 'question1',
    answer: 'answer1',
  },
]

export default function FAQ(): JSX.Element {
  return (
    <Grid container sx={{ backgroundColor: 'rgb(239,239,239)' }}>
      <OrgToolbar title='Frequently Asked Questions' />
      <Grid container margin={1} spacing={2}>
        {faq.map((section, idx) => {
          return (
            <React.Fragment key={idx}>
              <Grid item xs={12}>
                <Typography variant='h4'>
                  {idx + 1}. {section.question}
                </Typography>
                <Typography variant='h5'>{section.answer}</Typography>
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </Grid>
  )
}

import React, { useMemo, useState } from 'react'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableFooter,
  TableHead,
  TableBody,
  TablePagination,
  IconButton,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getApplicationsByOrgId } from '../../api/applications/applications'
import ArchiveOutlined from '@mui/icons-material/Inventory2Outlined'
import EditIcon from '@mui/icons-material/Edit'
import ArchiveIcon from '@mui/icons-material/Inventory'
import { TableSkeleton } from '../util.tsx/TableUtil'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import { ApplicationDialog } from '../dialogs/ApplicationDialog'
import { Application } from '../../types/applications'
import { ArchiveApplicationDialog } from '../dialogs/ArchiveApplicationDialog'

interface OrgApplicationsProps {
  orgId: string
}

export default function OrgApplications(
  props: OrgApplicationsProps,
): JSX.Element {
  const { orgId } = props
  const { data: applications, isLoading } = useQuery({
    queryKey: ['applications'],
    queryFn: () => getApplicationsByOrgId(orgId),
  })

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [currentApp, setCurrentApp] = useState({} as Application)
  const [edit, setEdit] = useState(false)
  const [openArchiveDialog, setOpenArchive] = useState(false)
  const [openNewDialog, setOpenNewDialog] = useState(false)

  const editApp = (app: Application): void => {
    setEdit(true)
    setCurrentApp(app)
    setOpenNewDialog(true)
  }

  const visibleRows = useMemo(
    () =>
      applications?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, applications],
  )

  const handleArchivedApp = (app: Application): void => {
    setCurrentApp(app)
    setOpenArchive(true)
  }

  return (
    <Grid item xs={12}>
      {openNewDialog && (
        <ApplicationDialog
          orgId={Number(orgId)}
          open={openNewDialog}
          edit={edit}
          app={currentApp}
          onClose={() => {
            setOpenNewDialog(false)
          }}
        />
      )}
      {openArchiveDialog && (
        <ArchiveApplicationDialog
          open={openArchiveDialog}
          onClose={() => {
            setOpenArchive(false)
            if (currentApp.archivedAt) {
              currentApp.archivedAt = null
            } else {
              currentApp.archivedAt = new Date().toISOString()
            }
            setCurrentApp(currentApp)
          }}
          app={currentApp}
        />
      )}
      <Card>
        <CardHeader title='Applications' />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                variant='contained'
                sx={{
                  borderRadius: '100px',
                  textTransform: 'none',
                  float: 'right',
                }}
                onClick={() => setOpenNewDialog(true)}
              >
                + Add New Application
              </Button>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Application ID</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableSkeleton columns={6} />
                ) : (
                  visibleRows?.map((app, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell component='th' scope='row'>
                          {app.id}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {app.data.title}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {app.createTimestamp}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <IconButton onClick={() => editApp(app)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handleArchivedApp(app)}>
                            {app.archivedAt ? (
                              <ArchiveOutlined />
                            ) : (
                              <ArchiveIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: 'All', value: -1 },
                    ]}
                    colSpan={6}
                    count={applications?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(e) => {
                      setRowsPerPage(Number(e.target.value))
                      setPage(0)
                    }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  )
}

import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { isAuthenticated } from '../api/authentication/auth'
import { AuthContext } from '../contexts/AuthContext'
import { useQuery } from '@tanstack/react-query'
import { fetchOneUser } from '../api/users/users'

interface ProtectedRouteProps {
  children: JSX.Element
  isAdminView?: boolean
}
export default function ProtectedRoute(
  props: ProtectedRouteProps,
): JSX.Element {
  const { userId } = useContext(AuthContext)

  const { data: user } = useQuery({
    queryKey: ['users', userId],
    queryFn: () => fetchOneUser(userId || ''),
  })

  const location = useLocation()

  if (!isAuthenticated()) {
    return <Navigate to='/login' state={{ from: location }} replace />
  }
  if (props.isAdminView) {
    if (
      user?.userType === 'EMPLOYEE' &&
      !user.verified &&
      !location.pathname.includes('verify')
    ) {
      return (
        <Navigate
          to='/organization/verify'
          state={{ from: location }}
          replace
        />
      )
    }
    if (user?.userType === 'PATIENT') {
      return <Navigate to='/search' state={{ from: location }} replace />
    }
  }

  return props.children
}

import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  Typography,
  DialogActions,
  Autocomplete,
} from '@mui/material'
import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getApplicationsByOrgId,
  postApplicationAnswers,
} from '../../api/applications/applications'
import { Model, Survey } from 'survey-react'
import { Application, ApplicationAnswers } from '../../types/applications'

interface SubmitApplicationDialogProps {
  orgId: number
  memberId?: number
  open: boolean
  onClose: () => void
}

export function SubmitApplicationDialog(
  props: SubmitApplicationDialogProps,
): JSX.Element {
  const { orgId, memberId } = props
  const [application, setApplication] = useState<Application>({} as Application)
  const [model, setModel] = useState<Model>()

  const { data: apps } = useQuery({
    queryKey: ['applications'],
    queryFn: () => getApplicationsByOrgId(orgId.toString()),
  })

  const queryClient = useQueryClient()

  const applicationMutation = useMutation({
    mutationFn: postApplicationAnswers,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['applications'] })
    },
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (formAnswers: any): void => {
    const answers: ApplicationAnswers = {
      version: 1,
      data: formAnswers,
      surveyId: application.id,
      userId: memberId as number,
      language: 'English',
    }
    applicationMutation.mutate(answers)
    props.onClose()
  }

  const filteredApps = apps?.filter((option) => option.title !== null)

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth='md' fullWidth>
      <DialogTitle>Assign a New Application</DialogTitle>
      <DialogContent>
        {filteredApps?.length && (
          <React.Fragment>
            <Typography variant='h6'>Choose an Application</Typography>
            <Grid
              container
              spacing={2}
              sx={{ marginTop: '10px', marginBottom: '10px' }}
            >
              <Grid item xs={12}>
                <Autocomplete
                  // disablePortal
                  options={apps || []}
                  getOptionLabel={(option: Application) => {
                    if (option) {
                      return option.title + ' V.' + option.version
                    }
                    return ''
                  }}
                  onChange={(e, val) => {
                    setModel(new Model(val?.data))
                    setApplication(val as Application)
                  }}
                  renderInput={(params) => (
                    <TextField
                      margin='dense'
                      variant='outlined'
                      {...params}
                      label='Applications'
                    />
                  )}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {model && (
          <React.Fragment>
            <Typography variant='h6'>Application</Typography>
            <Grid
              container
              spacing={2}
              sx={{ marginTop: '10px', marginBottom: '10px' }}
            >
              <Grid item xs={12}>
                <Survey
                  model={model}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onComplete={(sender: { data: any }) =>
                    handleSubmit(sender.data)
                  }
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant='outlined'
          sx={{ borderRadius: '100px', textTransform: 'none' }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import React from 'react'
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  CircularProgress,
} from '@mui/material'
import { formatServiceTypes } from '../services/util'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import CheckIcon from '@mui/icons-material/Check'
import { useQuery } from '@tanstack/react-query'
import { fetchReferralsByUser } from '../../api/referrals/referrals'
import { Referral } from '../../types/referrals'

export default function SavedApplications(): JSX.Element {
  const { data: applications, isLoading } = useQuery({
    queryKey: ['applications'],
    queryFn: () => fetchReferralsByUser(),
  })

  if (isLoading) {
    return <CircularProgress />
  }

  const renderAppStatus = (status: string): JSX.Element | undefined => {
    switch (status) {
      case 'REQUESTED':
        return (
          <Button
            variant='outlined'
            sx={{ textTransform: 'none' }}
            startIcon={<AccessTimeIcon />}
          >
            Application REQUESTED
          </Button>
        )
      case 'RECEIVED':
        return (
          <Button
            variant='outlined'
            sx={{ textTransform: 'none' }}
            startIcon={<MailOutlineIcon />}
          >
            Application Received
          </Button>
        )
      case 'COMPLETE':
        return (
          <Button
            variant='contained'
            sx={{ textTransform: 'none' }}
            startIcon={<CheckIcon />}
          >
            Application Complete
          </Button>
        )
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ margin: '16px' }}>
        <Typography variant='h5'>Current Applications</Typography>
      </Grid>
      <Grid item xs={12}>
        <List>
          {(applications as Referral[]).map((app, idx) => (
            <ListItem
              key={idx}
              sx={{ backgroundColor: 'white', marginBottom: '5px' }}
            >
              <ListItemText
                primary={formatServiceTypes(app.service.type)}
                secondary={app.service.name}
              />
              {renderAppStatus(app.status)}
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  )
}

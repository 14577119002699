/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import LandingPage from '../homepage/LandingPage'
import LandingPageToolbar from '../toolbars/LandingPageToolbar'
import SearchToolbar from '../toolbars/SearchToolbar'
import SearchPage from '../patients-views/searchpage/SearchPage'
import Application from '../patients-views/application/Application'
import Menu from '../menus/Menu'
import Account from '../account/Account'
import AccountMenu from '../menus/AccountMenu'
import Saves from '../patients-views/saves/Saves'
import ProtectedRoute from './ProtectedRoute'
import { AuthContext } from '../contexts/AuthContext'
import OrgDashboard from '../org-views/organizations/OrgDashboard'
import MemberDashboard from '../org-views/members/MemberDashboard'
import FAQ from '../org-views/faq'
import TermsOfService from './TermsOfService'
import OrgVerification from '../org-views/organizations/OrgVerification'
import ForgotPassword from '../account/ForgotPassword'

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(110,67,169)',
    },
  },
})

function renderRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path='/terms-of-service' element={<TermsOfService />} />
      <Route path='/login' element={<Account />} />
      <Route path='/signup' element={<Account />} />
      <Route path='/employee/signup' element={<Account />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route
        index
        element={
          <ProtectedRoute>
            <LandingPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/search'
        element={
          <ProtectedRoute>
            <SearchPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/apply/:svcId'
        element={
          <ProtectedRoute>
            <Application />
          </ProtectedRoute>
        }
      />
      <Route
        path='/profile/saves/applications'
        element={
          <ProtectedRoute>
            <Saves />
          </ProtectedRoute>
        }
      />
      <Route
        path='/organization/:orgId'
        element={
          <ProtectedRoute isAdminView>
            <OrgDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path='/organization/verify'
        element={
          <ProtectedRoute isAdminView>
            <OrgVerification />
          </ProtectedRoute>
        }
      />
      <Route
        path='/member/:memberId'
        element={
          <ProtectedRoute isAdminView>
            <MemberDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path='/organization/faq'
        element={
          <ProtectedRoute isAdminView>
            <FAQ />
          </ProtectedRoute>
        }
      />
    </Routes>
  )
}

export default function App(): JSX.Element {
  const location = useLocation()
  const { isAuthenticated } = useContext(AuthContext)
  const [open, setOpen] = useState<boolean>(false)

  const AppToolbar = (): JSX.Element | null => {
    if (
      location.pathname.includes('login') ||
      location.pathname.includes('signup')
    )
      return <React.Fragment />
    if (location.pathname === '/')
      return <LandingPageToolbar setOpen={setOpen} />
    if (
      location.pathname.includes('search') ||
      location.pathname.includes('saves')
    )
      return <SearchToolbar setOpen={setOpen} />
    return null
  }

  return (
    <ThemeProvider theme={theme}>
      <AppToolbar />
      {isAuthenticated ? (
        <AccountMenu open={open} setOpen={setOpen} />
      ) : (
        <Menu open={open} setOpen={setOpen} />
      )}
      {renderRoutes()}
    </ThemeProvider>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  IconButton,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  CircularProgress,
} from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import { useParams, useNavigate } from 'react-router-dom'
import { formatServiceTypes, getAcronym } from '../services/util'
import ApplicationSuccess from '../dialogs/ApplicationSuccess'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchOneService } from '../../api/services/services'
import 'survey-core/survey.i18n.js'
import 'survey-creator-core/survey-creator-core.i18n.js'
import 'survey-core/defaultV2.css'
import 'survey-creator-core/survey-creator-core.css'
import {
  getApplicationById,
  postApplicationAnswers,
} from '../../api/applications/applications'
import { AuthContext } from '../../contexts/AuthContext'
import { Model, Survey } from 'survey-react'
import { CreateReferral } from '../../types/referrals'
import { postReferral } from '../../api/referrals/referrals'

export default function Application(): JSX.Element {
  const { svcId } = useParams()
  const { userId } = useContext(AuthContext)
  const [model, setModel] = useState<Model>()

  const { data: service, isLoading } = useQuery({
    queryKey: ['services', svcId],
    queryFn: () => fetchOneService(svcId || ''),
  })

  useEffect(() => {
    if (service?.applicationId) {
      getApplicationById(service?.applicationId).then((app) => {
        setModel(new Model(app.data))
      })
    }
  }, [service])

  const queryClient = useQueryClient()

  const [success, setSuccess] = useState(false)
  const navigate = useNavigate()

  const referralMutation = useMutation({
    mutationFn: postReferral,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['referral'] })
    },
  })

  if (isLoading) {
    return <CircularProgress />
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (formAnswers: any): void => {
    postApplicationAnswers({
      version: 1,
      data: formAnswers,
      surveyId: Number(service?.applicationId),
      userId: Number(userId),
      language: 'English',
    }).then((appData) => {
      const referral: CreateReferral = {
        organizationId: Number(service?.organizationId),
        patientId: Number(userId),
        serviceId: Number(service?.id),
        applicationResponseId: appData.id as number,
      }
      referralMutation.mutate(referral)
    })
    setSuccess(true)
  }

  return (
    <Grid
      container
      sx={{
        padding: '4px 8px 4px 8px',
        color: '#49454F',
        backgroundColor: 'rgb(255,246,255)',
      }}
    >
      {success && <ApplicationSuccess />}
      <Grid item xs={1} display='flex' justifyContent='flex-start'>
        <IconButton
          size='large'
          edge='start'
          aria-label='menu'
          onClick={() => navigate('/search')}
        >
          <ArrowBackIos />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={9}
        display='flex'
        justifyContent='flex-start'
        sx={{ paddingTop: '4px' }}
      >
        <Typography variant='h5'>Application</Typography>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={0}>
          <CardHeader
            avatar={<Avatar>{getAcronym(service?.name[0] || '')}</Avatar>}
            title={service?.name}
            subheader={formatServiceTypes(service?.type || '')}
          />
          <CardContent>
            <Typography>Assistance Form</Typography>
            <Typography variant='body2' sx={{ marginBottom: '16px' }}>
              Fill out this form and you will be contacted by a team member with
              the next steps.
            </Typography>
            <Grid container spacing={4}>
              {model && (
                <Survey
                  model={model}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onComplete={(sender: { data: any }) =>
                    handleSubmit(sender.data)
                  }
                />
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { Grid, TextField, Button, Link, Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { LoginUser } from '../api/authentication/auth'
import { AuthContext } from '../contexts/AuthContext'

export default function Login(): JSX.Element {
  const { isAuthenticated, organizationId, userType } = useContext(AuthContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const navigate = useNavigate()

  const parseApiErrorMessage = (apiError: string): string | null => {
    const regex = /Error: (.+?)\./
    const match = regex.exec(apiError)

    return match ? match[1] : null
  }

  const handleLogin = async (): Promise<void> => {
    const { error } = await LoginUser(email, password)
    if (error == null) {
      window.location.reload()
    }
    setErrorMsg(parseApiErrorMessage(error as string) || '')
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (userType !== 'PATIENT') {
        navigate(`/organization/${organizationId}`)
        return
      }
      navigate('/search')
    }
  }, [isAuthenticated])
  return (
    <Grid container sx={{ marginTop: '32px' }}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label='Enter Email'
          variant='filled'
          error={Boolean(errorMsg)}
          helperText={errorMsg.length ? errorMsg : ''}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '32px' }}>
        <TextField
          fullWidth
          label='Enter Password'
          variant='filled'
          error={Boolean(errorMsg)}
          helperText={errorMsg.length ? errorMsg : ''}
          type='password'
          onChange={(e) => setPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '32px' }}>
        <Button
          fullWidth
          variant='contained'
          sx={{ borderRadius: '100px', textTransform: 'none' }}
          onClick={handleLogin}
        >
          Sign In
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Link
          variant='body1'
          display='flex'
          justifyContent='center'
          href='#'
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate('/forgot-password')}
        >
          Forgot your password?
        </Link>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '32px' }}>
        <Divider>or</Divider>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '16px' }}>
        <Button
          fullWidth
          variant='contained'
          sx={{ borderRadius: '100px', textTransform: 'none' }}
          onClick={() => navigate('/signup')}
        >
          Sign Up
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '16px' }}>
        <Button
          fullWidth
          variant='outlined'
          sx={{ borderRadius: '100px', textTransform: 'none' }}
          onClick={() => navigate('/employee/signup')}
        >
          Employee Sign Up
        </Button>
      </Grid>
    </Grid>
  )
}

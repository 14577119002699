/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react'
import { AppBar, Toolbar, IconButton } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Menu as MenuIcon } from '@mui/icons-material'
import logo from '../public/logos/logo-white.svg'
import { ToolbarProps } from './Toolbar'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import { fetchOneUser } from '../api/users/users'
import { useQuery } from '@tanstack/react-query'

export default function LandingPageToolbar(props: ToolbarProps): JSX.Element {
  const navigate = useNavigate()
  const { setOpen } = props

  const { userId, organizationId } = useContext(AuthContext)

  const { data: user } = useQuery({
    queryKey: ['users', userId],
    queryFn: () => fetchOneUser(userId || ''),
  })

  const handleProfileClick = (): void => {
    if (user?.userType === 'ADMIN') {
      navigate(`/organization/${organizationId}`)
      return
    }
    navigate('/profile/saves/applications')
  }
  return (
    <AppBar
      sx={{
        color: 'white',
        paddingTop: '32px',
        display: 'flex',
        position: 'absolute',
      }}
      color='transparent'
      elevation={0}
      position='static'
    >
      <Toolbar>
        <IconButton
          size='large'
          edge='start'
          color='inherit'
          aria-label='menu'
          sx={{ mr: 2 }}
          onClick={() => setOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <picture
          style={{
            flexGrow: 1,
            textAlign: 'center',
          }}
        >
          <img
            src={logo}
            alt='HealthOPX'
            style={{ height: '40px' }}
            onClick={() => navigate('/search')}
          />
        </picture>
        <AccountCircleIcon
          sx={{ color: 'white', fontSize: '24px', cursor: 'pointer' }}
          onClick={handleProfileClick}
        />
      </Toolbar>
    </AppBar>
  )
}

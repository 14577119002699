/* eslint-disable @typescript-eslint/no-explicit-any */
import { Application } from '../../types/applications'
import { Organization } from '../../types/organizations'
import { BASE_API_URL } from '../constants'

interface Analytics {
  organization: Organization
  membersServed: number
  totalCount: number
  completedCount: number
}

export const getTotalRequestReceived = async (
  id: string,
  date: string,
): Promise<Application[]> => {
  const token = localStorage.getItem('token')
  let url = `${BASE_API_URL}/v1/metrics/totalRequestReceived?orgId=${id}`
  if (date.length) {
    url += `&fromDate=${date}`
  }
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch applications')
  }
  return response.json()
}

export const getMembersReferred = async (
  id: string,
  date: string,
): Promise<number> => {
  const token = localStorage.getItem('token')
  let url = `${BASE_API_URL}/v1/metrics/membersReferred?orgId=${id}`
  if (date.length) {
    url += `&fromDate=${date}`
  }
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch applications')
  }
  return response.json()
}

export const getTotalReferredTo = async (
  id: string,
  date: string,
): Promise<Analytics[]> => {
  const token = localStorage.getItem('token')
  let url = `${BASE_API_URL}/v1/metrics/totalReferredTo?orgId=${id}`
  if (date.length) {
    url += `&fromDate=${date}`
  }
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch applications')
  }
  return response.json()
}

export const getTotalRequestSent = async (
  id: string,
  date: string,
): Promise<Application[]> => {
  const token = localStorage.getItem('token')
  let url = `${BASE_API_URL}/v1/metrics/totalRequestSent?orgId=${id}`
  if (date.length) {
    url += `&fromDate=${date}`
  }
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch applications')
  }
  return response.json()
}

export const getTotalRequestComplete = async (
  id: string,
  inbound: boolean,
  date: string,
): Promise<Application[]> => {
  const token = localStorage.getItem('token')
  let url = `${BASE_API_URL}/v1/metrics/totalRequestComplete?orgId=${id}`
  if (inbound) {
    url += `&inbound=true`
  }
  if (date.length) {
    url += `&fromDate=${date}`
  }
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch applications')
  }
  return response.json()
}

export const getTotalReferralAgencies = async (
  id: string,
  date: string,
): Promise<Analytics[]> => {
  const token = localStorage.getItem('token')
  let url = `${BASE_API_URL}/v1/metrics/totalReferralAgencies?orgId=${id}`
  if (date.length) {
    url += `&fromDate=${date}`
  }
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch applications')
  }
  return response.json()
}

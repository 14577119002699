//localization
import "../localization/arabic";
import "../localization/croatian";
import "../localization/czech";
import "../localization/danish";
import "../localization/dutch";
import "../localization/finnish";
import "../localization/french";
import "../localization/german";
import "../localization/hungarian";
import "../localization/indonesian";
import "../localization/italian";
import "../localization/japanese";
import "../localization/korean";
import "../localization/malay";
import "../localization/mongolian";
import "../localization/norwegian";
import "../localization/persian";
import "../localization/polish";
import "../localization/portuguese";
import "../localization/russian";
import "../localization/simplified-chinese";
import "../localization/slovak";
import "../localization/spanish";
import "../localization/swedish";
import "../localization/tajik";
import "../localization/traditional-chinese";
import "../localization/turkish";

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react'
import { Drawer, List, ListItem, Grid, Divider } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import logoBlue from '../public/logos/logo-blue.svg'
import useMediaQuery from '@mui/material/useMediaQuery'
import { MenuProps } from './Menu'
import { AuthContext } from '../contexts/AuthContext'

export default function OrgMenu(props: MenuProps): JSX.Element {
  const { organizationId } = useContext(AuthContext)
  const { open, setOpen } = props
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleSignOut = (): void => {
    localStorage.clear()
    // TODO, create a signout api to sign out from the backend pool
    window.location.reload()
  }

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ sx: { width: isMobile ? '100%' : '25%' } }}
    >
      <List>
        <Grid container sx={{ marginBottom: '10px' }}>
          <Grid item xs={2}>
            <CloseIcon onClick={() => setOpen(false)} sx={{ padding: '5px' }} />
          </Grid>
          <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center' }}>
            <picture>
              <img
                src={logoBlue}
                alt='HealthOPX'
                style={{ width: '140px' }}
                onClick={() => navigate('/search')}
              />
            </picture>
          </Grid>
        </Grid>
        <ListItem
          sx={{ marginBottom: '8px', cursor: 'pointer' }}
          onClick={() => {
            navigate(`/organization/${organizationId}`)
            setOpen(false)
          }}
        >
          Manage Organization
        </ListItem>
        <Divider />
        <ListItem
          sx={{ marginBottom: '8px', cursor: 'pointer' }}
          onClick={handleSignOut}
        >
          Sign Out
        </ListItem>
      </List>
    </Drawer>
  )
}

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from '@mui/material'
import React from 'react'

export default function MemberNotes(): JSX.Element {
  return (
    <Card>
      <CardHeader title='Case Notes' />
      <CardContent>Add non-medical case notes here</CardContent>
      <CardActions>
        <Button
          variant='contained'
          sx={{ borderRadius: '100px', textTransform: 'none' }}
        >
          + Add a new note
        </Button>
      </CardActions>
    </Card>
  )
}

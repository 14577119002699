import { CreateReferral, Referral, ReferralStatus } from '../../types/referrals'
import { BASE_API_URL } from '../constants'

export const fetchReferralsByUser = async (
  id?: string,
): Promise<Referral[]> => {
  const token = localStorage.getItem('token')
  const userId = id || localStorage.getItem('u=')
  const response = await fetch(
    `${BASE_API_URL}/v1/users/${userId}/service-requests-details`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  )
  if (!response.ok) {
    throw new Error('Failed to fetch services')
  }
  return response.json()
}

export const fetchIncomingReferralsByOrgId = async (
  id?: string,
): Promise<Referral[]> => {
  const token = localStorage.getItem('token')
  const url = `${BASE_API_URL}/v1/organizations/${id}/incoming-service-requests`
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch incoming referrals')
  }
  return response.json()
}

export const fetchOutgoingReferralsByOrg = async (
  id?: string,
): Promise<Referral[]> => {
  const token = localStorage.getItem('token')
  const url = `${BASE_API_URL}/v1/organizations/${id}/service-requests`
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch outgoing referrals')
  }
  return response.json()
}

interface UpdateReferralStatusInput {
  referralId: number
  newStatus: ReferralStatus
}

export const updateReferralStatus = async (
  input: UpdateReferralStatusInput,
): Promise<void> => {
  const token = localStorage.getItem('token')
  const response = await fetch(
    `${BASE_API_URL}/v1/service-requests/${input.referralId}/transition/${input.newStatus}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  )
  if (!response.ok) {
    throw new Error('Failed to update referral')
  }
}

export const postReferral = async (refData: CreateReferral): Promise<void> => {
  const token = localStorage.getItem('token')
  const response = await fetch(
    `${BASE_API_URL}/v1/service-requests/create-application`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(refData),
    },
  )
  if (!response.ok) {
    throw new Error('Failed to create referral')
  }
}

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} from '@mui/material'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import React, { useContext, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { TableSkeleton } from '../util.tsx/TableUtil'
import { AuthContext } from '../../contexts/AuthContext'
import { ViewApplicationDialog } from '../dialogs/ViewApplicationDialog'
import { Application } from '../../types/applications'
import {
  getApplicationById,
  getApplicationsByMemberId,
} from '../../api/applications/applications'
import { SubmitApplicationDialog } from '../dialogs/SubmitApplicationDialog'

interface MemberApplicationsProps {
  memberId: string
}

export default function MemberApplications(
  props: MemberApplicationsProps,
): JSX.Element {
  const { organizationId } = useContext(AuthContext)
  const [createApp, setCreateApp] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [openApp, setOpenApp] = useState(false)
  const [currentApp, setCurrentApp] = useState<Application>({} as Application)
  const [currentAnswers, setCurrentAnswers] = useState<Application>(
    {} as Application,
  )

  const { data: apps, isLoading } = useQuery({
    queryKey: ['applications'],
    queryFn: () => getApplicationsByMemberId(props.memberId),
  })

  const formatTime = (timeStr: string): string => {
    const date = new Date(timeStr)
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
  }

  const visibleRows = useMemo(
    () => apps?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, apps],
  )

  const handleViewApplication = (appId: number, answers: Application): void => {
    getApplicationById(appId).then((app) => {
      setCurrentApp(app)
      setCurrentAnswers(answers)
      setOpenApp(true)
    })
  }

  return (
    <Grid item xs={12}>
      {openApp && (
        <ViewApplicationDialog
          open={openApp}
          onClose={() => setOpenApp(false)}
          app={currentApp}
          answers={currentAnswers}
        />
      )}
      {createApp && (
        <SubmitApplicationDialog
          open={createApp}
          onClose={() => setCreateApp(false)}
          orgId={Number(organizationId)}
          memberId={Number(props.memberId)}
        />
      )}
      <Card>
        <CardHeader title='Applications' />
        <CardContent>
          <Grid container justifyContent='flex-end'>
            <Grid item xs={6}>
              <Button
                variant='contained'
                sx={{
                  borderRadius: '100px',
                  textTransform: 'none',
                  float: 'right',
                }}
                onClick={() => setCreateApp(true)}
              >
                + Create Application
              </Button>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Application Title</TableCell>
                  <TableCell>Date Sent</TableCell>
                  <TableCell>Survey Results</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableSkeleton columns={3} />
                ) : (
                  visibleRows?.map((app, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell component='th' scope='row'>
                          {app.title}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {formatTime(app.createTimestamp)}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <Button
                            onClick={() =>
                              handleViewApplication(app.surveyId, app.data)
                            }
                            variant='outlined'
                            sx={{
                              borderRadius: '100px',
                              textTransform: 'none',
                            }}
                          >
                            View Application
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: 'All', value: -1 },
                    ]}
                    colSpan={3}
                    count={apps?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(e) => {
                      setRowsPerPage(Number(e.target.value))
                      setPage(0)
                    }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  )
}

import React, { useMemo, useState, useEffect } from 'react'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  IconButton,
  TableBody,
  TableFooter,
  TextField,
  Link,
  Chip,
  Switch,
} from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchUsers, putUser } from '../../api/users/users'
import { TableSkeleton } from '../util.tsx/TableUtil'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import { MemberDialog } from '../dialogs/MemberDialog'
import { UserInfo } from '../../types/users'
import { Search } from '@mui/icons-material'

interface OrgMembersProps {
  orgId: string
}

interface MemberFilter {
  firstName: string
  lastName: string
  dob: string
}

export default function OrgEmployees(props: OrgMembersProps): JSX.Element {
  const { orgId } = props
  const [openDialog, setOpenDialog] = useState(false)
  const [filters, setFilters] = useState<MemberFilter>({} as MemberFilter)
  const [filterChips, setFilterChips] = useState<MemberFilter>(
    {} as MemberFilter,
  )

  const [employeeStatuses, setEmployeeStatuses] = useState<{
    [key: string]: boolean
  }>({})

  const { data: users, isLoading } = useQuery({
    queryKey: ['employees'],
    queryFn: () => fetchUsers(orgId, 'EMPLOYEE'),
  })

  const [members, setMembers] = useState<UserInfo[]>(users as UserInfo[])

  useEffect(() => {
    const statusObj: { [key: string]: boolean } = {}
    users?.map((usr) => {
      statusObj[usr.id] = usr.verified
    })
    setEmployeeStatuses(statusObj)
    setMembers(users as UserInfo[])
  }, [users])

  const queryClient = useQueryClient()

  const updateMutation = useMutation({
    mutationFn: putUser,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['employee'] })
    },
  })

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const visibleRows = useMemo(() => {
    if (Array.isArray(members)) {
      return members?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      )
    }
    return [members] as unknown as UserInfo[]
  }, [page, rowsPerPage, members])

  const searchUsers = (): void => {
    if (filters.firstName || filters.lastName || filters.dob) {
      const filteredMembers = users?.filter((member) => {
        if (
          member.firstName.toLowerCase() === filters.firstName?.toLowerCase() ||
          member.lastName.toLowerCase() === filters.lastName?.toLowerCase() ||
          member.dateOfBirth === filters.dob
        ) {
          return true
        }
        return false
      })
      setFilterChips(filters)
      setMembers(filteredMembers as UserInfo[])
    } else {
      setMembers(users as UserInfo[])
    }
  }

  const onEmployeeStatusUpdate = (usr: UserInfo, newStatus: boolean): void => {
    setEmployeeStatuses({ ...employeeStatuses, [usr.id]: newStatus })
    usr.verified = newStatus
    updateMutation.mutate(usr)
  }

  const renderTable = (): JSX.Element | JSX.Element[] => {
    if (isLoading) {
      return <TableSkeleton columns={6} />
    }
    return (
      <React.Fragment>
        {visibleRows.map((member, idx) => {
          return (
            <TableRow key={idx}>
              <TableCell component='th' scope='row'>
                <Link href={'/member/' + member?.id}>{member?.id}</Link>
              </TableCell>
              <TableCell component='th' scope='row'>
                {member?.firstName} {member?.lastName}
              </TableCell>
              <TableCell component='th' scope='row'>
                {member?.email}
              </TableCell>
              <TableCell component='th' scope='row'>
                {member?.phoneNumber}
              </TableCell>
              <TableCell component='th' scope='row'>
                <Switch
                  checked={member?.verified}
                  onClick={() =>
                    onEmployeeStatusUpdate(
                      member,
                      !employeeStatuses[member?.id],
                    )
                  }
                />
              </TableCell>
            </TableRow>
          )
        })}
      </React.Fragment>
    )
  }

  const updateFilters = (key: string): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [key as keyof MemberFilter]: _, ...rest } = filters
    setFilters(rest as MemberFilter)
    setFilterChips(rest as MemberFilter)
  }

  useEffect(() => {
    searchUsers()
  }, [filterChips])

  const renderFilters = (): JSX.Element[] => {
    return Object.entries(filterChips).map(([key, value]) => {
      return (
        <Chip
          key={key}
          label={`${key}=${value}`}
          onDelete={() => updateFilters(key)}
        />
      )
    })
  }

  return (
    <Grid item xs={12}>
      {openDialog && (
        <MemberDialog
          orgId={Number(orgId)}
          open={openDialog}
          onClose={() => setOpenDialog(false)}
        />
      )}
      <Card>
        <CardHeader title='Members' />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={3.66}>
              <TextField
                fullWidth
                margin='dense'
                variant='outlined'
                label='First Name'
                value={filters.firstName || ''}
                onChange={(e) =>
                  setFilters({ ...filters, firstName: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={3.66}>
              <TextField
                fullWidth
                margin='dense'
                variant='outlined'
                label='Last Name'
                value={filters.lastName || ''}
                onChange={(e) =>
                  setFilters({ ...filters, lastName: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                onClick={searchUsers}
                size='large'
                sx={{ marginTop: '10px', float: 'right' }}
              >
                <Search />
              </IconButton>
            </Grid>
          </Grid>
          {renderFilters()}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Member ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Verified</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderTable()}</TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: 'All', value: -1 },
                    ]}
                    colSpan={6}
                    count={members?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(e) => {
                      setRowsPerPage(Number(e.target.value))
                      setPage(0)
                    }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  )
}

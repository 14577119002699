// This dictionary contains 292 untranslated or inherited localization strings.
// These strings are commented out. Uncomment and edit them if you want to add your translations.
import { editorLocalization } from "survey-creator-core";

export var msStrings = {
  // survey templates
  survey: {
    edit: "Edit",
    externalHelpLink: "Tonton dan belajar cara mencipta tinjauan",
    externalHelpLinkUrl: "https://www.youtube.com/channel/UCH2ru9okIcooNZfpIbyq4qQ?view_as=subscriber",
    dropQuestion: "Sila letakkan soalan di sini daripada Kotak Alat",
    addLogicItem: "Cipta peraturan untuk menyesuaikan aliran tinjauan.",
    copy: "Salin",
    duplicate: "Pendua",
    addToToolbox: "Tambahkan kepada kotak alat",
    deletePanel: "Padamkan Panel",
    deleteQuestion: "Padamkan Soalan",
    convertTo: "Tukar kepada",
    drag: "Seret unsur"
  },
  // Question types
  qt: {
    default: "Lalai",
    checkbox: "Kotak semak",
    comment: "Komen",
    imagepicker: "Pemilih imej",
    ranking: "Kedudukan",
    image: "Imej",
    dropdown: "Juntai bawah",
    // tagbox: "Multi-Select Dropdown",
    file: "Fail",
    html: "HTML",
    matrix: "Matriks (pilihan tunggal)",
    matrixdropdown: "Matriks (pilihan berbilang)",
    matrixdynamic: "Matriks (baris dinamik)",
    multipletext: "Teks Berbilang",
    panel: "Panel",
    paneldynamic: "Panel (panel dinamik)",
    radiogroup: "Kumpulan radio",
    rating: "Penarafan",
    text: "Input Tunggal",
    boolean: "Yes/No (Boolean)",
    expression: "Ekspresi (baca sahaja)",
    signaturepad: "Pad tandatangan",
    // buttongroup: "Button Group"
  },
  // Strings in SurveyJS Creator
  ed: {
    defaultLocale: "Lalai ({0})",
    survey: "Tinjauan",
    settings: "Tetapan Tinjauan",
    settingsTooltip: "Buka tetapan tinjauan",
    // surveySettings: "Survey Settings",
    // surveySettingsTooltip: "Open survey settings",
    showPanel: "Tunjukkan Panel",
    hidePanel: "Sembunyikan Panel",
    // prevSelected: "Select previous",
    // nextSelected: "Select next",
    // surveyTypeName: "Survey",
    // pageTypeName: "Page",
    // panelTypeName: "Panel",
    // questionTypeName: "Question",
    // columnTypeName: "Column",
    addNewPage: "Tambahkan Halaman Baharu",
    moveRight: "Tatal ke Kanan",
    moveLeft: "Tatal ke Kiri",
    deletePage: "Padamkan Halaman",
    editPage: "Edit Halaman",
    edit: "Edit",
    newPageName: "halaman",
    newQuestionName: "soalan",
    newPanelName: "panel",
    newTextItemName: "teks",
    testSurvey: "Uji Tinjauan",
    // defaultV2Theme: "Default",
    // modernTheme: "Modern",
    // defaultTheme: "Default (legacy)",
    testSurveyAgain: "Uji Tinjauan Semula",
    testSurveyWidth: "Lebar tinjauan: ",
    navigateToMsg: "Anda perlu navigasi ke:",
    logic: "Logik Tinjauan",
    embedSurvey: "Benamkan Tinjauan",
    translation: "Terjemahan",
    saveSurvey: "Simpan Tinjauan",
    saveSurveyTooltip: "Simpan Tinjauan",
    designer: "Pereka Bentuk Tinjauan",
    jsonEditor: "Editor JSON",
    // jsonHideErrors: "Hide errors",
    // jsonShowErrors: "Show errors",
    undo: "Buat Asal",
    redo: "Buat Semula",
    undoTooltip: "Buat asal perubahan terakhir",
    redoTooltip: "Buat semula perubahan",
    // showMoreChoices: "Show more",
    // showLessChoices: "Show less",
    copy: "Salin",
    cut: "Potong",
    paste: "Tampal",
    copyTooltip: "Salin pilihan kepada papan klip",
    cutTooltip: "Potong pilihan kepada papan klip",
    pasteTooltip: "Tampal daripada papan klip",
    options: "Pilihan",
    generateValidJSON: "Jana JSON yang Sah",
    generateReadableJSON: "Jana JSON yang Boleh Dibaca",
    toolbox: "Kotak Alat",
    "property-grid": "Ciri",
    // propertyGridFilteredTextPlaceholder: "Type to search...",
    toolboxGeneralCategory: "Umum",
    // toolboxChoiceCategory: "Choice Questions",
    // toolboxTextCategory: "Text Input Questions",
    // toolboxContainersCategory: "Containers",
    // toolboxMatrixCategory: "Matrix Questions",
    // toolboxMiscCategory: "Misc",
    correctJSON: "Pilih JSON yang betul.",
    surveyResults: "Keputusan Tinjauan: ",
    surveyResultsTable: "Sebagai Jadual",
    surveyResultsJson: "Sebagai JSON",
    resultsTitle: "Tajuk Soalan",
    resultsName: "Nama Soalan",
    resultsValue: "Nilai Jawapan",
    resultsDisplayValue: "Nilai Paparan",
    modified: "Diubah suai",
    saving: "Menyimpan",
    saved: "Disimpan",
    propertyEditorError: "Ralat:",
    saveError: "Ralat! Kandungan editor tidak disimpan.",
    // translationPropertyGridTitle: "Language Settings",
    // translationLanguages: "Languages",
    translationAddLanguage: "Pilihan bahasa untuk diterjemah",
    translationShowAllStrings: "Tunjukkan semua rentetan",
    // translationShowUsedStringsOnly: "Used Strings Only",
    translationShowAllPages: "Tunjukkan semua halaman",
    translationNoStrings: "Tiada rentetan untuk diterjemah. Sila ubah penapis.",
    translationExportToSCVButton: "Eksport kepada CSV",
    translationImportFromSCVButton: "Import daripada CSV",
    translationMergeLocaleWithDefault: "Gabung {0} dengan tempatan lalai",
    // translationPlaceHolder: "Translation...",
    bold: "Tebal",
    italic: "Condong",
    underline: "Garis bawah",
    addNewQuestion: "Tambahkan soalan",
    selectPage: "Pilih halaman...",
    // htmlPlaceHolder: "HTML content will be here.",
    // panelPlaceHolder: "Drop a question from the toolbox here.",
    // surveyPlaceHolder: "The survey is empty. Drag an element from the toolbox or click the button below.",
    // addNewTypeQuestion: "Add {0}", //{0} is localizable question type
    // chooseLogoPlaceholder: "[LOGO]",
    // auto: "auto",
    lg: {
      // addNewItem: "Add New Rule",
      // empty_tab: "Create a rule to customize the flow of the survey.",
      page_visibilityName: "Kebolehlihatan halaman",
      // page_enableName: "Enable (disable) page",
      panel_visibilityName: "Kebolehlihatan panel",
      panel_enableName: "Dayakan/Lumpuhkan panel",
      question_visibilityName: "Kebolehlihatan soalan",
      question_enableName: "Dayakan/Lumpuhkan soalan",
      question_requireName: "Pilihan soalan diperlukan",
      // column_visibilityName: "Show (hide) column",
      // column_enableName: "Enable (disable) column",
      // column_requireName: "Make column required",
      trigger_completeName: "Lengkapkan tinjauan",
      trigger_setvalueName: "Tetapkan nilai soalan",
      trigger_copyvalueName: "Salin nilai soalan",
      trigger_skipName: "Langkau kepada soalan",
      trigger_runExpressionName: "Jalankan ekspresi tersuai",
      completedHtmlOnConditionName: "Teks 'Halaman terima kasih' tersuai",
      page_visibilityDescription: "Jadikan halaman boleh dilihat apabila ekspresi logik mengembalikan nilai benar. Jika tidak, biarkan tersembunyi.",
      panel_visibilityDescription: "Jadikan panel boleh dilihat apabila ekspresi logik mengembalikan nilai benar. Jika tidak, biarkan tersembunyi.",
      panel_enableDescription: "Jadikan panel dan semua elemen dalamnya didayakan apabila ekspresi logik mengembalikan nilai benar. Jika tidak, biarkan lumpuh.",
      question_visibilityDescription: "Jadikan soalan boleh dilihat apabila ekspresi logik mengembalikan nilai benar. Jika tidak, biarkan tersembunyi.",
      question_enableDescription: "Jadikan soalan didayakan apabila ekspresi logik mengembalikan nilai benar. Jika tidak, biarkan lumpuh.",
      question_requireDescription: "Soalan diperlukan apabila ekspresi logik mengembalikan nilai benar.",
      trigger_completeDescription: "Apabila ekspresi logik mengembalikan nilai benar, maka tinjauan akan lengkap dan pengguna akhir akan melihat 'Halaman terima kasih'.",
      trigger_setvalueDescription: "Apabila nilai soalan, yang digunakan dalam ekspresi logik, berubah dan ekspresi logik mengembalikan nilai benar, maka nilai ditetapkan kepada soalan yang dipilih.",
      trigger_copyvalueDescription: "Apabila nilai soalan, yang digunakan dalam ekspresi logik, berubah dan ekspresi logik mengembalikan nilai benar, maka nilai salah satu soalan yang dipilih akan disalin kepada satu lagi soalan yang dipilih.",
      trigger_skipDescription: "Apabila ekspresi logik mengembalikan nilai benar, maka tinjauan melangkau kepada / fokus pada soalan yang dipilih.",
      trigger_runExpressionDescription: "Apabila ekspresi mengembalikan nilai benar, maka ekspresi tersuai akan dilaksanakan. Anda boleh menetapkan keputusan ekspresi ini kepada soalan yang dipilih secara pilihan",
      completedHtmlOnConditionDescription: "Apabila ekspresi logik mengembalikan nilai benar, maka teks lalai untuk 'Halaman terima kasih' berubah kepada teks yang diberikan.",
      itemExpressionText: "Apabila ekspresi: '{0}' mengembalikan nilai benar:", //{0} - the expression
      // itemEmptyExpressionText: "New rule",
      page_visibilityText: "Jadikan halaman {0} boleh dilihat", //{0} page name
      panel_visibilityText: "Jadikan panel {0} boleh dilihat", //{0} panel name
      panel_enableText: "Jadikan panel {0} didayakan", //{0} panel name
      question_visibilityText: "Jadikan soalan {0} boleh dilihat", //{0} question name
      question_enableText: "Jadikan soalan {0} didayakan", //{0} question name
      question_requireText: "Jadikan soalan {0} diperlukan", //{0} question name
      // column_visibilityText: "make column {0} of question {1} visible", //{0} column name, {1} question name
      // column_enableText: "make column {0} of question {1} enable", //{0} column name, {1} question name
      // column_requireText: "make column {0} of question {1} required", //{0} column name, {1} question name
      trigger_completeText: "Tinjauan dilengkapkan",
      trigger_setvalueText: "Tetapkan kepada soalan: {0} nilai {1}", //{0} question name, {1} setValue
      trigger_copyvalueText: "Salin kepada soalan: {0} nilai daripada soalan {1}", //{0} and {1} question names
      trigger_skipText: "Tinjauan melangkau ke soalan {0}", //{0} question name
      trigger_runExpressionText1: "Jalankan ekspresi: '{0}'", //{0} the expression
      trigger_runExpressionText2: " dan tetapkan keputusannya kepada soalan: {0}", //{0} question name
      completedHtmlOnConditionText: "Tunjukkan teks tersuai untuk 'Halaman terima kasih'.",
      // showAllQuestions: "All Questions",
      // showAllActionTypes: "All Action Types",
      conditions: "Syarat",
      actions: "Tindakan",
      expressionEditorTitle: "Takrifkan syarat",
      actionsEditorTitle: "Takrifkan tindakan",
      deleteAction: "Padamkan tindakan",
      addNewAction: "Tambahkan tindakan baharu",
      selectedActionCaption: "Pilih tindakan untuk ditambahkan...",
      expressionInvalid: "Ekspresi logik kosong atau tidak sah. Sila betulkannya.",
      noActionError: "Sila tambahkan sekurang-kurangnya satu tindakan.",
      actionInvalid: "Sila baiki masalah dalam tindakan anda.",
      // expressionSetup: "",
      // actionsSetup: ""
    }
  },
  // Property Editors
  pe: {
    apply: "Guna",
    ok: "OK",
    save: "Simpan",
    // clear: "Clear",
    saveTooltip: "Simpan",
    cancel: "Batal",
    // set: "Set",
    reset: "Tetap Semula",
    // change: "Change",
    refresh: "Segar semula",
    // close: "Close",
    delete: "Padam",
    add: "Tambahkan",
    addNew: "Tambah Baharu",
    addItem: "Klik untuk menambahkan item...",
    // removeItem: "Click to remove the item...",
    // dragItem: "Drag the item",
    addOther: "Lain-lain",
    addSelectAll: "Pilih Semua",
    addNone: "Tiada",
    removeAll: "Alih Keluar Semua",
    edit: "Edit",
    back: "Kembali tanpa menyimpan",
    backTooltip: "Kembali tanpa menyimpan",
    saveAndBack: "Simpan dan kembali",
    saveAndBackTooltip: "Simpan dan kembali",
    // doneEditing: "Done",
    editChoices: "Edit Pilihan",
    showChoices: "Tunjukkan Pilihan",
    move: "Alih",
    empty: "<empty>",
    // emptyValue: "Value is empty",
    fastEntry: "Entri Pantas",
    // fastEntryNonUniqueError: "Value '{0}' is not unique",
    // fastEntryChoicesCountError: "Please limit the number of items from {0} to {1}",
    // fastEntryPlaceholder: "You can set data in the following format:\nvalue1|text\nvalue2",
    formEntry: "Entri Borang",
    testService: "Uji perkhidmatan",
    itemSelectorEmpty: "Sila pilih elemen",
    conditionActionEmpty: "Sila pilih tindakan",
    conditionSelectQuestion: "Pilih soalan...",
    conditionSelectPage: "Pilih halaman...",
    conditionSelectPanel: "Pilih panel...",
    conditionValueQuestionTitle: "Sila masukkan/pilih nilai",
    expressionHelp: "Anda boleh gunakan kurungan kerinting untuk mengakses nilai soalan: {soalan1} + {soalan2}, ({harga}*{kuantiti}) * (100 - {diskaun}). Anda boleh menggunakan fungsi seperti: iif(), today(), age(), min(), max(), count(), avg() dan lain-lain.",
    aceEditorHelp: "Tekan ctrl+space untuk mendapatkan petunjuk lengkapan ekspresi",
    aceEditorRowTitle: "Baris semasa",
    aceEditorPanelTitle: "Panel semasa",
    showMore: "Untuk butiran lanjut, sila semak dokumentasi",
    assistantTitle: "Soalan tersedia:",
    cellsEmptyRowsColumns: "Terdapat sekurang-kurangnya satu lajur atau baris",
    // showPreviewBeforeComplete: "Preview answers before submitting the survey",
    propertyIsEmpty: "Sila masukkan nilai",
    propertyIsNoUnique: "Sila masukkan nilai unik",
    propertyNameIsNotUnique: "Sila masukkan nama unik",
    listIsEmpty: "Tambahkan item baharu",
    // "listIsEmpty@choices": "No choices have been added yet",
    // "addNew@choices": "Add a choice",
    expressionIsEmpty: "Ekspresi kosong",
    value: "Nilai",
    text: "Teks",
    rowid: "ID Baris",
    imageLink: "Pautan Imej",
    columnEdit: "Edit lajur: {0}",
    itemEdit: "Edit item: {0}",
    url: "URL",
    path: "Laluan",
    valueName: "Nama nilai",
    choicesbyurl: {
      // valueName: "Get values from the following JSON field"
    },
    titleName: "Nama tajuk",
    // imageLinkName: "Get image URLs from the following JSON field",
    allowEmptyResponse: "Benarkan respons kosong",
    titlePlaceholder: "Tajuk input di sini",
    surveyTitlePlaceholder: "Tajuk tinjauan input di sini",
    pageTitlePlaceholder: "Tajuk halaman input di sini",
    descriptionPlaceholder: "Masukkan perihalan",
    surveyDescriptionPlaceholder: "Masukkan perihalan tinjauan",
    pageDescriptionPlaceholder: "Masukkan perihalan halaman",
    showOtherItem: "Ada item lain",
    otherText: "Teks item lain",
    showNoneItem: "Tiada item",
    noneText: "Tiada teks item",
    showSelectAllItem: "Telah pilih semua item",
    selectAllText: "Pilih semua teks item",
    choicesMin: "Nilai minimum untuk item auto dijana",
    choicesMax: "Nilai maksimum untuk item auto dijana",
    choicesStep: "Perbezaan antara item auto dijana",
    name: "Nama",
    title: "Tajuk",
    cellType: "Jenis sel",
    colCount: "Kiraan lajur",
    choicesOrder: "Pilih susunan pilihan",
    visible: "Boleh dilihat?",
    isRequired: "Diperlukan?",
    isAllRowRequired: "Perlukan jawapan untuk semua baris",
    requiredErrorText: "Teks ralat diperlukan",
    startWithNewLine: "Mulakan dengan baris baharu?",
    rows: "Kiraan baris",
    cols: "Kiraan lajur",
    placeholder: "Ruang letak input",
    showPreview: "Tunjukkan kawasan pratonton",
    storeDataAsText: "Simpan kandungan fail dalam keputusan JSON sebagai teks",
    maxSize: "Maksimum saiz fail dalam bait",
    imageHeight: "Tinggi imej",
    imageWidth: "Lebar imej",
    rowCount: "Kiraan baris",
    columnLayout: "Tataletak lajur",
    addRowLocation: "Tambahkan lokasi butang baris",
    addRowText: "Tambahkan teks butang baris",
    removeRowText: "Alih keluar teks butang baris",
    rateMin: "Kadar minimum",
    rateMax: "Kadar maksimum",
    rateStep: "Langkah kadar",
    minRateDescription: "Perihalan kadar minimum",
    maxRateDescription: "Perihalan kadar maksimum",
    inputType: "Jenis input",
    optionsCaption: "Kapsyen pilihan",
    defaultValue: "Nilai lalai",
    cellsDefaultRow: "Teks sel lalai",
    surveyEditorTitle: "Edit tetapan tinjauan",
    qEditorTitle: "Edit: {0}",
    maxLength: "Panjang maksimum",
    buildExpression: "Bina",
    editExpression: "Edit",
    // and: "and",
    // or: "or",
    remove: "Alih keluar",
    addCondition: "Tambahkan syarat",
    // emptyLogicPopupMessage: "Select a question to start configuring conditions.",
    // if: "If",
    // then: "then",
    // setToName: "Target question",
    // fromName: "Question to copy answer from",
    // gotoName: "Question to skip to",
    // ruleIsNotSet: "Rule is incorrect",
    // includeIntoResult: "Include into survey results",
    showTitle: "Tunjukkan/sembunyikan tajuk",
    expandCollapseTitle: "Kembangkan/runtuhkan tajuk",
    locale: "Bahasa lalai",
    simulator: "Pilih peranti",
    landscapeOrientation: "Landskap",
    // portraitOrientation: "Switch to portrait orientation",
    mode: "Mod (edit/baca sahaja)",
    clearInvisibleValues: "Kosongkan nilai tersembunyi",
    cookieName: "Nama kuki (untuk melumpuhkan perlaksanaan tinjauan dua kali secara setempat)",
    sendResultOnPageNext: "Hantar keputusan tinjauan pada halaman seterusnya",
    storeOthersAsComment: "Simpan nilai 'lain-lain' dalam medan berasingan",
    showPageTitles: "Tunjukkan tajuk halaman",
    showPageNumbers: "Tunjukkan nombor halaman",
    pagePrevText: "Teks butang halaman sebelumnya",
    pageNextText: "Teks butang halaman seterusnya",
    completeText: "Teks butang lengkapkan",
    previewText: "Teks butang pratonton",
    editText: "Edit teks butang",
    startSurveyText: "Teks butang mulakan",
    showNavigationButtons: "Tunjukkan butang navigasi (navigasi lalai)",
    showPrevButton: "Tunjukkan butang sebelumnya (pengguna boleh kembali ke halaman seterusnya)",
    firstPageIsStarted: "Halaman pertama dalam tinjauan ialah halaman permulaan.",
    showCompletedPage: "Tunjukkan halaman lengkap pada hujung (HTML dilengkapkan)",
    goNextPageAutomatic: "Selepas menjawab semua soalan, pergi ke halaman seterusnya secara automatik",
    showProgressBar: "Tunjukkan bar perkembangan",
    questionTitleLocation: "Lokasi tajuk soalan",
    requiredText: "Soalan memerlukan simbol",
    questionStartIndex: "Indeks mula soalan (1, 2 atau 'A', 'a')",
    showQuestionNumbers: "Tunjukkan nombor soalan",
    questionTitleTemplate: "Templat tajuk soalan, lalai ialah: '{tidak}. {perlukan} {tajuk}'",
    questionErrorLocation: "Lokasi ralat soalan",
    focusFirstQuestionAutomatic: "Fokus soalan pertama pada perubahan halaman",
    questionsOrder: "Susunan elemen pada halaman",
    maxTimeToFinish: "Masa maksimum untuk menyelesaikan tinjauan",
    maxTimeToFinishPage: "Masa maksimum untuk menyelesaikan halaman dalam tinjauan",
    page: {
      // maxTimeToFinish: "Time limit to finish the page (in seconds)"
    },
    question: {
      // page: "Parent page"
    },
    showTimerPanel: "Tunjukkan panel pemasa",
    showTimerPanelMode: "Tunjukkan mod panel pemasa",
    renderMode: "Mod penyampaian",
    allowAddPanel: "Benarkan menambahkan panel",
    allowRemovePanel: "Benarkan alih keluar panel",
    // noEntriesText: "Empty entries text",
    panelAddText: "Menambahkan teks panel",
    panelRemoveText: "Mengalih keluar teks panel",
    isSinglePage: "Tunjukkan semua elemen pada satu halaman",
    html: "HTML",
    expression: "Ekspresi",
    // setValue: "Answer",
    // dataFormat: "Image format",
    // allowAddRows: "Allow adding rows",
    // allowRemoveRows: "Allow removing rows",
    // allowRowsDragAndDrop: "Allow row drag and drop",
    // responsiveImageSizeHelp: "Does not apply if you specify the exact image width or height.",
    // minImageWidth: "Minimum image width",
    // maxImageWidth: "Maximum image width",
    // minImageHeight: "Minimum image height",
    // maxImageHeight: "Maximum image height",
    minValue: "Nilai minimum",
    maxValue: "Nilai maksimum",
    minLength: "Panjang minimum",
    allowDigits: "Benarkan digit",
    minCount: "Bilangan minimum",
    maxCount: "Bilangan maksimum",
    regex: "Ekspresi biasa",
    surveyvalidator: {
      // text: "Error message",
      // expression: "Validation expression"
    },
    totalText: "Jumlah teks",
    totalType: "Jumlah jenis",
    totalExpression: "Jumlah ekspresi",
    totalDisplayStyle: "Jumlah gaya paparan",
    totalCurrency: "Jumlah mata wang",
    totalFormat: "Jumlah format",
    // logo: "Logo (URL or base64-encoded string)",
    // questionsOnPageMode: "Survey structure",
    // maxTextLength: "Maximum answer length (in characters)",
    // maxOthersLength: "Maximum comment length (in characters)",
    // autoGrowComment: "Auto-expand comment area if necessary",
    // textUpdateMode: "Update text question value",
    // focusOnFirstError: "Set focus on the first invalid answer",
    // checkErrorsMode: "Run validation",
    // navigateToUrl: "Navigate to URL",
    // navigateToUrlOnCondition: "Dynamic URL",
    // completedBeforeHtml: "Markup to show if the user already filled out this survey",
    // completedHtml: "Survey Complete page markup",
    // completedHtmlOnCondition: "Dynamic Survey Complete page markup",
    // loadingHtml: "Markup to show while survey model is loading",
    // commentText: "Comment area text",
    // autocomplete: "Autocomplete type",
    // labelTrue: "\"True\" label",
    // labelFalse: "\"False\" label",
    allowClear: "Tunjukkan kapsyen pilihan",
    // displayStyle: "Value display style",
    // format: "Formatted string",
    // maximumFractionDigits: "Maximum fractional digits",
    // minimumFractionDigits: "Minimum fractional digits",
    // useGrouping: "Display grouping separators",
    // allowMultiple: "Allow multiple files",
    // allowImagesPreview: "Preview images",
    // acceptedTypes: "Accepted file types",
    // waitForUpload: "Wait for the upload to complete",
    // needConfirmRemoveFile: "Confirm file deletion",
    // detailPanelMode: "Detail panel location",
    // minRowCount: "Minimum row count",
    // maxRowCount: "Maximum row count",
    // confirmDelete: "Confirm row deletion",
    // confirmDeleteText: "Confirmation message",
    paneldynamic: {
      // confirmDelete: "Confirm panel deletion"
    },
    // panelCount: "Initial panel count",
    // minPanelCount: "Minimum panel count",
    // maxPanelCount: "Maximum panel count",
    // panelsState: "Inner panel expand state",
    // templateDescription: "Description template",
    // templateTitle: "Title template",
    // panelPrevText: "Previous Panel button tooltip",
    // panelNextText: "Next Panel button tooltip",
    // showRangeInProgress: "Show progress bar",
    // templateTitleLocation: "Question title location",
    // panelRemoveButtonLocation: "Remove Panel button location",
    // hideIfRowsEmpty: "Hide the question if there are no rows",
    // hideColumnsIfEmpty: "Hide columns if there are no rows",
    // rateValues: "Custom rate values",
    // hideIfChoicesEmpty: "Hide the question if it contains no choices",
    // hideNumber: "Hide question number",
    // minWidth: "Minimum width (in CSS-accepted values)",
    // maxWidth: "Maximum width (in CSS-accepted values)",
    // width: "Width (in CSS-accepted values)",
    // showHeader: "Show column headers",
    // horizontalScroll: "Show horizontal scrollbar",
    // columnMinWidth: "Minimum column width (in CSS-accepted values)",
    // rowTitleWidth: "Row header width (in CSS-accepted values)",
    // valueTrue: "\"True\" value",
    // valueFalse: "\"False\" value",
    // minErrorText: "\"Value is below minimum\" error message",
    // maxErrorText: "\"Value exceeds maximum\" error message",
    // otherErrorText: "\"Empty comment\" error message",
    // keyDuplicationError: "\"Non-unique key value\" error message",
    // maxSelectedChoices: "Maximum selected choices",
    // showClearButton: "Show the Clear button",
    // showNumber: "Show panel number",
    // logoWidth: "Logo width (in CSS-accepted values)",
    // logoHeight: "Logo height (in CSS-accepted values)",
    // readOnly: "Read-only",
    // enableIf: "Editable if",
    // emptyRowsText: "\"No rows\" message",
    // size: "Input size (in characters)",
    // separateSpecialChoices: "Separate special choices (None, Other, Select All)",
    // choicesFromQuestion: "Copy choices from the following question",
    // choicesFromQuestionMode: "Which choices to copy?",
    // showCommentArea: "Show the comment area",
    // commentPlaceholder: "Comment area placeholder",
    // displayRateDescriptionsAsExtremeItems: "Display rate descriptions as extreme values",
    // rowsOrder: "Row order",
    // columnsLayout: "Column layout",
    // columnColCount: "Nested column count",
    // state: "Panel expand state",
    // correctAnswer: "Correct Answer",
    // defaultPanelValue: "Default Values",
    // cells: "Cell Texts",
    // keyName: "Key column",
    itemvalue: {
      // text: "Alt text"
    },
    logoPosition: "Kedudukan logo",
    addLogo: "Tambahkan logo...",
    changeLogo: "Ubah logo...",
    logoPositions: {
      none: "Alih keluar logo",
      left: "Kiri",
      right: "Kanan",
      top: "Di atas",
      bottom: "Di bawah"
    },
    tabs: {
      general: "Umum",
      fileOptions: "Pilihan",
      html: "Editor HTML",
      columns: "Lajur",
      rows: "Baris",
      choices: "Pilihan",
      items: "Item",
      visibleIf: "Boleh Dilihat Jika",
      enableIf: "Dayakan Jika",
      requiredIf: "Diperlukan Jika",
      rateValues: "Nilai Kadar",
      choicesByUrl: "Pilihan daripada Web",
      matrixChoices: "Pilihan Lalai",
      multipleTextItems: "Input Teks",
      numbering: "Nombor",
      validators: "Pengesah",
      navigation: "Navigasi",
      question: "Soalan",
      pages: "Halaman",
      timer: "Pemasa/Kuiz",
      calculatedValues: "Nilai Dikira",
      triggers: "Pencetus",
      templateTitle: "Tajuk templat",
      totals: "Jumlah",
      logic: "Logik",
      layout: "Tataletak",
      data: "Data",
      validation: "Pengesahan",
      cells: "Sel",
      showOnCompleted: "Tunjukkan pada Dilengkapkan",
      logo: "Logo dalam Tajuk Tinjauan",
      slider: "Penggelongsor",
      // expression: "Expression",
      others: "Lain-lain"
    },
    editProperty: "Edit ciri '{0}'",
    items: "[ Item: {0} ]",
    // choicesVisibleIf: "Choices are visible if",
    // choicesEnableIf: "Choices are selectable if",
    // columnsEnableIf: "Columns are visible if",
    // rowsEnableIf: "Rows are visible if",
    // indent: "Add indents",
    panel: {
      // indent: "Add outer indents"
    },
    // innerIndent: "Add inner indents",
    // defaultValueFromLastRow: "Take default values from the last row",
    // defaultValueFromLastPanel: "Take default values from the last panel",
    enterNewValue: "Sila masukkan nilai.",
    noquestions: "Tiada soalan lain dalam tinjauan.",
    createtrigger: "Sila cipta pencetus",
    titleKeyboardAdornerTip: "Tekan butang enter untuk mengedit",
    keyboardAdornerTip: "Tekan butang enter untuk mengedit item, tekan butang padam untuk memadamkan item, tekan butang alt dan anak panah ke atas atau ke bawah untuk menggerakkan item",
    triggerOn: "Hidup ",
    triggerMakePagesVisible: "Jadikan halaman boleh dilihat:",
    triggerMakeQuestionsVisible: "Buat elemen boleh dilihat:",
    triggerCompleteText: "Lengkapkan tinjauan jika berjaya.",
    triggerNotSet: "Pencetus tidak ditetapkan",
    triggerRunIf: "Jalankan jika",
    triggerSetToName: "Ubah nilai: ",
    triggerFromName: "Salin nilai daripada: ",
    triggerRunExpression: "Jalankan Ekspresi ini:",
    triggerSetValue: "kepada: ",
    triggerGotoName: "Pergi kepada soalan:",
    triggerIsVariable: "Jangan letakkan pemboleh ubah dalam keputusan tinjauan.",
    triggerRunExpressionEmpty: "Sila masukkan ekspresi yang sah",
    // emptyExpressionPlaceHolder: "Type expression here...",
    noFile: "Tiada fail dipilih",
    // clearIfInvisible: "Clear the value if the question becomes hidden",
    // valuePropertyName: "Value property name",
    // searchEnabled: "Enable search",
    // hideSelectedItems: "Hide selected items",
    // signatureWidth: "Signature width",
    // signatureHeight: "Signature height",
    // verticalAlign: "Vertical alignment",
    // alternateRows: "Alternate rows",
    // columnsVisibleIf: "Columns are visible if",
    // rowsVisibleIf: "Rows are visible if",
    // otherPlaceholder: "Comment area placeholder"
  },
  // Property values
  pv: {
    // true: "true",
    // false: "false",
    inherit: "warisi",
    show: "tunjukkan",
    hide: "sembunyikan",
    default: "lalai",
    initial: "awal",
    random: "rawak",
    collapsed: "kuncup",
    expanded: "kembang",
    none: "tiada",
    asc: "tertib menaik",
    desc: "menurun",
    indeterminate: "tidak tentu",
    // decimal: "decimal",
    // currency: "currency",
    // percent: "percent",
    firstExpanded: "Pertama dikembangkan",
    off: "mati",
    // onpanel: "Start on each panel",
    onPanel: "Pada panel",
    onSurvey: "Pada tinjauan",
    list: "senarai",
    progressTop: "Perkembangan atas",
    progressBottom: "Perkembangan bawah",
    progressTopBottom: "Perkembangan atas bawah",
    horizontal: "mendatar",
    vertical: "menegak",
    top: "atas",
    bottom: "bawah",
    topBottom: "atas dan bawah",
    both: "kedua-duanya",
    left: "baki",
    // right: "Right",
    // color: "color",
    // date: "date",
    // datetime: "datetime",
    // "datetime-local": "datetime-local",
    // email: "email",
    // month: "month",
    // number: "number",
    // password: "password",
    // range: "range",
    // tel: "tel",
    // text: "text",
    // time: "time",
    // url: "url",
    // week: "week",
    hidden: "tersembunyi",
    on: "pada",
    onPage: "Pada halaman",
    edit: "edit",
    display: "paparan",
    onComplete: "Pada selesai",
    onHidden: "Pada tersembunyi",
    // onHiddenContainer: "When the question or its panel/page becomes hidden",
    clearInvisibleValues: {
      // none: "Never"
    },
    all: "semua",
    page: "halaman",
    survey: "tinjauan",
    onNextPage: "Pada halaman berikutnya",
    onValueChanged: "Pada nilai berubah",
    // onValueChanging: "Before an answer is changed",
    standard: "standard",
    singlePage: "halaman tunggal",
    questionPerPage: "soalan bagi setiap halaman",
    noPreview: "tiada pratonton",
    showAllQuestions: "tunjukkan pratonton dengan semua soalan",
    showAnsweredQuestions: "tunjukkan pratonton dengan soalan dijawab",
    pages: "halaman",
    questions: "soalan",
    requiredQuestions: "soalan diperlukan",
    correctQuestions: "soalan betul",
    buttons: "butang",
    underInput: "input di bawah",
    underTitle: "tajuk di bawah",
    // onBlur: "On blur",
    // onTyping: "While typing",
    // underRow: "Under the row",
    // underRowSingle: "Under the row, only one panel is visible",
    showNavigationButtons: {
      // none: "Hidden"
    },
    showProgressBar: {
      // off: "Hidden"
    },
    showTimerPanel: {
      // none: "Hidden"
    },
    showTimerPanelMode: {
      // all: "Both"
    },
    detailPanelMode: {
      // none: "Hidden"
    },
    addRowLocation: {
      // default: "Depends on matrix layout"
    },
    panelsState: {
      // default: "Users cannot expand or collapse panels",
      // collapsed: "All panels are collapsed",
      // expanded: "All panels are expanded"
    },
    widthMode: {
      // auto: "Auto",
      // static: "Static",
      // responsive: "Responsive"
    },
    imageFit: {
      // none: "None",
      // contain: "Contain",
      // cover: "Cover",
      // fill: "Fill"
    },
    contentMode: {
      // auto: "Auto",
      // image: "Image",
      // video: "Video",
      // youtube: "YouTube"
    },
    displayMode: {
      // auto: "Auto",
      // buttons: "Buttons",
      // dropdown: "Dropdown"
    }
  },
  // Operators
  op: {
    empty: "kosong",
    notempty: "tidak kosong",
    equal: "sama dengan",
    notequal: "tidak sama dengan",
    contains: "mengandungi",
    notcontains: "tidak mengandungi",
    anyof: "sebarang",
    allof: "semua",
    greater: "lebih besar",
    less: "kurang",
    greaterorequal: "lebih besar atau sama dengan",
    lessorequal: "kurang atau sama dengan",
    // and: "and",
    // or: "or"
  },
  // Embed window
  ew: {
    angular: "Guna versi Angular",
    jquery: "Guna versi jQuery",
    knockout: "Guna versi Knockout",
    react: "Guna versi React",
    vue: "Guna versi Vue",
    bootstrap: "Untuk kerangka kerja butstrap",
    modern: "Tema moden",
    default: "Tema lalai",
    orange: "Tema oren",
    darkblue: "Tema biru gelap",
    darkrose: "Tema mawar gelap",
    stone: "Tema batu",
    winter: "Tema musim sejuk",
    winterstone: "Tema batu-musim sejuk",
    showOnPage: "Tunjukkan tinjauan pada halaman",
    showInWindow: "Tunjukkan tinjauan pada tetingkap",
    loadFromServer: "Muatkan Tinjauan JSON daripada pelayan",
    titleScript: "Skrip dan gaya",
    titleHtml: "HTML",
    titleJavaScript: "JavaScript"
  },
  // Preview (Survey)
  ts: {
    selectPage: "Pilih halaman untuk mengujinya:",
    showInvisibleElements: "Pilih elemen tersembunyi",
    // hideInvisibleElements: "Hide invisible elements"
  },
  validators: {
    answercountvalidator: "kiraan jawapan",
    emailvalidator: "e-mel",
    expressionvalidator: "ekspresi",
    numericvalidator: "numerik",
    regexvalidator: "regex",
    textvalidator: "teks"
  },
  triggers: {
    completetrigger: "lengkapkan tinjauan",
    setvaluetrigger: "tetapkan nilai",
    copyvaluetrigger: "salin nilai",
    skiptrigger: "langkau kepada soalan",
    runexpressiontrigger: "jalankan ekspresi",
    visibletrigger: "ubah kebolehlihatan (tidak digunakan lagi)"
  },
  pehelp: {
    // cookieName: "Cookies prevent users from filling out the same survey twice.",
    // format: "Use {0} as a placeholder for the actual value.",
    // totalText: "Visible only when at least one column has Total type or Total expression.",
    // acceptedTypes: "Refer to the [accept](https://www.w3schools.com/tags/att_input_accept.asp) attribute description for more information.",
    // columnColCount: "Applicable only to Radiogroup and Checkbox cell types.",
    // autocomplete: "Refer to the [autocomplete](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete) attribute description for more information.",
    // valueName: "If you do not set this property, the answer will be stored in a field specified by the Name property.",
    choicesbyurl: {
      // valueName: " "
    },
    // keyName: "If the specified column contains identical values, the survey throws the \"Non-unique key value\" error."
  },
  // Properties
  p: {
    title: {
      name: "tajuk",
      title: "Biarkan kosong, jika sama seperti 'Nama'"
    },
    multiSelect: "Pilih berbilang",
    showLabel: "Tunjukkan label",
    // value: "Value",
    visibleIf: "Boleh dilihat jika",
    // attachOriginalItems: "attachOriginalItems",
    useDisplayValuesInDynamicTexts: "Guna nilai paparan dalam tajuk",
    titleLocation: "Lokasi tajuk",
    description: "perihalan",
    descriptionLocation: "Lokasi perihalan",
    defaultValueExpression: "Ekspresi nilai lalai",
    requiredIf: "Diperlukan jika",
    validators: "Pengesah",
    bindings: "ikatan",
    // renderAs: "renderAs",
    currency: "mata wang",
    // cellHint: "cellHint",
    // isUnique: "isUnique",
    // showInMultipleColumns: "showInMultipleColumns",
    // totalMaximumFractionDigits: "totalMaximumFractionDigits",
    // totalMinimumFractionDigits: "totalMinimumFractionDigits",
    columns: "lajur",
    // detailElements: "detailElements",
    choices: "pilihan",
    // allowAdaptiveActions: "allowAdaptiveActions",
    defaultRowValue: "Nilai baris lalai",
    detailPanelShowOnAdding: "Butiran panel tunjukkan pada penambahan",
    logoFit: "Muat logo",
    pages: "halaman",
    // questions: "questions",
    triggers: "pencetus",
    calculatedValues: "Nilai dikira",
    // surveyId: "surveyId",
    // surveyPostId: "surveyPostId",
    // surveyShowDataSaving: "surveyShowDataSaving",
    questionDescriptionLocation: "Lokasi perihalan soalan",
    progressBarType: "Jenis bar perkembangan",
    questionTitlePattern: "Corak tajuk soalan",
    widthMode: "Mod lebar",
    // showBrandInfo: "showBrandInfo",
    choicesByUrl: "Pilihan melalui URL",
    // choicesLazyLoadEnabled: "choicesLazyLoadEnabled",
    // choicesLazyLoadPageSize: "choicesLazyLoadPageSize",
    // inputFieldComponent: "inputFieldComponent",
    // itemComponent: "itemComponent",
    min: "min",
    max: "maks",
    minValueExpression: "Ekspresi nilai min",
    maxValueExpression: "Ekspresi nilai maks",
    step: "langkah",
    dataList: "Senarai data",
    itemSize: "Saiz item",
    // elements: "elements",
    // content: "content",
    navigationButtonsVisibility: "Kebolehlihatan butang navigasi",
    navigationTitle: "Tajuk navigasi",
    navigationDescription: "Perihalan navigasi",
    // closeOnSelect: "closeOnSelect",
    // longTap: "longTap",
    autoGrow: "Auto besarkan",
    // acceptCarriageReturn: "acceptCarriageReturn",
    // displayMode: "displayMode",
    label: "label",
    contentMode: "Mod kandungan",
    imageFit: "Muat imej",
    // altText: "altText",
    height: "tinggi",
    penColor: "Warna pen",
    backgroundColor: "Warna latar belakang",
    // templateElements: "templateElements",
    // operator: "operator",
    // isVariable: "isVariable",
    // runExpression: "runExpression",
    showCaption: "Tunjukkan kapsyen",
    iconName: "Nama ikon",
    iconSize: "Saiz ikon"
  }
};

editorLocalization.locales["ms"] = msStrings;

import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import React, { useState } from 'react'
import logo from '../public/logos/logo-purple.svg'
import MenuIcon from '@mui/icons-material/Menu'
import OrgMenu from '../menus/OrgMenu'

interface OrgToolbarProps {
  title?: string
}

export default function OrgToolbar(props: OrgToolbarProps): JSX.Element {
  const [open, setOpen] = useState(false)
  return (
    <React.Fragment>
      <OrgMenu open={open} setOpen={setOpen} />
      <AppBar
        position='static'
        sx={{ backgroundColor: 'rgb(252,252,252)' }}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            size='large'
            edge='start'
            aria-label='menu'
            sx={{ mr: 2 }}
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <picture
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginRight: '30px',
            }}
          >
            <img src={logo} alt='HealthOPX' style={{ width: '125px' }} />
          </picture>
          <Typography variant='h4' sx={{ color: 'black' }}>
            {props?.title}
          </Typography>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { fetchOneOrg } from '../../api/organizations/organizations'
import OrgToolbar from '../../toolbars/OrgToolbar'

export default function OrgVerification(): JSX.Element {
  const { orgId } = useParams()

  const { data: org } = useQuery({
    queryKey: ['organizations', orgId],
    queryFn: () => fetchOneOrg(orgId || ''),
  })

  const handleSignOut = (): void => {
    localStorage.clear()
    // TODO, create a signout api to sign out from the backend pool
    window.location.reload()
  }

  return (
    <Grid container sx={{ backgroundColor: 'rgb(239,239,239)' }}>
      <OrgToolbar title={org?.name || ''} />
      <Dialog open>
        <DialogTitle>Account Verification Required</DialogTitle>
        <DialogContent>
          Your account is pending verification by an admin. Thank you for your
          patience while we process your request. Contact your organization
          leader for any questions.
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleSignOut}>
            Sign Out
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

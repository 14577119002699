import React from 'react'
import { CircularProgress, Typography } from '@mui/material'
import ServiceCard from '../patients-views/services/ServiceCard'
import { Service } from '../types/services'
import { useQuery } from '@tanstack/react-query'
import { fetchServices } from '../api/services/services'

export default function LandingServices(): JSX.Element {
  const { data: services, isLoading } = useQuery({
    queryKey: ['services'],
    queryFn: () => fetchServices(),
  })

  // TODO: get location programatically
  const location = 'Montgomery, AL'
  return (
    <React.Fragment>
      <Typography variant='h5'>Major Services in {location}</Typography>
      <Typography variant='subtitle1' sx={{ marginBottom: '15px' }}>
        General services in your area
      </Typography>
      <div
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          overflowY: 'hidden',
          height: '320px',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          (services as Service[]).map((svc, idx) => (
            <div
              style={{
                flex: '0 0 auto',
                width: 'auto',
                height: '100px',
                maxWidth: '100%',
                marginRight: '30px',
              }}
              key={idx}
            >
              <ServiceCard
                service={svc}
                mediaStyles={{ width: '343px', height: '171px' }}
              />
            </div>
          ))
        )}
      </div>
    </React.Fragment>
  )
}

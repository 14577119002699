import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { postReferral } from '../../../api/referrals/referrals'
import {
  getApplicationById,
  postApplicationAnswers,
} from '../../../api/applications/applications'
import { CreateReferral } from '../../../types/referrals'
import { Model, Survey } from 'survey-react'

interface ApplicationStepProps {
  appId: number
  svcId: number
  orgId: number
  memberId: number
  onComplete: () => void
}

export function ApplicationStep(props: ApplicationStepProps): JSX.Element {
  const { orgId, appId, memberId, svcId } = props
  const [model, setModel] = useState<Model>()

  const queryClient = useQueryClient()

  const referralMutation = useMutation({
    mutationFn: postReferral,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['referral'] })
    },
  })

  useEffect(() => {
    if (appId) {
      getApplicationById(appId).then((app) => {
        setModel(new Model(app.data))
      })
    }
  }, [appId])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (formAnswers: any): void => {
    postApplicationAnswers({
      version: 1,
      data: formAnswers,
      surveyId: appId,
      userId: memberId,
      language: 'English',
    }).then((appData) => {
      const referral: CreateReferral = {
        organizationId: orgId,
        patientId: memberId,
        serviceId: svcId,
        applicationResponseId: appData.id as number,
      }
      referralMutation.mutate(referral)
    })
    props.onComplete()
  }

  if (!model) {
    return <React.Fragment />
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{ marginTop: '10px', marginBottom: '10px' }}
    >
      <Grid item xs={12}>
        <Survey
          model={model}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onComplete={(sender: { data: any }) => handleSubmit(sender.data)}
        />
      </Grid>
    </Grid>
  )
}

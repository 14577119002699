// This dictionary contains 338 untranslated or inherited localization strings.
// These strings are commented out. Uncomment and edit them if you want to add your translations.
import { editorLocalization } from "survey-creator-core";

export var jaStrings = {
  // survey templates
  survey: {
    edit: "編集",
    externalHelpLink: "アンケートの作成方法を見て学ぶ",
    externalHelpLinkUrl: "https://www.youtube.com/channel/UCH2ru9okIcooNZfpIbyq4qQ?view_as=subscriber",
    dropQuestion: "ツールボックスからここに質問をドロップしてください。",
    addLogicItem: "アンケートの流れをカスタマイズするルールを作成します。",
    copy: "コピー",
    duplicate: "複製",
    addToToolbox: "ツールボックスに追加する",
    deletePanel: "パネルを削除する",
    deleteQuestion: "質問を削除する",
    convertTo: "に変換する",
    drag: "要素をドラッグする"
  },
  // Question types
  qt: {
    default: "デフォルト",
    checkbox: "チェックボックス",
    comment: "コメント",
    imagepicker: "画像ピッカー",
    ranking: "ランキング",
    image: "画像",
    dropdown: "ドロップダウン",
    // tagbox: "Multi-Select Dropdown",
    file: "ファイル",
    html: "HTML",
    matrix: "マトリックス（単一選択）",
    matrixdropdown: "マトリックス（多項選択）",
    matrixdynamic: "マトリックス（動的行）",
    multipletext: "複数のテキスト",
    panel: "パネル",
    paneldynamic: "パネル（動的パネル）",
    radiogroup: "ラジオグループ",
    rating: "評価",
    text: "単一入力",
    boolean: "ブール",
    expression: "式（読み取り専用）",
    signaturepad: "署名パッド",
    // buttongroup: "Button Group"
  },
  // Strings in SurveyJS Creator
  ed: {
    defaultLocale: "デフォルト（{0}）",
    survey: "アンケート",
    settings: "アンケートの設定",
    settingsTooltip: "アンケートの設定を開く",
    // surveySettings: "Survey Settings",
    // surveySettingsTooltip: "Open survey settings",
    showPanel: "パネルを表示する",
    hidePanel: "パネルを非表示にする",
    // prevSelected: "Select previous",
    // nextSelected: "Select next",
    // surveyTypeName: "Survey",
    // pageTypeName: "Page",
    // panelTypeName: "Panel",
    // questionTypeName: "Question",
    // columnTypeName: "Column",
    addNewPage: "新規ページを追加する",
    moveRight: "右にスクロールする",
    moveLeft: "左にスクロールする",
    deletePage: "ページを削除する",
    editPage: "ページを編集する",
    edit: "編集",
    newPageName: "ページ",
    newQuestionName: "質問",
    newPanelName: "パネル",
    newTextItemName: "テキスト",
    testSurvey: "アンケートをテストする",
    // defaultV2Theme: "Default",
    // modernTheme: "Modern",
    // defaultTheme: "Default (legacy)",
    testSurveyAgain: "アンケートを再度テストする",
    testSurveyWidth: "アンケートの幅： ",
    navigateToMsg: "次の場所に移動する必要がありました：",
    logic: "アンケートのロジック",
    embedSurvey: "アンケートを埋め込む",
    translation: "翻訳",
    saveSurvey: "アンケートを保存する",
    saveSurveyTooltip: "アンケートを保存する",
    designer: "アンケートの設計者",
    jsonEditor: "JSONエディタ",
    // jsonHideErrors: "Hide errors",
    // jsonShowErrors: "Show errors",
    undo: "元に戻す",
    redo: "やり直す",
    undoTooltip: "最後の変更を元に戻す",
    redoTooltip: "変更をやり直す",
    // showMoreChoices: "Show more",
    // showLessChoices: "Show less",
    copy: "コピー",
    cut: "カット",
    paste: "貼り付ける",
    copyTooltip: "選択範囲をクリップボードにコピーする",
    cutTooltip: "選択範囲をクリップボードにカットする",
    pasteTooltip: "クリップボードから貼り付ける",
    options: "オプション",
    generateValidJSON: "有効なJSONを生成する",
    generateReadableJSON: "読み取り可能なJSONを生成する",
    toolbox: "ツールボックス",
    "property-grid": "プロパティ",
    // propertyGridFilteredTextPlaceholder: "Type to search...",
    toolboxGeneralCategory: "一般",
    // toolboxChoiceCategory: "Choice Questions",
    // toolboxTextCategory: "Text Input Questions",
    // toolboxContainersCategory: "Containers",
    // toolboxMatrixCategory: "Matrix Questions",
    // toolboxMiscCategory: "Misc",
    correctJSON: "JSONを修正してください。",
    surveyResults: "アンケート結果： ",
    surveyResultsTable: "表として",
    surveyResultsJson: "JSONとして",
    resultsTitle: "質問のタイトル",
    resultsName: "質問名",
    resultsValue: "回答値",
    resultsDisplayValue: "表示値",
    modified: "修正済み",
    saving: "保存",
    saved: "保存済み",
    propertyEditorError: "エラー：",
    saveError: "エラー！ エディタのコンテンツは保存されません。",
    // translationPropertyGridTitle: "Language Settings",
    // translationLanguages: "Languages",
    translationAddLanguage: "翻訳する言語を選択する",
    translationShowAllStrings: "すべての文字列を表示する",
    // translationShowUsedStringsOnly: "Used Strings Only",
    translationShowAllPages: "すべてのページを表示する",
    translationNoStrings: "翻訳する文字列がありません。フィルターを変更してください。",
    translationExportToSCVButton: "CSVにエクスポートする",
    translationImportFromSCVButton: "CSVからインポートする",
    translationMergeLocaleWithDefault: "{0}をデフォルトのロケールにマージする",
    // translationPlaceHolder: "Translation...",
    bold: "太字",
    italic: "イタリック",
    underline: "下線",
    addNewQuestion: "質問を追加",
    selectPage: "ページを選択...",
    // htmlPlaceHolder: "HTML content will be here.",
    // panelPlaceHolder: "Drop a question from the toolbox here.",
    // surveyPlaceHolder: "The survey is empty. Drag an element from the toolbox or click the button below.",
    // addNewTypeQuestion: "Add {0}", //{0} is localizable question type
    // chooseLogoPlaceholder: "[LOGO]",
    // auto: "auto",
    lg: {
      addNewItem: "新しいルールを追加",
      // empty_tab: "Create a rule to customize the flow of the survey.",
      page_visibilityName: "ページの可視性",
      // page_enableName: "Enable (disable) page",
      panel_visibilityName: "パネルの可視性",
      panel_enableName: "パネルの有効化/無効化",
      question_visibilityName: "質問の可視性",
      question_enableName: "質問の有効化/無効化",
      question_requireName: "質問オプションが必要",
      // column_visibilityName: "Show (hide) column",
      // column_enableName: "Enable (disable) column",
      // column_requireName: "Make column required",
      trigger_completeName: "アンケートを完了する",
      trigger_setvalueName: "質問の値を設定する",
      trigger_copyvalueName: "質問の値をコピーする",
      trigger_skipName: "質問にスキップする",
      trigger_runExpressionName: "カスタム式を実行する",
      completedHtmlOnConditionName: "カスタムの「サンキューページ」テキスト",
      page_visibilityDescription: "論理式がtrue（真）を返した場合、ページを表示にします。それ以外の場合は、非表示にしておきます。",
      panel_visibilityDescription: "論理式がtrue（真）を返した場合、パネルを表示にします。それ以外の場合は、非表示にしておきます。",
      panel_enableDescription: "論理式がtrue（真）を返した場合、パネルとその中のすべての要素を有効にします。それ以外の場合は、無効にしておきます。",
      question_visibilityDescription: "論理式がtrue（真）を返した場合、質問を表示にします。それ以外の場合は、非表示にしておきます。",
      question_enableDescription: "論理式がtrue（真）を返した場合、質問を有効にします。それ以外の場合は、無効にしておきます。",
      question_requireDescription: "論理式がtrue（真）を返した場合、質問が必須になります。",
      trigger_completeDescription: "論理式がtrue（真）を返した場合、アンケートが完了し、エンドユーザーに「サンキューページ」が表示されます。",
      trigger_setvalueDescription: "論理式で使用される質問の値が変更され、論理式がtrue（真）を返した場合、その値は選択された質問に設定されます。",
      trigger_copyvalueDescription: "論理式で使用される質問の値が変更され、論理式がtrue（真）を返した場合、選択された1つの質問の値が別の選択された質問にコピーされます。",
      trigger_skipDescription: "論理式がtrue（真）を返した場合、アンケートは選択された質問にスキップ/フォーカスされます。",
      trigger_runExpressionDescription: "論理式がtrue（真）を返した場合、カスタム式が実行されます。オプションで、この式の結果を選択した質問に設定することができます。",
      completedHtmlOnConditionDescription: "論理式がtrue（真）を返した場合、「サンキューページ」のデフォルトテキストは指定されたものに変更されます。",
      itemExpressionText: "式：「{0}」がtrue（真）を返した場合：", //{0} - the expression
      // itemEmptyExpressionText: "New rule",
      page_visibilityText: "ページ{0}を表示にする", //{0} page name
      panel_visibilityText: "パネル{0}を表示にする", //{0} panel name
      panel_enableText: "パネル{0}を有効にする", //{0} panel name
      question_visibilityText: "質問{0}を表示にする", //{0} question name
      question_enableText: "質問{0}を有効にする", //{0} question name
      question_requireText: "質問{0}を必須にする", //{0} question name
      // column_visibilityText: "make column {0} of question {1} visible", //{0} column name, {1} question name
      // column_enableText: "make column {0} of question {1} enable", //{0} column name, {1} question name
      // column_requireText: "make column {0} of question {1} required", //{0} column name, {1} question name
      trigger_completeText: "アンケートが完了する",
      trigger_setvalueText: "質問：{0}値{1}に設定する", //{0} question name, {1} setValue
      trigger_copyvalueText: "質問{1}から質問：{0}値にコピーする", //{0} and {1} question names
      trigger_skipText: "アンケートは質問{0}にスキップする", //{0} question name
      trigger_runExpressionText1: "式：「{0}」を実行する", //{0} the expression
      trigger_runExpressionText2: " そして、その結果を質問：{0}に設定する", //{0} question name
      completedHtmlOnConditionText: "「サンキューページ」のカスタムテキストを表示します。",
      // showAllQuestions: "All Questions",
      // showAllActionTypes: "All Action Types",
      conditions: "条件",
      actions: "アクション",
      expressionEditorTitle: "条件を定義する",
      actionsEditorTitle: "アクションを定義する",
      deleteAction: "アクションを削除する",
      addNewAction: "新しいアクションを追加する",
      selectedActionCaption: "追加するアクションを選択…",
      expressionInvalid: "論理式が空または無効です。修正してください。",
      noActionError: "少なくとも1つのアクションを追加してください。",
      actionInvalid: "アクションの問題を解決してください。",
      // expressionSetup: "",
      // actionsSetup: ""
    }
  },
  // Property Editors
  pe: {
    apply: "適用",
    ok: "OK",
    save: "保存",
    // clear: "Clear",
    saveTooltip: "保存",
    cancel: "キャンセル",
    // set: "Set",
    reset: "リセット",
    // change: "Change",
    refresh: "リフレッシュ",
    // close: "Close",
    delete: "削除",
    add: "追加",
    addNew: "新規追加:",
    addItem: "クリックしてアイテムを追加…",
    // removeItem: "Click to remove the item...",
    // dragItem: "Drag the item",
    addOther: "その他",
    addSelectAll: "すべて選択",
    addNone: "なし",
    removeAll: "すべて削除",
    edit: "編集",
    back: "保存せずに戻る",
    backTooltip: "保存せずに戻る",
    saveAndBack: "保存して戻る",
    saveAndBackTooltip: "保存して戻る",
    // doneEditing: "Done",
    editChoices: "選択肢を編集",
    showChoices: "選択肢を表示",
    move: "移動",
    // empty: "<empty>",
    // emptyValue: "Value is empty",
    fastEntry: "高速入力",
    // fastEntryNonUniqueError: "Value '{0}' is not unique",
    // fastEntryChoicesCountError: "Please limit the number of items from {0} to {1}",
    // fastEntryPlaceholder: "You can set data in the following format:\nvalue1|text\nvalue2",
    formEntry: "フォーム入力",
    testService: "サービスをテスト",
    itemSelectorEmpty: "エレメントを選択してください",
    conditionActionEmpty: "アクションを選択してください",
    conditionSelectQuestion: "質問を選択...",
    conditionSelectPage: "ページを選択...",
    conditionSelectPanel: "パネルを選択...",
    conditionValueQuestionTitle: "値を入力/選択してください",
    expressionHelp: "波括弧を使用して質問の値にアクセスできます: {質問1} + {質問2}, ({値段}*{数量}) * (100 - {割引})。iif(), today(), age(), min(), max(), count(), avg() およびその他の関数を使用できます。",
    aceEditorHelp: "表現の補完のヒントを取得するには「ctrl+space」を押してください",
    aceEditorRowTitle: "現在の行",
    aceEditorPanelTitle: "現在のパネル",
    showMore: "詳細は文書をご確認ください",
    assistantTitle: "利用できる質問:",
    cellsEmptyRowsColumns: "少なくとも1つの列または行を含む必要があります",
    // showPreviewBeforeComplete: "Preview answers before submitting the survey",
    propertyIsEmpty: "値を入力してください",
    propertyIsNoUnique: "固有値を入力してください",
    propertyNameIsNotUnique: "固有名を入力してください",
    listIsEmpty: "新しいアイテムを追加",
    // "listIsEmpty@choices": "No choices have been added yet",
    // "addNew@choices": "Add a choice",
    expressionIsEmpty: "表現が空白です",
    value: "値",
    text: "テキスト",
    rowid: "行ID",
    imageLink: "画像リンク",
    columnEdit: "行編集: {0}",
    itemEdit: "アイテム編集: {0}",
    url: "URL",
    path: "パス",
    valueName: "値名",
    choicesbyurl: {
      // valueName: "Get values from the following JSON field"
    },
    titleName: "タイトル名",
    // imageLinkName: "Get image URLs from the following JSON field",
    allowEmptyResponse: "空の応答を許可",
    titlePlaceholder: "タイトルを入力",
    surveyTitlePlaceholder: "調査タイトルを入力",
    pageTitlePlaceholder: "ページタイトルを入力",
    descriptionPlaceholder: "詳細を入力",
    surveyDescriptionPlaceholder: "調査の詳細を入力",
    pageDescriptionPlaceholder: "ページの詳細を入力",
    showOtherItem: "他のアイテム",
    otherText: "他のアイテムのテキスト",
    showNoneItem: "ノーアイテム",
    noneText: "ノーアイテムのテキスト",
    showSelectAllItem: "アイテムを全て選択",
    selectAllText: "アイテムを全て選択のテキスト",
    choicesMin: "自動生成アイテムの最小値",
    choicesMax: "自動生成アイテムの最大値",
    choicesStep: "自動生成アイテムの差",
    name: "名前",
    title: "タイトル",
    cellType: "セルタイプ",
    colCount: "行の数",
    choicesOrder: "選択順",
    visible: "表示",
    isRequired: "必須",
    isAllRowRequired: "全ての列で回答必須",
    requiredErrorText: "必要なエラーテキスト",
    startWithNewLine: "ニューラインで開始",
    rows: "列数",
    cols: "行の数",
    placeholder: "プレースホルダの入力",
    showPreview: "プレビューエリア表示",
    storeDataAsText: "ファイルのコンテンツをテキストとしてJSONの結果に保存",
    maxSize: "ファイルの最大サイズ（byte）",
    imageHeight: "イメージの高さ",
    imageWidth: "イメージの幅",
    rowCount: "列数",
    columnLayout: "行のレイアウト",
    addRowLocation: "列ボタンのロケーションを追加",
    addRowText: "行ボタンのテキストを追加",
    removeRowText: "行ボタンのテキストを削除",
    rateMin: "最小レート",
    rateMax: "最大レート",
    rateStep: "レートステップ",
    minRateDescription: "最小レートの詳細",
    maxRateDescription: "最大レートの説明",
    inputType: "入力タイプ",
    optionsCaption: "オプションのキャプション",
    defaultValue: "デフォルト値",
    cellsDefaultRow: "デフォルトのセルテキスト",
    surveyEditorTitle: "アンケート設定を編集する",
    qEditorTitle: "編集： {0}",
    maxLength: "最大長",
    buildExpression: "構築する",
    editExpression: "編集",
    // and: "and",
    // or: "or",
    remove: "削除する",
    addCondition: "条件を追加する",
    // emptyLogicPopupMessage: "Select a question to start configuring conditions.",
    // if: "If",
    // then: "then",
    // setToName: "Target question",
    // fromName: "Question to copy answer from",
    // gotoName: "Question to skip to",
    // ruleIsNotSet: "Rule is incorrect",
    // includeIntoResult: "Include into survey results",
    showTitle: "タイトルの表示/非表示",
    expandCollapseTitle: "タイトルを展開/折りたたむ",
    locale: "デフォルトの言語",
    simulator: "デバイスを選択する",
    landscapeOrientation: "横向き",
    // portraitOrientation: "Switch to portrait orientation",
    mode: "モード（編集/読み取り専用）",
    clearInvisibleValues: "非表示の値をクリアする",
    cookieName: "Cookie名（ローカルで2回アンケートを実行しないようにするため）",
    sendResultOnPageNext: "次のページにアンケート結果を送信する",
    storeOthersAsComment: "「その他」の値を別のフィールドに保存する",
    showPageTitles: "ページタイトルを表示する",
    showPageNumbers: "ページ番号を表示する",
    pagePrevText: "「前のページへ」ボタンのテキスト",
    pageNextText: "「次のページへ」ボタンのテキスト",
    completeText: "「完了」ボタンのテキスト",
    previewText: "「プレビュー」ボタンのテキスト",
    editText: "「編集」ボタンのテキスト",
    startSurveyText: "「開始」ボタンのテキスト",
    showNavigationButtons: "ナビゲーションボタンを表示する（デフォルトのナビゲーション）",
    showPrevButton: "「前へ」ボタンを表示する（ユーザーは前のページに戻ることができます）",
    firstPageIsStarted: "アンケートの最初のページは、開始ページです。",
    showCompletedPage: "完了したページを最後に表示する（completedHtml）",
    goNextPageAutomatic: "すべての質問に回答すると、自動的に次のページに移動します",
    showProgressBar: "プログレスバーを表示する",
    questionTitleLocation: "質問のタイトルの場所",
    requiredText: "質問には記号が必要",
    questionStartIndex: "質問開始インデックス（1、2または「A」、「a」）",
    showQuestionNumbers: "質問番号を表示する",
    questionTitleTemplate: "質問タイトルのテンプレート、デフォルトは「{no}. {require} {title}」です",
    questionErrorLocation: "質問エラーの場所",
    focusFirstQuestionAutomatic: "ページを変える際に最初の質問に焦点を合わせる",
    questionsOrder: "ページ上の要素の順序",
    maxTimeToFinish: "アンケート終了までの最長時間",
    maxTimeToFinishPage: "アンケートの1ページを終了するまでの最長時間",
    page: {
      // maxTimeToFinish: "Time limit to finish the page (in seconds)"
    },
    question: {
      // page: "Parent page"
    },
    showTimerPanel: "タイマーパネルを表示する",
    showTimerPanelMode: "タイマーパネルモードを表示",
    renderMode: "レンダーモード",
    allowAddPanel: "パネルの追加を許可",
    allowRemovePanel: "パネルの削除を許可",
    // noEntriesText: "Empty entries text",
    panelAddText: "パネルテキストを追加しています",
    panelRemoveText: "パネルテキストを削除しています",
    isSinglePage: "1ページ上にすべてのエレメントを表示",
    html: "HTML",
    expression: "表現",
    // setValue: "Answer",
    // dataFormat: "Image format",
    // allowAddRows: "Allow adding rows",
    // allowRemoveRows: "Allow removing rows",
    // allowRowsDragAndDrop: "Allow row drag and drop",
    // responsiveImageSizeHelp: "Does not apply if you specify the exact image width or height.",
    // minImageWidth: "Minimum image width",
    // maxImageWidth: "Maximum image width",
    // minImageHeight: "Minimum image height",
    // maxImageHeight: "Maximum image height",
    minValue: "最小値",
    maxValue: "最大値",
    minLength: "最小長",
    allowDigits: "数字を許可",
    minCount: "最小数",
    maxCount: "最大数",
    regex: "正規表現",
    surveyvalidator: {
      // text: "Error message",
      // expression: "Validation expression"
    },
    totalText: "テキストの合計",
    totalType: "タイプの合計",
    totalExpression: "表現の合計",
    totalDisplayStyle: "表示スタイルの合計",
    totalCurrency: "通貨の合計",
    totalFormat: "形式の合計",
    // logo: "Logo (URL or base64-encoded string)",
    // questionsOnPageMode: "Survey structure",
    // maxTextLength: "Maximum answer length (in characters)",
    // maxOthersLength: "Maximum comment length (in characters)",
    // autoGrowComment: "Auto-expand comment area if necessary",
    // textUpdateMode: "Update text question value",
    // focusOnFirstError: "Set focus on the first invalid answer",
    // checkErrorsMode: "Run validation",
    // navigateToUrl: "Navigate to URL",
    // navigateToUrlOnCondition: "Dynamic URL",
    // completedBeforeHtml: "Markup to show if the user already filled out this survey",
    // completedHtml: "Survey Complete page markup",
    // completedHtmlOnCondition: "Dynamic Survey Complete page markup",
    // loadingHtml: "Markup to show while survey model is loading",
    // commentText: "Comment area text",
    // autocomplete: "Autocomplete type",
    // labelTrue: "\"True\" label",
    // labelFalse: "\"False\" label",
    allowClear: "オプションのキャプションを表示する",
    // displayStyle: "Value display style",
    // format: "Formatted string",
    // maximumFractionDigits: "Maximum fractional digits",
    // minimumFractionDigits: "Minimum fractional digits",
    // useGrouping: "Display grouping separators",
    // allowMultiple: "Allow multiple files",
    // allowImagesPreview: "Preview images",
    // acceptedTypes: "Accepted file types",
    // waitForUpload: "Wait for the upload to complete",
    // needConfirmRemoveFile: "Confirm file deletion",
    // detailPanelMode: "Detail panel location",
    // minRowCount: "Minimum row count",
    // maxRowCount: "Maximum row count",
    // confirmDelete: "Confirm row deletion",
    // confirmDeleteText: "Confirmation message",
    paneldynamic: {
      // confirmDelete: "Confirm panel deletion"
    },
    // panelCount: "Initial panel count",
    // minPanelCount: "Minimum panel count",
    // maxPanelCount: "Maximum panel count",
    // panelsState: "Inner panel expand state",
    // templateDescription: "Description template",
    // templateTitle: "Title template",
    // panelPrevText: "Previous Panel button tooltip",
    // panelNextText: "Next Panel button tooltip",
    // showRangeInProgress: "Show progress bar",
    // templateTitleLocation: "Question title location",
    // panelRemoveButtonLocation: "Remove Panel button location",
    // hideIfRowsEmpty: "Hide the question if there are no rows",
    // hideColumnsIfEmpty: "Hide columns if there are no rows",
    // rateValues: "Custom rate values",
    // hideIfChoicesEmpty: "Hide the question if it contains no choices",
    // hideNumber: "Hide question number",
    // minWidth: "Minimum width (in CSS-accepted values)",
    // maxWidth: "Maximum width (in CSS-accepted values)",
    // width: "Width (in CSS-accepted values)",
    // showHeader: "Show column headers",
    // horizontalScroll: "Show horizontal scrollbar",
    // columnMinWidth: "Minimum column width (in CSS-accepted values)",
    // rowTitleWidth: "Row header width (in CSS-accepted values)",
    // valueTrue: "\"True\" value",
    // valueFalse: "\"False\" value",
    // minErrorText: "\"Value is below minimum\" error message",
    // maxErrorText: "\"Value exceeds maximum\" error message",
    // otherErrorText: "\"Empty comment\" error message",
    // keyDuplicationError: "\"Non-unique key value\" error message",
    // maxSelectedChoices: "Maximum selected choices",
    // showClearButton: "Show the Clear button",
    // showNumber: "Show panel number",
    // logoWidth: "Logo width (in CSS-accepted values)",
    // logoHeight: "Logo height (in CSS-accepted values)",
    // readOnly: "Read-only",
    // enableIf: "Editable if",
    // emptyRowsText: "\"No rows\" message",
    // size: "Input size (in characters)",
    // separateSpecialChoices: "Separate special choices (None, Other, Select All)",
    // choicesFromQuestion: "Copy choices from the following question",
    // choicesFromQuestionMode: "Which choices to copy?",
    // showCommentArea: "Show the comment area",
    // commentPlaceholder: "Comment area placeholder",
    // displayRateDescriptionsAsExtremeItems: "Display rate descriptions as extreme values",
    // rowsOrder: "Row order",
    // columnsLayout: "Column layout",
    // columnColCount: "Nested column count",
    // state: "Panel expand state",
    // correctAnswer: "Correct Answer",
    // defaultPanelValue: "Default Values",
    // cells: "Cell Texts",
    // keyName: "Key column",
    itemvalue: {
      // text: "Alt text"
    },
    logoPosition: "ロゴの位置",
    addLogo: "ロゴを追加...",
    changeLogo: "ロゴを変更...",
    logoPositions: {
      none: "ロゴを削除",
      left: "左",
      right: "右",
      top: "上に",
      bottom: "下に"
    },
    tabs: {
      general: "一般",
      fileOptions: "オプション",
      html: "HTMLエディター",
      columns: "列",
      rows: "行",
      choices: "選択",
      items: "アイテム",
      visibleIf: "以下の場合、見える：",
      enableIf: "以下の場合、有効にする：",
      requiredIf: "以下の場合、必須：",
      rateValues: "レート値",
      choicesByUrl: "ウェブの選択",
      matrixChoices: "デフォルトの選択",
      multipleTextItems: "テキスト入力",
      numbering: "番号付け",
      validators: "バリデータ",
      navigation: "ナビゲーション",
      question: "質問",
      pages: "ページ",
      timer: "タイマー/クイズ",
      calculatedValues: "算出された値",
      triggers: "トリガー",
      templateTitle: "テンプレートタイトル",
      totals: "合計",
      logic: "ロジック",
      layout: "レイアウト",
      data: "データ",
      validation: "検証",
      cells: "セル",
      showOnCompleted: "完成したものに表示",
      logo: "アンケートタイトルのロゴ",
      slider: "スライダー",
      // expression: "Expression",
      others: "その他"
    },
    editProperty: "プロパティ'{0}'を編集",
    items: "[ アイテム: {0} ]",
    // choicesVisibleIf: "Choices are visible if",
    // choicesEnableIf: "Choices are selectable if",
    // columnsEnableIf: "Columns are visible if",
    // rowsEnableIf: "Rows are visible if",
    // indent: "Add indents",
    panel: {
      // indent: "Add outer indents"
    },
    // innerIndent: "Add inner indents",
    // defaultValueFromLastRow: "Take default values from the last row",
    // defaultValueFromLastPanel: "Take default values from the last panel",
    enterNewValue: "値を入力してください。",
    noquestions: "アンケートに質問はありません。",
    createtrigger: "トリガーを作成してください。",
    titleKeyboardAdornerTip: "編集するには「enter」キーを押してください",
    keyboardAdornerTip: "アイテムを編集するには「enter」キーを押してください、アイテムを削除するには「delete」キーを押してください、アイテムを移動するには「alt」+上矢印キーまたは「alt」+下矢印キーを押してください",
    triggerOn: "オン ",
    triggerMakePagesVisible: "ページを可視化:",
    triggerMakeQuestionsVisible: "エレメントを可視化:",
    triggerCompleteText: "うまくいけばアンケートが完了します。",
    triggerNotSet: "トリガーが設定されていません",
    triggerRunIf: "次の場合、実行",
    triggerSetToName: "次の値を変更: ",
    triggerFromName: "次から値をコピー: ",
    triggerRunExpression: "この表現を実行:",
    triggerSetValue: "宛先: ",
    triggerGotoName: "質問に移動:",
    triggerIsVariable: "アンケートの結果に変数を入れないでください。",
    triggerRunExpressionEmpty: "有効な表現を入力してください",
    // emptyExpressionPlaceHolder: "Type expression here...",
    noFile: "ファイルが選択されていません",
    // clearIfInvisible: "Clear the value if the question becomes hidden",
    // valuePropertyName: "Value property name",
    // searchEnabled: "Enable search",
    // hideSelectedItems: "Hide selected items",
    // signatureWidth: "Signature width",
    // signatureHeight: "Signature height",
    // verticalAlign: "Vertical alignment",
    // alternateRows: "Alternate rows",
    // columnsVisibleIf: "Columns are visible if",
    // rowsVisibleIf: "Rows are visible if",
    // otherPlaceholder: "Comment area placeholder"
  },
  // Property values
  pv: {
    // true: "true",
    // false: "false",
    inherit: "継承する",
    show: "表示する",
    hide: "非表示にする",
    default: "デフォルト",
    initial: "最初の",
    random: "ランダム",
    collapsed: "折り畳んだ",
    expanded: "展開した",
    none: "なし",
    asc: "昇順",
    desc: "降順",
    indeterminate: "不確定",
    // decimal: "decimal",
    // currency: "currency",
    // percent: "percent",
    firstExpanded: "firstExpanded",
    off: "オフ",
    // onpanel: "Start on each panel",
    onPanel: "onPanel",
    onSurvey: "onSurvey",
    list: "リスト",
    progressTop: "progressTop",
    progressBottom: "progressBottom",
    progressTopBottom: "progressTopBottom",
    horizontal: "水平",
    vertical: "垂直",
    top: "上",
    bottom: "下",
    topBottom: "上と下",
    both: "両方",
    left: "左",
    // right: "Right",
    // color: "color",
    // date: "date",
    // datetime: "datetime",
    // "datetime-local": "datetime-local",
    // email: "email",
    // month: "month",
    // number: "number",
    // password: "password",
    // range: "range",
    // tel: "tel",
    // text: "text",
    // time: "time",
    // url: "url",
    // week: "week",
    hidden: "非表示",
    on: "オン",
    onPage: "onPage",
    edit: "編集",
    display: "表示",
    onComplete: "onComplete",
    onHidden: "onHidden",
    // onHiddenContainer: "When the question or its panel/page becomes hidden",
    clearInvisibleValues: {
      // none: "Never"
    },
    all: "すべて",
    page: "ページ",
    survey: "アンケート",
    onNextPage: "onNextPage",
    onValueChanged: "onValueChanged",
    // onValueChanging: "Before an answer is changed",
    standard: "スタンダード",
    singlePage: "シングルページ",
    questionPerPage: "ページあたりの質問",
    noPreview: "プレビューなし",
    showAllQuestions: "すべての質問を含むプレビューを表示",
    showAnsweredQuestions: "回答された質問を含むプレビューを表示",
    pages: "ページ",
    questions: "質問",
    requiredQuestions: "必須の質問",
    correctQuestions: "正しい質問",
    buttons: "ボタン",
    underInput: "インプットの下",
    underTitle: "タイトルの下",
    // onBlur: "On blur",
    // onTyping: "While typing",
    // underRow: "Under the row",
    // underRowSingle: "Under the row, only one panel is visible",
    showNavigationButtons: {
      // none: "Hidden"
    },
    showProgressBar: {
      // off: "Hidden"
    },
    showTimerPanel: {
      // none: "Hidden"
    },
    showTimerPanelMode: {
      // all: "Both"
    },
    detailPanelMode: {
      // none: "Hidden"
    },
    addRowLocation: {
      // default: "Depends on matrix layout"
    },
    panelsState: {
      // default: "Users cannot expand or collapse panels",
      // collapsed: "All panels are collapsed",
      // expanded: "All panels are expanded"
    },
    widthMode: {
      // auto: "Auto",
      // static: "Static",
      // responsive: "Responsive"
    },
    imageFit: {
      // none: "None",
      // contain: "Contain",
      // cover: "Cover",
      // fill: "Fill"
    },
    contentMode: {
      // auto: "Auto",
      // image: "Image",
      // video: "Video",
      // youtube: "YouTube"
    },
    displayMode: {
      // auto: "Auto",
      // buttons: "Buttons",
      // dropdown: "Dropdown"
    }
  },
  // Operators
  op: {
    empty: "は空白です",
    notempty: "は空白ではありません",
    equal: "等しい",
    notequal: "等しくない",
    contains: "含む",
    notcontains: "含まない",
    anyof: "次のいずれか",
    allof: "次のすべて",
    greater: "より大きい",
    less: "より小さい",
    greaterorequal: "等しいかより大きい",
    lessorequal: "等しいかより小さい",
    // and: "and",
    // or: "or"
  },
  // Embed window
  ew: {
    angular: "Angularバージョンを使用",
    jquery: "jQueryバージョンを使用",
    knockout: "Knockoutバージョンを使用",
    react: "Reactバージョンを使用",
    vue: "Vueバージョンを使用",
    bootstrap: "Bootstrapフレームワーク向け",
    modern: "モダンテーマ",
    default: "デフォルトテーマ",
    orange: "オレンジテーマ",
    darkblue: "ダークブルーテーマ",
    darkrose: "ダークローズテーマ",
    stone: "ストーンテーマ",
    winter: "ウィンターテーマ",
    winterstone: "ウィンターストーンテーマ",
    showOnPage: "1つのページにアンケートを表示",
    showInWindow: "1つのウィンドウにアンケートを表示",
    loadFromServer: "サーバーからアンケートのJSONファイルを読み込む",
    titleScript: "スクリプトとスタイル",
    titleHtml: "HTML",
    titleJavaScript: "JavaScript"
  },
  // Preview (Survey)
  ts: {
    selectPage: "それをテストするためのページを選択:",
    showInvisibleElements: "不可視エレメントを表示",
    // hideInvisibleElements: "Hide invisible elements"
  },
  validators: {
    answercountvalidator: "答えの数",
    emailvalidator: "メール",
    expressionvalidator: "表現",
    numericvalidator: "数字の",
    regexvalidator: "正規表現",
    textvalidator: "テキスト"
  },
  triggers: {
    completetrigger: "アンケートを完了",
    setvaluetrigger: "値を設定",
    copyvaluetrigger: "値をコピー",
    skiptrigger: "質問へスキップ",
    runexpressiontrigger: "数式を実行",
    visibletrigger: "可視性を変更 (非推奨)"
  },
  pehelp: {
    // cookieName: "Cookies prevent users from filling out the same survey twice.",
    // format: "Use {0} as a placeholder for the actual value.",
    // totalText: "Visible only when at least one column has Total type or Total expression.",
    // acceptedTypes: "Refer to the [accept](https://www.w3schools.com/tags/att_input_accept.asp) attribute description for more information.",
    // columnColCount: "Applicable only to Radiogroup and Checkbox cell types.",
    // autocomplete: "Refer to the [autocomplete](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete) attribute description for more information.",
    // valueName: "If you do not set this property, the answer will be stored in a field specified by the Name property.",
    choicesbyurl: {
      // valueName: " "
    },
    // keyName: "If the specified column contains identical values, the survey throws the \"Non-unique key value\" error."
  },
  // Properties
  p: {
    title: {
      // name: "title",
      title: "「名前」と同じ場合は空白のままにしてください"
    },
    // multiSelect: "Allow multiple selection",
    // showLabel: "Show image captions",
    // value: "Value",
    // visibleIf: "visibleIf",
    // attachOriginalItems: "attachOriginalItems",
    // useDisplayValuesInDynamicTexts: "useDisplayValuesInDynamicTexts",
    // titleLocation: "titleLocation",
    // description: "description",
    // descriptionLocation: "descriptionLocation",
    // defaultValueExpression: "defaultValueExpression",
    // requiredIf: "requiredIf",
    // validators: "validators",
    // bindings: "bindings",
    // renderAs: "renderAs",
    // currency: "currency",
    // cellHint: "cellHint",
    // isUnique: "isUnique",
    // showInMultipleColumns: "showInMultipleColumns",
    // totalMaximumFractionDigits: "totalMaximumFractionDigits",
    // totalMinimumFractionDigits: "totalMinimumFractionDigits",
    // columns: "columns",
    // detailElements: "detailElements",
    // choices: "choices",
    // allowAdaptiveActions: "allowAdaptiveActions",
    // defaultRowValue: "defaultRowValue",
    // detailPanelShowOnAdding: "detailPanelShowOnAdding",
    // logoFit: "logoFit",
    // pages: "pages",
    // questions: "questions",
    // triggers: "triggers",
    // calculatedValues: "calculatedValues",
    // surveyId: "surveyId",
    // surveyPostId: "surveyPostId",
    // surveyShowDataSaving: "surveyShowDataSaving",
    // questionDescriptionLocation: "questionDescriptionLocation",
    // progressBarType: "progressBarType",
    // questionTitlePattern: "questionTitlePattern",
    // widthMode: "widthMode",
    // showBrandInfo: "showBrandInfo",
    // choicesByUrl: "choicesByUrl",
    // choicesLazyLoadEnabled: "choicesLazyLoadEnabled",
    // choicesLazyLoadPageSize: "choicesLazyLoadPageSize",
    // inputFieldComponent: "inputFieldComponent",
    // itemComponent: "itemComponent",
    // min: "min",
    // max: "max",
    // minValueExpression: "minValueExpression",
    // maxValueExpression: "maxValueExpression",
    // step: "step",
    // dataList: "dataList",
    // itemSize: "itemSize",
    // elements: "elements",
    // content: "content",
    // navigationButtonsVisibility: "navigationButtonsVisibility",
    // navigationTitle: "navigationTitle",
    // navigationDescription: "navigationDescription",
    // closeOnSelect: "closeOnSelect",
    // longTap: "longTap",
    // autoGrow: "autoGrow",
    // acceptCarriageReturn: "acceptCarriageReturn",
    // displayMode: "displayMode",
    // label: "label",
    // contentMode: "contentMode",
    // imageFit: "imageFit",
    // altText: "altText",
    // height: "height",
    // penColor: "penColor",
    // backgroundColor: "backgroundColor",
    // templateElements: "templateElements",
    // operator: "operator",
    // isVariable: "isVariable",
    // runExpression: "runExpression",
    // showCaption: "showCaption",
    // iconName: "iconName",
    // iconSize: "iconSize"
  }
};

editorLocalization.locales["ja"] = jaStrings;

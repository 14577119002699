import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  TextField,
  MenuItem,
  TableFooter,
  TablePagination,
} from '@mui/material'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  fetchReferralsByUser,
  updateReferralStatus,
} from '../../api/referrals/referrals'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { TableSkeleton } from '../util.tsx/TableUtil'
// import { NotesDialog } from '../dialogs/NotesDialog'
import { ReferralStatus } from '../../types/referrals'
import { ReferralDialog } from '../dialogs/referral-flow/ReferralDialog'
import { AuthContext } from '../../contexts/AuthContext'
import { ViewApplicationDialog } from '../dialogs/ViewApplicationDialog'
import { Application } from '../../types/applications'
import { fetchOneUser } from '../../api/users/users'

interface MemberReferralsProps {
  memberId: string
}

export default function MemberReferrals(
  props: MemberReferralsProps,
): JSX.Element {
  const { organizationId } = useContext(AuthContext)
  const [openReferral, setOpenReferral] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [openApp, setOpenApp] = useState(false)
  const [currentApp, setCurrentApp] = useState<Application>({} as Application)
  const [currentAnswers, setCurrentAnswers] = useState<Application>(
    {} as Application,
  )

  const { data: user } = useQuery({
    queryKey: ['users'],
    queryFn: () => fetchOneUser(props.memberId || ''),
  })
  // const [openNotes, setOpenNotes] = useState(false)
  // const [currentNotes, setCurrentNotes] = useState<string[]>([])
  const [statuses, setStatuses] = useState<{ [key: number]: string }>({})

  const {
    data: referrals,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['referrals'],
    queryFn: () => fetchReferralsByUser(props.memberId),
  })

  useEffect(() => {
    if (referrals) {
      const statusObj: { [key: number]: string } = {}
      referrals.map((ref) => {
        statusObj[ref.id] = ref.status
      })
      setStatuses(statusObj)
    }
  }, [referrals])

  const formatTime = (timeStr: string): string => {
    const date = new Date(timeStr)
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
  }

  const queryClient = useQueryClient()

  const updateStatus = useMutation({
    mutationFn: updateReferralStatus,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['referral'] })
    },
  })

  const onUpdateStatus = (
    referralId: number,
    newStatus: ReferralStatus,
  ): void => {
    setStatuses({ ...statuses, [referralId]: newStatus })
    updateStatus.mutate({ referralId, newStatus })
    refetch()
  }

  // const openNotesDialog = (refNotes: string[]): void => {
  //   setCurrentNotes(refNotes)
  //   setOpenNotes(true)
  // }

  const visibleRows = useMemo(
    () =>
      referrals?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, referrals],
  )

  const handleViewApplication = (
    app: Application,
    answers: Application,
  ): void => {
    setCurrentApp(app)
    setCurrentAnswers(answers)
    setOpenApp(true)
  }

  return (
    <Grid item xs={12}>
      {/* {openNotes && (
        <NotesDialog
          open={openNotes}
          onClose={() => setOpenNotes(false)}
          notes={currentNotes}
        />
      )} */}
      {openApp && (
        <ViewApplicationDialog
          open={openApp}
          onClose={() => setOpenApp(false)}
          app={currentApp}
          answers={currentAnswers.data}
        />
      )}
      {openReferral && (
        <ReferralDialog
          open={openReferral}
          onClose={() => setOpenReferral(false)}
          orgId={Number(organizationId)}
          member={user}
        />
      )}
      <Card>
        <CardHeader title='Referrals' />
        <CardContent>
          <Grid container justifyContent='flex-end'>
            <Grid item xs={6}>
              <Button
                variant='contained'
                sx={{
                  borderRadius: '100px',
                  textTransform: 'none',
                  float: 'right',
                }}
                onClick={() => setOpenReferral(true)}
              >
                + Create Referral
              </Button>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Service</TableCell>
                  <TableCell>Service Type</TableCell>
                  <TableCell>Member</TableCell>
                  <TableCell>Organization Applied To</TableCell>
                  <TableCell>Date Sent</TableCell>
                  <TableCell>Application</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableSkeleton columns={7} />
                ) : (
                  visibleRows?.map((ref, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell component='th' scope='row'>
                          {ref.service?.name}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {ref.service?.type}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <Link href={`/member/${ref.patientId}`}>
                            {ref.patient.firstName} {ref.patient.lastName}
                          </Link>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {ref.organization.name}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {formatTime(ref.createTimestamp)}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {ref.service.applicationId && (
                            <Button
                              onClick={() =>
                                handleViewApplication(
                                  ref.survey,
                                  ref.surveyAnswer,
                                )
                              }
                              variant='outlined'
                              sx={{
                                borderRadius: '100px',
                                textTransform: 'none',
                              }}
                            >
                              View Application
                            </Button>
                          )}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <TextField
                            select
                            margin='dense'
                            label='Status'
                            variant='outlined'
                            fullWidth
                            value={statuses[ref.id] || ref.status}
                            onChange={(e) =>
                              onUpdateStatus(
                                ref.id,
                                e.target.value as ReferralStatus,
                              )
                            }
                          >
                            <MenuItem value='REQUESTED'>Requested</MenuItem>
                            <MenuItem value='OPEN'>Open</MenuItem>
                            <MenuItem value='IN_PROGRESS'>In Progress</MenuItem>
                            <MenuItem value='NEEDS_MORE_INFO'>
                              Need More Info
                            </MenuItem>
                            <MenuItem value='PENDING'>Pending</MenuItem>
                            <MenuItem value='COMPLETED'>Completed</MenuItem>
                            <MenuItem value='CANCELLED'>Cancelled</MenuItem>
                          </TextField>
                        </TableCell>
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: 'All', value: -1 },
                    ]}
                    colSpan={7}
                    count={referrals?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(e) => {
                      setRowsPerPage(Number(e.target.value))
                      setPage(0)
                    }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  )
}

import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  FormGroup,
  Grid,
  Typography,
  DialogActions,
  Autocomplete,
} from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { postService, postUpdateService } from '../../api/services/services'
import moment from 'moment-timezone'
import { Service } from '../../types/services'
import { formatServiceTypes } from '../../patients-views/services/util'
import { getApplicationsByOrgId } from '../../api/applications/applications'

interface ServiceDialogProps {
  orgId: string
  open: boolean
  edit?: boolean
  editService?: Service
  onClose: () => void
}

export const serviceTypes: string[] = [
  'FOOD',
  'TRANSLATION',
  'TRANSPORTATION',
  'INTERPRETATION',
  'SCHEDULING',
  'CHILD_CARE',
  'WORKFORCE_DEVELOPMENT',
  'MEDICAL',
  'ECONOMIC_ASSISTANCE',
  'MENTAL_HEALTH',
  'EDUCATION',
  'FAMILY',
  'HOUSING',
  'LEGAL',
  'OTHER',
]

const DAYS = [
  { name: 'Monday', number: 1 },
  { name: 'Tuesday', number: 2 },
  { name: 'Wednesday', number: 3 },
  { name: 'Thursday', number: 4 },
  { name: 'Friday', number: 5 },
  { name: 'Saturday', number: 6 },
  { name: 'Sunday', number: 7 },
]

export function ServiceDialog(props: ServiceDialogProps): JSX.Element {
  const { edit, editService } = props
  const defaultService = edit
    ? editService
    : {
        organizationId: Number(props.orgId),
        type: '',
        name: '',
        description: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        locationType: '',
        eligibilityRequirements: '',
        timezone: '',
        active: true,
        serviceHours: [],
        applicationId: 0,
      }
  const { data: applications } = useQuery({
    queryKey: ['applications'],
    queryFn: () => getApplicationsByOrgId(props.orgId),
  })
  const [service, setService] = useState<Service>(defaultService as Service)
  useEffect(() => {
    if (editService) {
      setService(editService as Service)
    }
  }, [editService])
  const allTimezones = moment.tz.names()
  // Filter timezones that are within the America region
  const timezones = allTimezones.filter((timezone) =>
    timezone.startsWith('America/'),
  )

  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: postService,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['service'] })
    },
  })

  const updateMutation = useMutation({
    mutationFn: postUpdateService,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['service'] })
    },
  })

  const formatServiceHours = (timeStr: string): string => {
    const parsedTimeStr = new Date(timeStr)
    return `${parsedTimeStr.getFullYear()}-${String(
      parsedTimeStr.getMonth() + 1,
    ).padStart(2, '0')}-${String(parsedTimeStr.getDate()).padStart(
      2,
      '0',
    )} ${String(parsedTimeStr.getHours()).padStart(2, '0')}:${String(
      parsedTimeStr.getMinutes(),
    ).padStart(2, '0')}:${String(parsedTimeStr.getSeconds()).padStart(
      2,
      '0',
    )}.${String(parsedTimeStr.getMilliseconds()).padStart(3, '0')}`
  }

  const handleSubmit = (): void => {
    service.serviceHours = service.serviceHours.map((day) => ({
      ...day,
      startTime: formatServiceHours(day.startTime),
      endTime: formatServiceHours(day.endTime),
    }))
    if (!service.organizationId) {
      service.organizationId = Number(props.orgId)
    }
    if (!edit && !service.active) {
      service.active = true
    }
    if (edit) {
      updateMutation.mutate(service)
    } else {
      mutation.mutate(service)
    }
    props.onClose()
  }

  const handleCheckboxChange = (
    dayNumber: number,
    isChecked: boolean,
  ): void => {
    dayNumber++
    setService((prevService) => {
      const previous = prevService.serviceHours || []
      const newServiceHours = !isChecked
        ? [
            ...previous,
            { dayOfWeek: dayNumber, startTime: '09:00', endTime: '17:00' },
          ] // Default times can be adjusted
        : previous.filter((hour) => hour.dayOfWeek !== dayNumber)

      return { ...prevService, serviceHours: newServiceHours }
    })
  }

  const handleTimeChange = (
    dayNumber: number,
    timeType: string,
    value: string | Dayjs,
  ): void => {
    setService((prevService) => {
      const previous = prevService.serviceHours || []
      const serviceHoursIndex = previous.findIndex(
        (hour) => hour.dayOfWeek === dayNumber,
      )
      if (serviceHoursIndex !== -1) {
        const updatedServiceHours = [...previous]
        updatedServiceHours[serviceHoursIndex] = {
          ...updatedServiceHours[serviceHoursIndex],
          [timeType]: value,
        }
        return { ...prevService, serviceHours: updatedServiceHours }
      }
      return prevService // In case the day is not yet added
    })
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Add A New Service</DialogTitle>
      <DialogContent>
        <Typography>
          Select All of the services needed from the list provided below, or
          write in any services.
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <Grid item xs={6}>
            <TextField
              margin='dense'
              label='Service Title'
              type='text'
              value={service.name || ''}
              onChange={(e) => setService({ ...service, name: e.target.value })}
              fullWidth
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id='service-type'
              select
              margin='dense'
              label='Virtual or In Person Service'
              variant='outlined'
              fullWidth
              value={service.locationType || ''}
              onChange={(e) =>
                setService({ ...service, locationType: e.target.value })
              }
            >
              <MenuItem value='virtual'>Virtual</MenuItem>
              <MenuItem value='inPerson'>In Person</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              margin='dense'
              label='Service Type'
              variant='outlined'
              fullWidth
              value={service.type}
              onChange={(e) => setService({ ...service, type: e.target.value })}
            >
              {serviceTypes.map((type, idx) => (
                <MenuItem key={idx} value={type}>
                  {formatServiceTypes(type)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disablePortal
              options={applications || []}
              getOptionLabel={(option) => option.data.title}
              value={
                applications?.find((app) => app.id === service.applicationId) ||
                null
              }
              onChange={(e, val) =>
                setService({ ...service, applicationId: val?.id as number })
              }
              renderInput={(params) => (
                <TextField
                  margin='dense'
                  variant='outlined'
                  {...params}
                  label='Application'
                />
              )}
            />
          </Grid>
        </Grid>
        <Typography variant='h6'>Service Location</Typography>
        <Typography>
          Provide address information for all locations that the service will be
          provided to community members.
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <Grid item xs={12}>
            <TextField
              margin='dense'
              label='Address Line 1'
              type='text'
              fullWidth
              value={service.addressLine1 || ''}
              onChange={(e) =>
                setService({ ...service, addressLine1: e.target.value })
              }
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin='dense'
              label='Address Line 2'
              type='text'
              fullWidth
              value={service.addressLine2 || ''}
              onChange={(e) =>
                setService({ ...service, addressLine2: e.target.value })
              }
              variant='outlined'
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin='dense'
              label='City'
              type='text'
              fullWidth
              value={service.city || ''}
              onChange={(e) => setService({ ...service, city: e.target.value })}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin='dense'
              label='State'
              type='text'
              fullWidth
              variant='outlined'
              value={service.state || ''}
              onChange={(e) =>
                setService({ ...service, state: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin='dense'
              label='Zip Code'
              type='text'
              fullWidth
              variant='outlined'
              value={service.zipCode || ''}
              onChange={(e) =>
                setService({ ...service, zipCode: e.target.value })
              }
            />
          </Grid>
        </Grid>
        <Typography variant='h6'>Hours of Operation</Typography>
        <Typography>
          Provide the hours of operation for the service listed.
        </Typography>

        <Grid
          container
          spacing={2}
          sx={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              options={timezones || []}
              value={service.timezone || ''}
              onChange={(e, val) =>
                setService({ ...service, timezone: val as string })
              }
              renderInput={(params) => (
                <TextField
                  margin='dense'
                  variant='outlined'
                  {...params}
                  label='Timezone'
                />
              )}
            />
          </Grid>
        </Grid>

        {/* Repeat TextField for address, city, state, zip code */}

        <FormGroup>
          <Grid container spacing={2}>
            {DAYS.map((day, idx) => {
              const isChecked: boolean = service.serviceHours
                ? service.serviceHours.some(
                    (hour) => hour.dayOfWeek === day.number,
                  )
                : false
              return (
                <React.Fragment key={idx}>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={() => handleCheckboxChange(idx, isChecked)}
                          name={day.name}
                        />
                      }
                      label={day.name}
                    />
                  </Grid>
                  {isChecked ? (
                    <React.Fragment>
                      <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label='From'
                            value={
                              isChecked
                                ? dayjs(
                                    service.serviceHours.find(
                                      (hour) => hour.dayOfWeek === day.number,
                                    )?.startTime,
                                    'HH:mm:ss',
                                  )
                                : ''
                            }
                            onChange={(time) =>
                              handleTimeChange(
                                day.number,
                                'startTime',
                                time || '',
                              )
                            }
                            disabled={!isChecked}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label='To'
                            value={
                              isChecked
                                ? dayjs(
                                    service.serviceHours.find(
                                      (hour) => hour.dayOfWeek === day.number,
                                    )?.endTime,
                                    'HH:mm:ss',
                                  )
                                : ''
                            }
                            onChange={(time) =>
                              handleTimeChange(
                                day.number,
                                'endTime',
                                time || '',
                              )
                            }
                            disabled={!isChecked}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <Grid item xs={8}>
                      <TextField
                        margin='dense'
                        label='Closed'
                        type='text'
                        placeholder='Closed'
                        fullWidth
                        disabled
                        variant='outlined'
                      />
                    </Grid>
                  )}
                </React.Fragment>
              )
            })}
          </Grid>
        </FormGroup>

        <Typography variant='h6'>Service Description</Typography>
        <TextField
          margin='dense'
          id='service-description'
          label='Service Description'
          type='text'
          placeholder='Enter your description here'
          fullWidth
          multiline
          rows={4}
          variant='outlined'
          value={service.description || ''}
          onChange={(e) =>
            setService({ ...service, description: e.target.value })
          }
        />

        <Typography variant='h6'>Eligibility Requirements</Typography>
        <TextField
          margin='dense'
          id='eligibility-requirements'
          label='Eligibility Requirements'
          type='text'
          multiline
          rows={4}
          fullWidth
          placeholder='Place a comma “,” between each statement.
example: Member must be age 18 and up, Member must be the guardian parent of at least two children, etc.'
          variant='outlined'
          value={service.eligibilityRequirements || ''}
          onChange={(e) =>
            setService({ ...service, eligibilityRequirements: e.target.value })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant='outlined'
          sx={{ borderRadius: '100px', textTransform: 'none' }}
        >
          Close
        </Button>
        <Button
          sx={{ borderRadius: '100px', textTransform: 'none' }}
          variant='contained'
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import { Service } from '../../types/services'
import { BASE_API_URL } from '../constants'

export const postService = async (service: Service): Promise<Service[]> => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_API_URL}/v1/services`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(service),
  })
  if (!response.ok) {
    throw new Error('Failed to create service')
  }
  return response.json()
}

export const postUpdateService = async (
  service: Service,
): Promise<Service[]> => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_API_URL}/v1/services/updateService`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(service),
  })
  if (!response.ok) {
    throw new Error('Failed to create service')
  }
  return response.json()
}

export const fetchServices = async (
  zipCode?: string,
  radius?: string,
  organizationId?: string,
  allServices?: boolean,
  category?: string,
): Promise<Service[]> => {
  let url = `${BASE_API_URL}/v1/services`
  if (organizationId) {
    const queryParams = new URLSearchParams({ organizationId })
    url += `?${queryParams}`
  }
  if (allServices) {
    if (url.includes('?')) {
      url += `&allServices=${allServices}`
    } else {
      url += `?allServices=${allServices}`
    }
  }
  if (category) {
    const categoryParams = new URLSearchParams({ category })
    if (url.includes('?')) {
      url += `&${categoryParams}`
    } else {
      url += `?${categoryParams}`
    }
  }
  if (zipCode && radius) {
    const zipcodeParams = new URLSearchParams({ zipCode })
    const radiusParams = new URLSearchParams({ radius })
    if (url.includes('?')) {
      url += `&${zipcodeParams}&${radiusParams}`
    } else {
      url += `?${zipcodeParams}&${radiusParams}`
    }
  }
  const token = localStorage.getItem('token')
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch services')
  }
  return response.json()
}

export const fetchOneService = async (id: string): Promise<Service> => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_API_URL}/v1/services/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch services')
  }
  return response.json()
}

import React, { useState } from 'react'
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material'
import Search from '@mui/icons-material/Search'
import { styled } from '@mui/material/styles'
import headerImage from '../public/misc/headerImage.jpg'
import { useLocation, useNavigate } from 'react-router-dom'

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    height: '246px',
  },
}))

const StyledContent = styled(CardContent)({
  position: 'absolute',
  height: 0,
  top: 86,
  bottom: 0,
  left: 0,
  right: 0,
})

export default function LandingHeader(): JSX.Element {
  const [input, setInput] = useState('')
  const navigate = useNavigate()
  const location = useLocation()

  const onSearch = (): void => {
    const to = {
      pathname: location.pathname + 'search',
      search: 'zipcode=' + input,
    }
    navigate(to, { replace: true })
  }

  return (
    <Card sx={{ boxShadow: 'none' }} square>
      <StyledCardMedia>
        <CardMedia
          component='img'
          alt='header background'
          image={headerImage}
        />
        <StyledContent>
          <CardContent>
            <Typography
              sx={{
                fontSize: '20px',
                fontWeight: 700,
                letterSpacing: '0.15px',
                color: 'white',
                textAlign: 'center',
                fontFamily: 'Roboto',
              }}
            >
              Life. Health. Community. Service.
            </Typography>
          </CardContent>
          <OutlinedInput
            sx={{
              height: '56px',
              width: '80%',
              margin: 'auto',
              textAlign: 'center',
              backgroundColor: 'white',
              padding: '16px 32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder='Enter your zipcode'
            endAdornment={
              <InputAdornment position='end'>
                <IconButton aria-label='search' edge='end' onClick={onSearch}>
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </StyledContent>
      </StyledCardMedia>
    </Card>
  )
}

import React, { useState } from 'react'
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardHeader,
  CardActions,
  Button,
} from '@mui/material'
import foodImage from '../../public/misc/food.jpg'
import Avatar from '@mui/material/Avatar'
import { Service } from '../../types/services'
import { formatServiceTypes, getAcronym } from './util'
import ViewServiceDialog from '../dialogs/ViewServiceDialog'

interface ServiceCardProps {
  service: Service
  mediaStyles: React.CSSProperties
  expanded?: boolean
}

export default function ServiceCard(props: ServiceCardProps): JSX.Element {
  const { service, mediaStyles, expanded } = props
  const [open, setOpen] = useState(false)

  const getDetails = (): JSX.Element => {
    return (
      <React.Fragment>
        <Typography variant='body1'>Eligibility Requirements</Typography>
        <Typography variant='body2'>
          Please review the eligibility requirements to be considered for
          support.
        </Typography>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {open && (
        <ViewServiceDialog
          open={open}
          onClose={() => setOpen(false)}
          service={service}
        />
      )}
      <Card
        sx={{ color: '#49454F' }}
        style={{
          borderRadius: '10px',
        }}
      >
        <CardHeader
          avatar={<Avatar>{getAcronym(service.name[0])}</Avatar>}
          title={service.name}
          subheader={formatServiceTypes(service.type)}
        />
        <CardMedia
          component='img'
          alt='service'
          image={foodImage}
          sx={mediaStyles}
        />
        <CardContent>{getDetails()}</CardContent>
        {expanded && (
          <CardActions
            sx={{
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant='contained'
              sx={{ textTransform: 'none', borderRadius: '100px' }}
              onClick={() => setOpen(true)}
            >
              View Service
            </Button>
          </CardActions>
        )}
      </Card>
    </React.Fragment>
  )
}

import { Organization } from '../../types/organizations'
import { BASE_API_URL } from '../constants'

export const fetchOrgs = async (): Promise<Organization[]> => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_API_URL}/v1/organizations`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch services')
  }
  return response.json()
}

export const fetchMultipleOrgs = async (
  ids: number[],
): Promise<Organization[]> => {
  const token = localStorage.getItem('token')
  const fetchPromises: Promise<Response>[] = []

  for (const id of ids) {
    const promise = fetch(`${BASE_API_URL}/v1/organizations/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    fetchPromises.push(promise)
  }

  const responses = await Promise.all(fetchPromises)

  const orgs: Organization[] = await Promise.all(
    responses.map(async (response) => {
      if (!response.ok) {
        throw new Error('Failed to fetch organizations')
      }
      return response.json()
    }),
  )

  return orgs
}

export const fetchOneOrg = async (id: string): Promise<Organization> => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_API_URL}/v1/organizations/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch services')
  }
  return response.json()
}

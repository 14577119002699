import React from 'react'
import { isAuthenticated } from '../api/authentication/auth'

export const AuthContext = React.createContext({
  isAuthenticated: false as boolean,
  token: '' as string | null,
  userId: '' as string | null,
  userType: '' as string | null,
  organizationId: '' as string | null,
})
AuthContext.displayName = 'AuthContext'

type AuthContextProps = {
  children: JSX.Element | JSX.Element[]
}
export function AuthProvider(props: AuthContextProps): JSX.Element {
  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('u=')
  const organizationId = localStorage.getItem('org=')
  const userType = localStorage.getItem('t=')

  return (
    <AuthContext.Provider
      value={{
        token,
        isAuthenticated: isAuthenticated(),
        userId,
        userType,
        organizationId,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

import React, { useMemo, useState, useEffect } from 'react'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  Switch,
  TableBody,
  TableFooter,
  FormControlLabel,
  IconButton,
} from '@mui/material'
import Edit from '@mui/icons-material/Edit'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { fetchServices, postUpdateService } from '../../api/services/services'
import { TableSkeleton } from '../util.tsx/TableUtil'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import { ServiceDialog } from '../dialogs/ServiceDialog'
import { Service } from '../../types/services'

interface OrgServicesProps {
  orgId: string
}

export default function OrgServices(props: OrgServicesProps): JSX.Element {
  const { orgId } = props
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [openDialog, setOpenDialog] = useState(false)
  const [edit, setEdit] = useState(false)
  const [currentService, setCurrentService] = useState({} as Service)
  const [serviceStatuses, setServiceStatuses] = useState<{
    [key: number]: boolean
  }>({})
  const [page, setPage] = useState(0)

  const { data: services, isLoading } = useQuery({
    queryKey: ['services'],
    queryFn: () => fetchServices(undefined, undefined, orgId, true),
  })

  useEffect(() => {
    const statusObj: { [key: number]: boolean } = {}
    services?.map((svc) => {
      statusObj[svc.id] = svc.active
    })
    setServiceStatuses(statusObj)
  }, [services])

  const queryClient = useQueryClient()

  const updateMutation = useMutation({
    mutationFn: postUpdateService,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['service'] })
    },
  })

  const onServiceStatusUpdate = (svc: Service, newStatus: boolean): void => {
    setServiceStatuses({ ...serviceStatuses, [svc.id]: newStatus })
    svc.active = newStatus
    updateMutation.mutate(svc)
  }

  const editService = (service: Service): void => {
    setCurrentService(service)
    setEdit(true)
    setOpenDialog(true)
  }

  const visibleRows = useMemo(
    () => services?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, services],
  )

  return (
    <Grid item xs={12}>
      {openDialog && (
        <ServiceDialog
          open={openDialog}
          orgId={orgId}
          edit={edit}
          editService={currentService}
          onClose={() => {
            setEdit(false)
            setOpenDialog(false)
          }}
        />
      )}
      <Card>
        <CardHeader title='Services' />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                variant='contained'
                sx={{
                  borderRadius: '100px',
                  textTransform: 'none',
                  float: 'right',
                }}
                onClick={() => setOpenDialog(true)}
              >
                + Add New Service
              </Button>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Service ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>ZipCode</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableSkeleton columns={5} />
                ) : (
                  visibleRows?.map((svc, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell component='th' scope='row'>
                          {svc.id}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {svc.name}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {svc.type}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {svc.zipCode}
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => editService(svc)}>
                            <Edit />
                          </IconButton>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={serviceStatuses[svc.id]}
                                onClick={() =>
                                  onServiceStatusUpdate(
                                    svc,
                                    !serviceStatuses[svc.id],
                                  )
                                }
                              />
                            }
                            label={svc.active ? 'Enabled' : 'Disabled'}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: 'All', value: -1 },
                    ]}
                    colSpan={5}
                    count={services?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(e) => {
                      setRowsPerPage(Number(e.target.value))
                      setPage(0)
                    }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  )
}

import React, { useState } from 'react'
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Avatar,
  Grid,
} from '@mui/material'
import { Check } from '@mui/icons-material'

export default function ApplicationSuccess(): JSX.Element {
  const [open, setOpen] = useState(true)
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        style: {
          backgroundColor: 'rgb(238,229,241)',
          borderRadius: '25px',
          padding: '24px',
        },
      }}
      aria-labelledby='success-dialog'
    >
      <Grid container justifyContent='center'>
        <Avatar
          sx={{
            justifyContent: 'center',
            display: 'flex',
            bgcolor: 'white',
          }}
        >
          <Check sx={{ color: '#625B71' }} />
        </Avatar>
      </Grid>
      <DialogTitle id='success-dialog-title'>
        <Typography variant='h4' align='center'>
          Form Successfully Submitted
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='success-dialog-description'>
          Your application has been submitted!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          variant='contained'
          sx={{ borderRadius: '100px' }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

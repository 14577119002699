/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import {
  Grid,
  List,
  ListItem,
  Typography,
  Toolbar,
  AppBar,
} from '@mui/material'
import logo from '../public/logos/logo-blue.svg'
import { useNavigate } from 'react-router-dom'

export default function TermsOfService(): JSX.Element {
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <AppBar elevation={0} position='static' sx={{ backgroundColor: 'white' }}>
        <Toolbar>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ justifyContent: 'center', display: 'flex' }}
            >
              <picture>
                <img
                  src={logo}
                  alt='HealthOPX'
                  style={{ height: '40px', cursor: 'pointer' }}
                  onClick={() => navigate('/')}
                />
              </picture>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid
        container
        sx={{
          backgroundColor: 'rgb(239,239,239)',
          padding: '32px 22px 32px 22px',
          justifyContent: 'center',
          display: 'flex',
          width: '50%',
          marginLeft: '25%',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography variant='h4' sx={{ color: '#6750A4' }}>
            Terms of Service Agreement
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY CLICKING
            “ACCEPTED AND AGREED TO,” YOU AGREE TO THIS AGREEMENT.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            Health OpX Inc. (“OpX”) provides a suite of resources that are
            designed to aid in improving social determinants of health in
            providing healthcare and improvements in quality of life. The
            resources are designed to increase communication capability between
            an individual and his or her social services and/or healthcare
            provider. This Terms of Service Agreement (“Agreement”) will govern
            your access to and use of the http://app.helpopx.com/ website as
            well as any other media form, media channel, mobile website or
            mobile application related, linked or otherwise connected thereto
            (the “Site”). You agree that when you access or use the Site, you
            will do so subject to this Agreement. DO NOT ACCESS OR USE THE SITE
            IF YOU ARE UNWILLING OR UNABLE TO BE BOUND BY THIS AGREEMENT.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            BACKGROUND
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            Each individual user of the Site will create a user profile (“User
            Profile”). Each hospital, non-profit, healthcare provider, or entity
            that uses the Site and has executed a Subscription Agreement
            (individually a “Partner” and collectively “Partners”) will have a
            portal within the Site (“Partner Portal”). The Site provides
            functionality for a Partner via the Partner portal to share and
            solicit information from patients who have a User Profile. Partners
            are permitted to upload onto the Site questionnaires, documents,
            templates, sound recordings, images, videos, and related data
            (collectively, “Templates”) related to the services that they
            provide and deliver those Templates to individual users via the
            Partner Portal. The Templates may be customized and translated for
            different languages which enable the Partner to gather information
            to provide healthcare and/or social services.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            The User Profile is a customizable profile for individuals that aids
            in the ultimate goal of improving social determinants of health
            before, during, and after health care and/or social services. The
            User Profile provides a collaborative portal whereby individuals can
            link to the Partners that they utilize (e.g., their healthcare
            provider or nonprofit social service provider) and transmit data
            related to the individualized health care and/or social services.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            USE OF THE SITE IN GENERAL
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            For individual users of the Site, such as individual subscribers to
            a User Profile, you represent and warrant that you are 18 years old
            or older, and you recognize and agree that you must be 18 years old
            or older to be an individual user of the Site. If you are using the
            User Profile on behalf of a minor child you represent and warrant
            that you are permitted to do so as the minor’s legal guardian. If
            you are an entity user of the Site, you acknowledge that you have
            all necessary approvals and authorizations to enter into this
            Agreement.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            NO MEDICAL ADVICE
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            OpX does not provide any medical advice. Users may be asked
            information about their health in order to aid the User’s applicable
            Partner in providing medical advice and health care. Users should
            discuss all medical decisions with their physician or health care
            provider. OpX is not a health care provider. IF YOU ARE HAVING A
            MEDICAL EMERGENCY, DIAL 911 IMMEDIATELY.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            ACCOUNT REGISTRATION
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            In order to access the services of the Site, you (both individuals
            and entities) must register to create a User Profile or a Partner
            Portal (either, an “Account”). You agree to provide accurate,
            current and complete information during the registration process and
            to update such information to keep it accurate, current, and
            complete. OpX reserves the right to suspend or terminate your
            Account if any information provided during the registration process
            or thereafter proves to be inaccurate, not current, or incomplete.
            You will be asked to create a password when you create your Account.
            OpX does not have access to your passwords and if you forget your
            password you will be asked to create a new one. You are responsible
            for safeguarding your password. You agree that you will not disclose
            your password to any third party and that you will take sole
            responsibility for any activities or actions under your Account,
            whether or not you have authorized such activities or actions. You
            will immediately notify OpX if you become aware of any unauthorized
            use of your Account.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            PRIVACY
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            When you provide information to OpX via the Site or otherwise, you
            are giving OpX the authority to share anonymized data with third
            parties.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            HIPPA
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            OpX is not a “covered entity” as that term is defined under the
            Health Insurance Portability and Accountability Act of 1996 and its
            implementing regulations (“HIPAA”). However, OpX may act as a third
            party “business associate” on behalf of one or more HIPAA covered
            entities. Therefore, certain health information that you provide
            through the Site may be “protected health information” or “PHI”
            under HIPAA. OpX will protect any PHI as required by HIPAA and
            applicable state law. OpX will not use or disclose your PHI, except
            as permitted by HIPAA and applicable state law.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            OWNERSHIP
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '2%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            The Site, User Profile, and Partner Portal and the content provided
            by OpX through the same, including trademarks and copyrightable
            text, are protected by copyright, trademark, and other laws of the
            United States and foreign countries and are proprietary designations
            of OpX. You acknowledge and agree that the Site, User Profile, and
            Partner Portal are the exclusive property of OpX. You will not
            remove, alter, or obscure any copyright, trademark, service mark, or
            other proprietary rights incorporated in or accompanying the Site,
            User Profile, or Partner Portal.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            As between any user and OpX, all User Profile content containing
            personally identifiable information is and remains the sole and
            exclusive property of you as the user; however, personally
            identification information will be shared with Partners. OpX will
            own all data and non-personally identifiable information.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            LICENSE GRANTED BY MEMBER
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            By making available any content, including Templates, on or through
            Site, you hereby grant to OpX a worldwide, irrevocable, perpetual,
            non-exclusive, transferable, royalty-free, fully-paid up right and
            license to access, use, share, view, copy, adapt, translate,
            reformat, and download, such content for the purpose of making such
            content available to you, other users, and for all other use
            associated with the Site. OPX CANNOT CONTROL ANY USE OF CONTENT BY
            ANY PERSON AND YOU ARE SOLELY RESPONSIBLE FOR THE SAME AND ANY
            LIABILITY FOR SUCH ACCESS.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            LICENSE GRANTED BY OPX
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            Subject to your compliance with this Agreement and the separate
            Subscription Agreement, OpX grants you a limited, non-exclusive,
            non-transferable license to view, download, and print any content
            related to Partner Portal or User Profile for your personal and
            non-commercial purposes. You will not use, adopt, modify, prepare
            derivative works based upon, distribute, download, license, sell,
            transfer, publicly display, or otherwise exploit the Site, User
            Profile, or Partner Portal, except as limited by this Agreement. No
            licenses or rights are granted to you by implication or otherwise
            under any intellectual property rights owned or controlled by OpX,
            except as expressly granted in this Agreement.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            TRANSLATIONS
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            OpX makes no representations or warranties related to the language
            translations provided on the Site, User Profile, or Partner Portal.
            If a User creates a new Template or submits responses to a Template,
            that User understands and agrees that the data is transferred to a
            third party for translation into the appropriate language.
            Translations of any materials into language other than English are
            intended to aid in improving social determinants of health. OpX has
            attempted to provide an accurate translation of the original
            material in English, but due to the nuances in translating to a
            foreign language, slight different may exist. OpX is not responsible
            for any errors in translations. OpX shall own all translations.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            GENERAL PROHIBITIONS
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            You agree that you will not:
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: '5%' }}>
          <List sx={{ listStyleType: 'disc', pl: 4 }}>
            <ListItem sx={{ display: 'list-item', color: '#6750A4' }}>
              <Typography
                variant='body1'
                sx={{ color: '#6750A4', fontSize: '14px' }}
              >
                Impersonate or misrepresent your affiliation with any person or
                entity
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', color: '#6750A4' }}>
              <Typography
                variant='body1'
                sx={{ color: '#6750A4', fontSize: '14px' }}
              >
                Violate any law
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', color: '#6750A4' }}>
              <Typography
                variant='body1'
                sx={{ color: '#6750A4', fontSize: '14px' }}
              >
                Post, upload, publish, submit, or transmit any content that: (i)
                infringes, misappropriates, or violates a third party’s privacy
                or any rights of publicity, or any patent, copyright, trademark
                or other intellectual property rights, (ii) violates, or
                encourages any conduct that would violate, any law or give rise
                to civil liability, (iii) is fraudulent, false, misleading or
                deceptive, (iv) is defamatory, obscene, vulgar or offensive, (v)
                promotes discrimination, bigotry, racism, hatred, harassment or
                harm against any person, or (vi) is violent or promotes violence
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', color: '#6750A4' }}>
              <Typography
                variant='body1'
                sx={{ color: '#6750A4', fontSize: '14px' }}
              >
                Use or display any individual element within the Site, User
                Profile or Partner Portal without OpX’s express written consent
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', color: '#6750A4' }}>
              <Typography
                variant='body1'
                sx={{ color: '#6750A4', fontSize: '14px' }}
              >
                Attempt to probe, scan or test the vulnerability of any OpX
                system or network or breach any security or authentication
                measures
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', color: '#6750A4' }}>
              <Typography
                variant='body1'
                sx={{ color: '#6750A4', fontSize: '14px' }}
              >
                Use the Site, User Profile or Partner Portal to send any
                unsolicited or unauthorized advertising, promotional materials,
                email, junk mail, spam or other form of solicitation
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', color: '#6750A4' }}>
              <Typography
                variant='body1'
                sx={{ color: '#6750A4', fontSize: '14px' }}
              >
                Attempt to decipher, decompile, disassemble or reverse engineer
                any of the software used to provide the Site, User Profile or
                Partner Portal.
              </Typography>
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            TERMINATION AND ACCOUNT CANCELLATION
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            If you breach this Agreement, OpX will have the right to suspend or
            disable your Account or terminate this Agreement and disable access
            to User Profile or Partner Portal, as applicable. Additionally, OpX
            reserves the right to revoke your access to and use of the Site,
            User Profile, and Partner Portal at any time, with or without cause.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            DISCLAIMERS
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            THE SITE, USER PROFILE, AND PARTNER PORTAL ARE PROVIDED ‘AS IS’
            WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT
            LIMITING THE FOREGOING, OPX EXPLICITLY DISCLAIMS ANY WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT
            OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF
            DEALING OR USAGE OF TRADE. OPX MAKES NO WARRANTY THAT THE SITE, USER
            PROFILE, OR PARTNER PORTAL WILL MEET YOUR REQUIREMENTS OR BE
            AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. OPX
            MAKES NO WARRANTY REGARDING THE QUALITY OF ANY SERVICES OR CONTENT.
            PROFILE CONTENT IS PROVIDED SOLELY BY MEMBERS AND OPX HAS NO
            RESPONSIBILITY OR LIABILITY FOR THE TRUTHFULNESS OR ACCURACY OF THE
            SAME. NO ADVICE OR INFORMATION, INCLUDING ANY HEALTH CARE INSIGHTS,
            WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE IN THIS AGREEMENT.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            INDEMNITY
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS OPX, ITS OFFICERS,
            DIRECTORS, EMPLOYEES AND AGENTS, FROM AND AGAINST ANY CLAIMS,
            LIABILITIES, DAMAGES, LOSSES, AND EXPENSES, INCLUDING, WITHOUT
            LIMITATION, REASONABLE LEGAL AND ACCOUNTING FEES, ARISING OUT OF OR
            IN ANY WAY CONNECTED WITH: (i) YOUR ACCESS TO OR USE OF THE SITE,
            USER PROFILE, OR PARTNER PORTAL, AS APPLICABLE, (ii) ANY USE OF THE
            SITE, USER PROFILE, OR PARTNER PORTAL FOR ANY UNLAWFUL OR
            INAPPROPRIATE CONDUCT, OR (iii) ANY ACT OR OMISSION BY YOU THAT
            CONSTITUTES AN ACTUAL OR ALLEGED VIOLATION OF THESE TERMS.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            LIMITATION OF LIABILITY
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY
            LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE
            SITE, USER PROFILE, PARTNER PORTAL OR OTHER SERVICE, AS APPLICABLE,
            REMAINS WITH YOU. NEITHER OPX NOR ANY OTHER PERSON OR ENTITY
            INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SITE, USER
            PROFILE, PARTNER PORTAL OR OTHER SERVICE WILL BE LIABLE FOR ANY
            INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING
            LOST PROFITS, LOSS OF DATA OR LOSS OF GOODWILL, SERVICE
            INTERRUPTION, COMPUTER DAMAGE OR THE COST OF SUBSTITUTE PRODUCTS OR
            SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR
            EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS
            OR FROM THE USE OF OR INABILITY TO USE THE SITE, USER PROFILE,
            PARTNER PORTAL OR OTHER SERVICE, WHETHER BASED ON WARRANTY,
            CONTRACT, TORT, OR ANY OTHER LEGAL THEORY. IN NO EVENT WILL OpX’S
            AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THIS
            AGREEMENT OR FROM THE USE OF OR INABILITY TO USE THE SITE, USER
            PROFILE, PARTNER PORTAL OR OTHER SERVICE EXCEED THE AMOUNTS YOU HAVE
            PAID TO OPX FOR USE OF THE SAME, IF YOU HAVE MADE ANY PAYMENTS TO
            OPX FOR USE OF THE SAME OR $100, WHICHEVER IS GREATER. THE
            LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF
            THE BASIS OF THIS AGREEMENT.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            CONTROLLING LAW AND JURISDICTION
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            This Agreement and any action related thereto will be governed by
            the laws of the State of Michigan without regard to its conflict of
            law provisions. Venue for any dispute related to this Agreement, the
            Site, User Profile or Partner Portal will be in Ingham County,
            Michigan Circuit Court or the Federal U.S. District Court for the
            Western District of Michigan.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            ENTIRE AGREEMENT
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            This Agreement and the Subscription Agreement (with respect to
            Partners) constitutes the entire and exclusive understanding and
            agreement between OpX and you regarding the Site, Partner Portal,
            and User Profile, and this Agreement supersedes and replaces any and
            all prior oral or written understandings or agreements between OpX
            and you regarding the same. There are no third party beneficiaries.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            ASSIGNMENT
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            You may not assign or transfer this Agreement, by operation of law
            or otherwise, without OpX’s prior written consent. Any attempt by
            you to assign or transfer this Agreement, without such consent, will
            be null and void. OpX may transfer or assign this Agreement at its
            sole discretion.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            NO WAIVER
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            The failure of OpX to enforce any right or provision of this
            Agreement will not constitute a waiver of future enforcement of that
            right or provision. The waiver of any such right or provision will
            be effective only if in writing and signed by a duly authorized
            representative of OpX.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: '#6750A4', fontSize: '14px' }}>
            CONTACTING OpX
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: '5%', justifyContent: 'center', display: 'flex' }}
        >
          <Typography
            variant='body1'
            sx={{ color: '#6750A4', fontSize: '14px' }}
          >
            If you have questions about this Agreement, please contact OpX at:
            Wesley.ma@healthopx.com
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

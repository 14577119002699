export const formatServiceTypes = (str: string): string => {
  return str
    .replace(/_/g, ' ')
    .replace(/\w\S*/g, function (word: string): string {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
}

export const getAcronym = (name: string): string => {
  return name
    .split(/\s/)
    .reduce((rsp: string, wrd: string) => (rsp += wrd.slice(0, 1)), '')
}

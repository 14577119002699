import React from 'react'
import {
  Drawer,
  List,
  ListItem,
  Grid,
  Button,
  Typography,
  Slider,
  Checkbox,
  FormControlLabel,
  Divider,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Close as CloseIcon } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { formatServiceTypes } from '../services/util'
import { useLocation, useNavigate } from 'react-router-dom'

interface FilterProps {
  open: boolean
  filters: string[]
  setOpen: (open: boolean) => void
  appliedFilters: string[]
  setAppliedFilters: (filters: string[]) => void
  resultCount: number
}

export default function Filter(props: FilterProps): JSX.Element {
  const {
    open,
    setOpen,
    filters,
    appliedFilters,
    setAppliedFilters,
    resultCount,
  } = props
  const theme = useTheme()
  const navigate = useNavigate()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const radius = searchParams.get('radius') || 50
  const zipcode = searchParams.get('zipcode') || ''

  const handleUpdateFilters = (filter: string, value: boolean): void => {
    if (value) {
      setAppliedFilters([...appliedFilters, filter])
      return
    }
    setAppliedFilters(appliedFilters.filter((type) => type !== filter))
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ sx: { width: isMobile ? '100%' : '25%' } }}
    >
      <List sx={{ backgroundColor: 'rgb(239,239,239)' }} dense>
        <Grid
          container
          sx={{ padding: '8px 24px 8px 24px', backgroundColor: 'white' }}
        >
          <Grid item xs={7} justifyContent='flex-end' display='flex'>
            <Typography variant='h5'>{resultCount} Results</Typography>
          </Grid>
          <Grid item xs={5} justifyContent='flex-end' display='flex'>
            <CloseIcon onClick={() => setOpen(false)} sx={{ padding: '5px' }} />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            padding: '8px 24px 8px 24px',
          }}
        >
          <Grid item xs={12}>
            <Typography variant='body2' sx={{ fontWeight: '500' }}>
              Distance from my location(mi)
            </Typography>
            <Slider
              aria-label='distance-slider'
              defaultValue={Number(radius)}
              onChange={(e, value) => {
                e.preventDefault()
                navigate(`/search?zipcode=${zipcode}&radius=${value}`)
              }}
              step={10}
              marks={[
                {
                  value: 0,
                  label: '0mi',
                },
                {
                  value: 50,
                  label: '50mi',
                },
                {
                  value: 100,
                  label: '100mi',
                },
              ]}
              min={0}
              max={100}
              valueLabelDisplay='auto'
            />
          </Grid>
        </Grid>
        <Divider
          variant='middle'
          sx={{
            borderColor: 'black',
            marginTop: '32px',
            marginBottom: '32px',
          }}
        />
        <ListItem sx={{ fontSize: '14px', fontWeight: '500' }}>
          Select any serices listed below
        </ListItem>
        {filters.map((type, idx) => (
          <ListItem key={idx}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => handleUpdateFilters(type, e.target.checked)}
                  checked={appliedFilters.indexOf(type) !== -1}
                />
              }
              label={formatServiceTypes(type)}
            />
          </ListItem>
        ))}
      </List>
      <Grid
        container
        sx={{ padding: '32px 24px 32px 24px' }}
        alignContent='center'
      >
        <Grid container item xs={6} justifyContent='start'>
          <Button
            sx={{ textTransform: 'none', borderRadius: '100px' }}
            variant='outlined'
            onClick={() => setAppliedFilters([])}
          >
            Reset all filters
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  )
}

import React, { useState } from 'react'
import {
  Grid,
  TextField,
  Button,
  Typography,
  Link,
  Divider,
  Autocomplete,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import CallIcon from '@mui/icons-material/Call'
import { UserInfo } from '../types/users'
import { SignUp } from '../api/authentication/auth'
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha'
import { CAPTCHA_KEY } from '../api/constants'
import { formatPhoneNumber, isValidEmail, isValidPassword } from './util'
import { useQuery } from '@tanstack/react-query'
import { fetchOrgs } from '../api/organizations/organizations'
import { Organization } from '../types/organizations'

interface SignupProps {
  setOpen: (open: boolean) => void
  isEmployee: boolean
}
export default function EmployeeSignup(props: SignupProps): JSX.Element {
  const { setOpen, isEmployee } = props
  const { data: orgs } = useQuery({
    queryKey: ['organizations'],
    queryFn: () => fetchOrgs(),
  })

  const [createAccount, setCreateAccount] = useState(false)
  const [passwordValid, setPasswordValid] = useState(true)
  const [signupEnable, setSignupEnable] = useState(false)
  const [emailValid, setEmailValid] = useState(true)

  const [userInfo, setUserInfo] = useState<UserInfo>({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    dateOfBirth: '',
    city: '',
    state: '',
    organizationId: 3333,
    organizationIds: [3333],
    userType: isEmployee ? 'EMPLOYEE' : 'PATIENT',
  } as UserInfo)
  const navigate = useNavigate()

  const handleCreateAccount = async (): Promise<void> => {
    const passwordIsValid = isValidPassword(userInfo.password)
    const emailIsValid = isValidEmail(userInfo.email)
    if (createAccount && passwordIsValid && emailIsValid) {
      try {
        await SignUp(userInfo)
        setOpen(true)
        setCreateAccount(false)
      } catch (error) {
        console.error('Error in SignUp: ', error)
        setCreateAccount(true)
      }
    }
    if (passwordIsValid && emailIsValid) {
      setCreateAccount(true)
    } else {
      setPasswordValid(false)
      setEmailValid(false)
    }
  }

  const handleCaptchaChange = (): void => {
    setSignupEnable(true)
  }

  const renderEmailandPassword = (): JSX.Element => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Enter Email'
            error={!emailValid}
            value={userInfo.email || ''}
            helperText={!passwordValid ? 'Invalid email' : ''}
            variant='filled'
            onChange={(e) =>
              setUserInfo({ ...userInfo, email: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '32px' }}>
          <TextField
            fullWidth
            label='Enter Password'
            variant='filled'
            error={!passwordValid}
            type='password'
            value={userInfo.password || ''}
            helperText={!passwordValid ? 'Invalid password' : ''}
            onChange={(e) =>
              setUserInfo({ ...userInfo, password: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2'>- At least 8 characters</Typography>
          <Typography variant='body2'>- Mix of letters and numbers</Typography>
          <Typography variant='body2'>
            - At least 1 special character
          </Typography>
          <Typography variant='body2'>
            - At least 1 lowercase letter and 1 uppercase letter
          </Typography>
        </Grid>
      </React.Fragment>
    )
  }

  const renderAccountDetails = (): JSX.Element => {
    return (
      <React.Fragment>
        <Grid item xs={1}>
          <PermIdentityIcon />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            label='First Name'
            value={userInfo.firstName || ''}
            variant='filled'
            onChange={(e) =>
              setUserInfo({ ...userInfo, firstName: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Last Name'
            variant='filled'
            value={userInfo.lastName || ''}
            onChange={(e) =>
              setUserInfo({ ...userInfo, lastName: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={1}>
          <CallIcon />
        </Grid>
        <Grid item xs={11}>
          <TextField
            fullWidth
            label='Phone Number'
            value={userInfo.phoneNumber || ''}
            variant='filled'
            onChange={(e) =>
              setUserInfo({
                ...userInfo,
                phoneNumber: formatPhoneNumber(e.target.value),
              })
            }
          />
        </Grid>
        {isEmployee && (
          <React.Fragment>
            <Grid item xs={1}>
              <PermIdentityIcon />
            </Grid>
            <Grid item xs={11}>
              <Autocomplete
                disablePortal
                options={orgs || []}
                getOptionLabel={(option: Organization) => option.name}
                value={
                  orgs?.find(
                    (org: Organization) => org.id === userInfo.organizationId,
                  ) || null
                }
                onChange={(e, val) =>
                  setUserInfo({
                    ...userInfo,
                    organizationId: val?.id as number,
                    organizationIds: [val?.id as number],
                  })
                }
                renderInput={(params) => (
                  <TextField
                    variant='filled'
                    {...params}
                    label='Organization'
                  />
                )}
              />
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12} justifyContent='center' display='flex'>
          <ReCAPTCHA
            sitekey={CAPTCHA_KEY as string}
            onChange={handleCaptchaChange}
          />
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <Grid container sx={{ marginTop: '32px' }} spacing={3}>
      {createAccount ? renderAccountDetails() : renderEmailandPassword()}
      <Grid item xs={12} sx={{ marginTop: '16px' }}>
        <Button
          fullWidth
          variant='contained'
          sx={{ borderRadius: '100px', textTransform: 'none' }}
          disabled={createAccount && !signupEnable}
          onClick={handleCreateAccount}
        >
          {createAccount ? 'Create Account' : 'Submit'}
        </Button>
      </Grid>
      <Grid item xs={12} display='flex' justifyContent='center'>
        <Typography variant='body1'>
          By submitting, I accept HealthOpX's{' '}
          <Link
            variant='body1'
            href='/terms-of-service'
            sx={{ cursor: 'pointer' }}
          >
            terms of use
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '32px' }}>
        <Divider>or</Divider>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '16px' }}>
        <Button
          fullWidth
          variant='contained'
          sx={{ borderRadius: '100px', textTransform: 'none' }}
          onClick={() => navigate('/login')}
        >
          Log In
        </Button>
      </Grid>
    </Grid>
  )
}

import React, { useState } from 'react'
import { Grid, Tabs, Tab } from '@mui/material'
import SavedApplications from './SavedApplications'

export default function Saves(): JSX.Element {
  const [value] = useState(0)

  return (
    <Grid
      container
      sx={{
        backgroundColor: 'rgb(239,239,239)',
      }}
    >
      <Grid item xs={12}>
        <Tabs value={value}>
          <Tab label='Applications' sx={{ textTransform: 'none' }} />
        </Tabs>
        {value === 0 && <SavedApplications />}
      </Grid>
    </Grid>
  )
}

import { Grid, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { fetchOneOrg } from '../../api/organizations/organizations'
import OrgMembers from './OrgMembers'
import OrgServices from './OrgServices'
import OrgApplications from './OrgApplications'
import OrgToolbar from '../../toolbars/OrgToolbar'
import OrgReferrals from './OrgReferrals'
import OrgEmployees from './OrgEmployees'
import OrgAnalytics from './OrgAnalytics'

export default function OrgDashboard(): JSX.Element {
  const { orgId } = useParams()
  const [tab, setTab] = useState(0)

  const { data: org } = useQuery({
    queryKey: ['organizations', orgId],
    queryFn: () => fetchOneOrg(orgId || ''),
  })

  return (
    <Grid container sx={{ backgroundColor: 'rgb(239,239,239)' }}>
      <OrgToolbar title={org?.name || ''} />
      <Tabs
        value={tab}
        onChange={(_, newValue) => {
          setTab(newValue)
        }}
      >
        <Tab label='Members' sx={{ textTransform: 'none' }} />
        <Tab label='Employees' sx={{ textTransform: 'none' }} />
        <Tab label='Services' sx={{ textTransform: 'none' }} />
        <Tab label='Referrals' sx={{ textTransform: 'none' }} />
        <Tab label='Applications' sx={{ textTransform: 'none' }} />
        <Tab label='Analytics' sx={{ textTransform: 'none' }} />
      </Tabs>
      {tab === 0 && <OrgMembers orgId={orgId || ''} />}
      {tab === 1 && <OrgEmployees orgId={orgId || ''} />}
      {tab === 2 && <OrgServices orgId={orgId || ''} />}
      {tab === 3 && <OrgReferrals orgId={orgId || ''} />}
      {tab === 4 && <OrgApplications orgId={orgId || ''} />}
      {tab === 5 && <OrgAnalytics orgId={orgId || ''} />}
    </Grid>
  )
}

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import LandingHeader from './LandingHeader'
import LandingServices from './LandingServices'
import logo from '../public/logos/logo-purple.svg'
import { Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default function LandingPage(): JSX.Element {
  const navigate = useNavigate()
  return (
    <Grid
      container
      sx={{
        width: '100%',
        height: '100px',
        top: 0,
        display: 'block',
      }}
    >
      <div style={{ backgroundColor: 'rgb(239,239,239)' }}>
        <Grid item>
          <LandingHeader />
        </Grid>
        <Grid item xs={12} sx={{ padding: '16px', marginBottom: '16px' }}>
          <LandingServices />
        </Grid>
        <Grid item xs={12} sx={{ paddingBottom: '32px' }}>
          <picture
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={logo}
              alt='HealthOPX'
              style={{ height: '20px', opacity: '0.4' }}
              onClick={() => navigate('/search')}
            />
          </picture>
          <Typography
            sx={{
              color: '#79747E',
              display: 'flex',
              justifyContent: 'center',
              fontSize: '11px',
            }}
          >
            v1.0
          </Typography>
        </Grid>
      </div>
    </Grid>
  )
}

import React from 'react'
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  FormGroup,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Service } from '../../types/services'

interface ViewServiceDialogProps {
  service: Service
  open: boolean
  onClose: () => void
}

const DAYS = [
  { name: 'Monday', number: 1 },
  { name: 'Tuesday', number: 2 },
  { name: 'Wednesday', number: 3 },
  { name: 'Thursday', number: 4 },
  { name: 'Friday', number: 5 },
  { name: 'Saturday', number: 6 },
  { name: 'Sunday', number: 7 },
]

export default function ViewServiceDialog(
  props: ViewServiceDialogProps,
): JSX.Element {
  const { service } = props
  const navigate = useNavigate()

  const formatTime = (timeStr: string): string => {
    const time = new Date(`1970-01-01T${timeStr}`)
    const formattedTime = time.toLocaleString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })
    return formattedTime
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Service Details</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <Grid item xs={6}>
            <TextField
              margin='dense'
              disabled
              label='Service Title'
              type='text'
              value={service.name || ''}
              fullWidth
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin='dense'
              label='Location Type'
              type='text'
              disabled
              value={service.locationType || ''}
              fullWidth
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin='dense'
              label='Service Type'
              type='text'
              variant='outlined'
              disabled
              fullWidth
              value={service.type}
            />
          </Grid>
        </Grid>
        <Typography variant='h6'>Service Location</Typography>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <Grid item xs={12}>
            <TextField
              margin='dense'
              label='Address Line 1'
              type='text'
              fullWidth
              disabled
              value={service.addressLine1 || ''}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin='dense'
              label='Address Line 2'
              type='text'
              disabled
              fullWidth
              value={service.addressLine2 || ''}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin='dense'
              label='City'
              type='text'
              disabled
              fullWidth
              value={service.city || ''}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin='dense'
              label='State'
              type='text'
              disabled
              fullWidth
              variant='outlined'
              value={service.state || ''}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin='dense'
              label='Zip Code'
              type='text'
              disabled
              fullWidth
              variant='outlined'
              value={service.zipCode || ''}
            />
          </Grid>
        </Grid>
        <Typography variant='h6'>Hours of Operation</Typography>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <Grid item xs={12}>
            <TextField
              margin='dense'
              label='Timezone'
              type='text'
              disabled
              variant='outlined'
              fullWidth
              value={service.timezone || ''}
            />
          </Grid>
        </Grid>
        <FormGroup>
          <Grid container spacing={2}>
            {DAYS.map((day, idx) => {
              const isChecked: boolean = service.serviceHours
                ? service.serviceHours.some(
                    (hour) => hour.dayOfWeek === day.number,
                  )
                : false
              return (
                <React.Fragment key={idx}>
                  {isChecked ? (
                    <React.Fragment>
                      <Grid item xs={4}>
                        <Typography variant='h6'>{day.name}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          margin='dense'
                          label='From'
                          type='text'
                          disabled
                          variant='outlined'
                          fullWidth
                          value={formatTime(
                            service.serviceHours.find(
                              (hour) => hour.dayOfWeek === day.number,
                            )?.startTime as string,
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          margin='dense'
                          label='To'
                          type='text'
                          disabled
                          variant='outlined'
                          fullWidth
                          value={formatTime(
                            service.serviceHours.find(
                              (hour) => hour.dayOfWeek === day.number,
                            )?.endTime as string,
                          )}
                        />
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid item xs={4}>
                        <Typography variant='body1'>{day.name}</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          margin='dense'
                          label='Closed'
                          type='text'
                          placeholder='Closed'
                          fullWidth
                          disabled
                          variant='outlined'
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )
            })}
          </Grid>
        </FormGroup>

        <Typography variant='h6'>Service Description</Typography>
        <TextField
          margin='dense'
          id='service-description'
          label='Service Description'
          type='text'
          placeholder='Enter your description here'
          fullWidth
          multiline
          disabled
          rows={4}
          variant='outlined'
          value={service.description || ''}
        />

        <Typography variant='h6'>Eligibility Requirements</Typography>
        <TextField
          margin='dense'
          id='eligibility-requirements'
          label='Eligibility Requirements'
          type='text'
          multiline
          disabled
          rows={4}
          fullWidth
          placeholder='Place a comma “,” between each statement.
example: Member must be age 18 and up, Member must be the guardian parent of at least two children, etc.'
          variant='outlined'
          value={service.eligibilityRequirements || ''}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant='outlined'
          sx={{ borderRadius: '100px', textTransform: 'none' }}
        >
          Close
        </Button>
        <Button
          sx={{ borderRadius: '100px', textTransform: 'none' }}
          variant='contained'
          onClick={() => navigate(`/apply/${service.id}`)}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Typography,
  DialogActions,
} from '@mui/material'
import 'survey-core/survey.i18n.js'
import 'survey-creator-core/survey-creator-core.i18n.js'
import 'survey-core/defaultV2.css'
import 'survey-creator-core/survey-creator-core.css'
import React from 'react'
import { Application } from '../../types/applications'
import { Model, Survey } from 'survey-react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateApplicationAnswers } from '../../api/applications/applications'

interface ViewApplicationDialogProps {
  open: boolean
  app: Application
  answers?: Application
  viewMode?: boolean
  onClose: () => void
}

export function ViewApplicationDialog(
  props: ViewApplicationDialogProps,
): JSX.Element {
  const { app, answers, viewMode } = props

  const model = new Model(app?.data)
  if (answers) {
    model.data = answers
  }

  if (viewMode) {
    model.showNavigationButtons = false
    model.mode = 'dispaly'
  }

  const queryClient = useQueryClient()

  const updateMutation = useMutation({
    mutationFn: updateApplicationAnswers,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['applicationAnswers'] })
    },
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (formAnswers: any): void => {
    updateMutation.mutate({ data: formAnswers, id: answers?.id as number })
    props.onClose()
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth>
      <DialogTitle>View Application</DialogTitle>
      <DialogContent>
        {viewMode && (
          <Typography variant='h6'>Currently in View Mode</Typography>
        )}
        <Grid
          container
          spacing={2}
          sx={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <Survey
            model={model} // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onComplete={(sender: { data: any }) => handleSubmit(sender.data)}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant='outlined'
          sx={{ borderRadius: '100px', textTransform: 'none' }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

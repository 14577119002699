import {
  TextField,
  Button,
  Grid,
  ListItem,
  List,
  IconButton,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material'
import { Search, ExpandMore } from '@mui/icons-material'
import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { fetchUsersByNameAndDOB } from '../../../api/users/users'
import { UserInfo } from '../../../types/users'

interface MemberStepProps {
  member?: UserInfo
  setCurrentUser: (currentUser: UserInfo) => void
  disabled?: boolean
}

export function MemberStep(props: MemberStepProps): JSX.Element {
  const [user, setUser] = useState<UserInfo>(props.member || ({} as UserInfo))
  const [selected, setSelected] = useState(props.member?.id || '')

  const { data: member, refetch: refetchMembers } = useQuery({
    queryKey: ['filteredUsers'],
    queryFn: () =>
      fetchUsersByNameAndDOB(user.firstName, user.lastName, user.dateOfBirth),
  })

  const searchUsers = (): void => {
    refetchMembers()
  }

  const renderUserSummary = (member: UserInfo): string => {
    return `${member.firstName} ${member.lastName} - ${member.dateOfBirth}`
  }

  const renderUserDetails = (member: UserInfo): JSX.Element => {
    return (
      <React.Fragment>
        <Typography>
          Member Name: {member.firstName} {member.lastName}
        </Typography>
        <Typography>Date of Birth: {member.dateOfBirth}</Typography>
        <Typography>Zipcode: {member.zipCode}</Typography>
        <Typography>Email: {member.email}</Typography>
        <Typography>Phone Number: {member.phoneNumber}</Typography>
      </React.Fragment>
    )
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{ marginTop: '10px', marginBottom: '10px' }}
    >
      <Grid item xs={3}>
        <TextField
          margin='dense'
          variant='outlined'
          label='First Name'
          value={user.firstName || ''}
          onChange={(e) => setUser({ ...user, firstName: e.target.value })}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          margin='dense'
          variant='outlined'
          label='Last Name'
          value={user.lastName || ''}
          onChange={(e) => setUser({ ...user, lastName: e.target.value })}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          margin='dense'
          variant='outlined'
          label='Date Of Birth'
          placeholder='YYYY-MM-DD'
          value={user.dateOfBirth || ''}
          onChange={(e) => setUser({ ...user, dateOfBirth: e.target.value })}
        />
      </Grid>
      <Grid item xs={3}>
        <IconButton
          onClick={searchUsers}
          size='large'
          sx={{ marginTop: '10px' }}
        >
          <Search />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <List>
          {member && (
            <ListItem
              secondaryAction={
                <Button
                  onClick={() => {
                    setSelected(member.id)
                    props.setCurrentUser(member)
                  }}
                  variant={selected === member.id ? 'contained' : 'outlined'}
                  sx={{
                    borderRadius: '100px',
                    textTransform: 'none',
                  }}
                >
                  {selected === member.id ? 'Selected' : 'Select'}
                </Button>
              }
            >
              <Accordion sx={{ width: '80%' }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  {renderUserSummary(member)}
                </AccordionSummary>
                <AccordionDetails>{renderUserDetails(member)}</AccordionDetails>
              </Accordion>
            </ListItem>
          )}
        </List>
      </Grid>
    </Grid>
  )
}

import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Button,
  Grid,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { fetchOneUser } from '../../api/users/users'
import { MemberDialog } from '../dialogs/MemberDialog'
import { UserInfo } from '../../types/users'

interface MemberInfoProps {
  id: string
}

export default function MemberInfo(props: MemberInfoProps): JSX.Element {
  const [openDialog, setOpenDialog] = useState(false)
  const { data: user, isLoading } = useQuery({
    queryKey: ['users'],
    queryFn: () => fetchOneUser(props.id || ''),
  })

  const renderName = (user: UserInfo): string => {
    if (user.firstName && user.lastName) {
      return user.firstName[0] + ' ' + user.lastName[0]
    }
    return ''
  }
  return (
    <React.Fragment>
      {!isLoading && (
        <MemberDialog
          open={openDialog}
          edit
          editMember={user}
          onClose={() => setOpenDialog(false)}
        />
      )}
      <Card>
        <CardHeader title='Member Information' sx={{ fontSize: '32px' }} />
        {user && (
          <CardContent sx={{ textAlign: 'center' }}>
            <Avatar
              sx={{
                height: '128px',
                width: '128px',
                margin: 'auto',
                marginBottom: '15px',
              }}
            >
              {renderName(user)}
            </Avatar>
            <Grid container sx={{ marginBottom: '15px' }}>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '14px' }}>Member Name</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '14px' }}>
                  {user?.firstName} {user?.lastName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ marginBottom: '15px' }}>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '14px' }}>Email</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '14px' }}>{user?.email}</Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ marginBottom: '15px' }}>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '14px' }}>Member ID</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '14px' }}>{user?.id}</Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ marginBottom: '15px' }}>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '14px' }}>Phone Number</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '14px' }}>
                  {user?.phoneNumber}
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ marginBottom: '15px' }}>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '14px' }}>DOB</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '14px' }}>
                  {user?.dateOfBirth}
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ marginBottom: '15px' }}>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '14px' }}>ZIP Code</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '14px' }}>
                  {user?.zipCode}
                </Typography>
              </Grid>
            </Grid>
            <Button
              variant='contained'
              sx={{
                marginTop: '15px',
                borderRadius: '100px',
                textTransform: 'none',
              }}
              startIcon={<EditIcon />}
              onClick={() => setOpenDialog(true)}
            >
              Edit Member Information
            </Button>
          </CardContent>
        )}
      </Card>
    </React.Fragment>
  )
}

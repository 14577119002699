import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  TextField,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TableFooter,
  TablePagination,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import {
  fetchIncomingReferralsByOrgId,
  fetchOutgoingReferralsByOrg,
  updateReferralStatus,
} from '../../api/referrals/referrals'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { TableSkeleton } from '../util.tsx/TableUtil'
import { Referral, ReferralStatus } from '../../types/referrals'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import { ReferralDialog } from '../dialogs/referral-flow/ReferralDialog'
import { ViewApplicationDialog } from '../dialogs/ViewApplicationDialog'
import { Application } from '../../types/applications'

interface OrgReferralsProps {
  orgId: string
}

export default function OrgReferrals(props: OrgReferralsProps): JSX.Element {
  // const [openNotes, setOpenNotes] = useState(false)
  const [openReferral, setOpenReferral] = useState(false)
  const [refs, setRefs] = useState<Referral[]>([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [filterIncoming, setFilterIncoming] = useState(true)
  const [filterOutgoing, setFilterOutgoing] = useState(true)
  const [openApp, setOpenApp] = useState(false)
  const [currentApp, setCurrentApp] = useState<Application>({} as Application)
  const [currentAnswers, setCurrentAnswers] = useState<Application>(
    {} as Application,
  )
  const [sortByStatus, setSortByStatus] = useState<'asc' | 'desc'>('asc')
  const [sortByDate, setSortByDate] = useState<'asc' | 'desc'>('asc')

  // const [currentNotes, setCurrentNotes] = useState<string[]>([])
  const { data: incomingReferrals, isLoading: isLoadingIn } = useQuery({
    queryKey: ['incoming-referrals'],
    queryFn: () => fetchIncomingReferralsByOrgId(props.orgId),
  })
  const { data: outgoingReferrals, isLoading: isLoadingOut } = useQuery({
    queryKey: ['outgoing-referrals'],
    queryFn: () => fetchOutgoingReferralsByOrg(props.orgId),
  })

  const queryClient = useQueryClient()

  const updateStatus = useMutation({
    mutationFn: updateReferralStatus,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['referral'] })
    },
  })

  const [statuses, setStatuses] = useState<{ [key: number]: string }>({})

  const setIncomingReferrals = (): Referral[] => {
    if (incomingReferrals) {
      return incomingReferrals.map((ref) => {
        return { ...ref, source: 'INBOUND' }
      })
    }
    return []
  }

  const setOutgoingReferrals = (): Referral[] => {
    if (outgoingReferrals) {
      return outgoingReferrals.map((ref) => {
        return { ...ref, source: 'OUTBOUND' }
      })
    }
    return []
  }

  useEffect(() => {
    if (incomingReferrals && outgoingReferrals) {
      const combined = [...incomingReferrals, ...outgoingReferrals]
      const statusObj: { [key: number]: string } = {}
      combined.map((ref) => {
        statusObj[ref.id] = ref.status
      })
      setStatuses(statusObj)
      if (filterIncoming && filterOutgoing) {
        setRefs([...setOutgoingReferrals(), ...setIncomingReferrals()])
      } else if (filterIncoming) {
        setRefs([...setIncomingReferrals()])
      } else if (filterOutgoing) {
        setRefs([...setOutgoingReferrals()])
      }
    }
  }, [incomingReferrals, outgoingReferrals, filterIncoming, filterOutgoing])

  const onUpdateStatus = (
    source: string,
    referralId: number,
    newStatus: ReferralStatus,
  ): void => {
    setStatuses({ ...statuses, [referralId]: newStatus })
    updateStatus.mutate({ referralId, newStatus })
  }

  const formatTime = (timeStr: string): string => {
    const date = new Date(timeStr)
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
  }

  // const openNotesDialog = (refNotes: string[]): void => {
  //   setCurrentNotes(refNotes)
  //   setOpenNotes(true)
  // }

  const toggleSortByStatus = (): void => {
    setSortByStatus(sortByStatus === 'asc' ? 'desc' : 'asc')
  }

  const toggleSortByDate = (): void => {
    setSortByDate(sortByDate === 'asc' ? 'desc' : 'asc')
  }

  const visibleRows = useMemo(
    () => refs?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, refs],
  )

  const sortedVisibleRows = useMemo(() => {
    let sortedRows = [...visibleRows]

    if (sortByStatus !== 'asc') {
      sortedRows = sortedRows.sort((a, b) => a.status.localeCompare(b.status))
    } else {
      sortedRows = sortedRows.sort((a, b) => b.status.localeCompare(a.status))
    }

    if (sortByDate !== 'asc') {
      sortedRows = sortedRows.sort(
        (a, b) =>
          new Date(a.createTimestamp).getTime() -
          new Date(b.createTimestamp).getTime(),
      )
    } else {
      sortedRows = sortedRows.sort(
        (a, b) =>
          new Date(b.createTimestamp).getTime() -
          new Date(a.createTimestamp).getTime(),
      )
    }

    return sortedRows
  }, [visibleRows, sortByStatus, sortByDate])

  const handleViewApplication = (
    app: Application,
    answers: Application,
  ): void => {
    setCurrentAnswers(answers)
    setCurrentApp(app)
    setOpenApp(true)
  }

  return (
    <Grid item xs={12}>
      {/* {openNotes && (
        <NotesDialog
          open={openNotes}
          onClose={() => setOpenNotes(false)}
          notes={currentNotes}
        />
      )} */}
      {openApp && (
        <ViewApplicationDialog
          open={openApp}
          onClose={() => setOpenApp(false)}
          app={currentApp}
          answers={currentAnswers.data}
        />
      )}
      {openReferral && (
        <ReferralDialog
          open={openReferral}
          onClose={() => setOpenReferral(false)}
          orgId={Number(props.orgId)}
        />
      )}
      <Card>
        <CardHeader title='Referrals' />
        <CardContent>
          <Grid container justifyContent='flex-end'>
            <Grid item xs={6}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterIncoming}
                      onChange={(e) => setFilterIncoming(e.target.checked)}
                    />
                  }
                  label='Inbound'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterOutgoing}
                      onChange={(e) => setFilterOutgoing(e.target.checked)}
                    />
                  }
                  label='Outbound'
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant='contained'
                sx={{
                  borderRadius: '100px',
                  textTransform: 'none',
                  float: 'right',
                }}
                onClick={() => setOpenReferral(true)}
              >
                + Create Referral
              </Button>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Service</TableCell>
                  <TableCell>Service Type</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Member</TableCell>
                  <TableCell>Organization Applied To</TableCell>
                  <TableCell
                    onClick={toggleSortByDate}
                    sx={{ cursor: 'pointer' }}
                  >
                    Date Sent {sortByDate === 'asc' ? '▲' : '▼'}
                  </TableCell>
                  <TableCell>Application</TableCell>
                  <TableCell
                    onClick={toggleSortByStatus}
                    sx={{ cursor: 'pointer' }}
                  >
                    Status {sortByStatus === 'asc' ? '▲' : '▼'}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoadingIn || isLoadingOut ? (
                  <TableSkeleton columns={8} />
                ) : (
                  sortedVisibleRows?.map((ref, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell component='th' scope='row'>
                          {ref.service?.name}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {ref.service?.type}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {ref.source}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <Link href={`/member/${ref.patientId}`}>
                            {ref.patient.firstName} {ref.patient.lastName}
                          </Link>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {ref.organization.name}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {formatTime(ref.createTimestamp)}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {ref.service.applicationId && (
                            <Button
                              onClick={() =>
                                handleViewApplication(
                                  ref.application,
                                  ref.applicationResponse,
                                )
                              }
                              variant='outlined'
                              sx={{
                                borderRadius: '100px',
                                textTransform: 'none',
                              }}
                            >
                              View Application
                            </Button>
                          )}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {ref.organization.id === Number(props.orgId) ? (
                            <TextField
                              select
                              margin='dense'
                              label='Status'
                              variant='outlined'
                              fullWidth
                              value={statuses[ref.id] || ref.status}
                              onChange={(e) =>
                                onUpdateStatus(
                                  ref.source || '',
                                  ref.id,
                                  e.target.value as ReferralStatus,
                                )
                              }
                            >
                              <MenuItem value='REQUESTED'>Requested</MenuItem>
                              <MenuItem value='OPEN'>Open</MenuItem>
                              <MenuItem value='IN_PROGRESS'>
                                In Progress
                              </MenuItem>
                              <MenuItem value='NEEDS_MORE_INFO'>
                                Need More Info
                              </MenuItem>
                              <MenuItem value='PENDING'>Pending</MenuItem>
                              <MenuItem value='COMPLETED'>Completed</MenuItem>
                              <MenuItem value='CANCELLED'>Cancelled</MenuItem>
                            </TextField>
                          ) : (
                            ref.status
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: 'All', value: -1 },
                    ]}
                    colSpan={8}
                    count={refs?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(e) => {
                      setRowsPerPage(Number(e.target.value))
                      setPage(0)
                    }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  )
}

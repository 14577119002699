import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  Typography,
  DialogActions,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { UserInfo } from '../../types/users'
import { postUser, putUser } from '../../api/users/users'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface MemberDialogProps {
  orgId?: number
  open: boolean
  editMember?: UserInfo
  edit?: boolean
  onClose: () => void
}

export function MemberDialog(props: MemberDialogProps): JSX.Element {
  const { edit, editMember } = props
  const defaultMember = edit
    ? editMember
    : {
        organizationId: props.orgId,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        password: '',
        userType: 'PATIENT',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        dateOfBirth: '',
        active: true,
      }

  const [member, setMember] = useState<UserInfo>(defaultMember as UserInfo)
  const [isPasswordValid, setIsPasswordValid] = useState(false)

  const validatePassword = (password: string): boolean => {
    if (password) {
      const minLength = 8
      const hasNumber = /\d/.test(password)
      const hasSpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(
        password,
      )
      const hasUpperCase = /[A-Z]/.test(password)
      const hasLowerCase = /[a-z]/.test(password)

      return (
        password.length >= minLength &&
        hasNumber &&
        hasSpecialCharacter &&
        hasUpperCase &&
        hasLowerCase
      )
    }
    return false
  }

  useEffect(() => {
    setIsPasswordValid(validatePassword(member.password))
  }, [member])
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: postUser,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['user'] })
    },
  })

  const updateMutation = useMutation({
    mutationFn: putUser,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['user'] })
    },
  })

  const handleSubmit = (): void => {
    if (!isPasswordValid) {
      return
    }
    if (member.phoneNumber && member.phoneNumber[0] !== '+') {
      member.phoneNumber = '+' + member.phoneNumber
    }
    if (edit) {
      updateMutation.mutate(member)
    } else {
      mutation.mutate(member)
    }
    props.onClose()
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      {edit ? (
        <DialogTitle>Edit Member Info</DialogTitle>
      ) : (
        <DialogTitle>Add a New Member</DialogTitle>
      )}
      <DialogContent>
        <Typography variant='h6'>Member Account Information</Typography>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <Grid item xs={6}>
            <TextField
              margin='dense'
              label='First Name'
              type='text'
              value={member.firstName}
              onChange={(e) =>
                setMember({ ...member, firstName: e.target.value })
              }
              fullWidth
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin='dense'
              label='Last Name'
              type='text'
              value={member.lastName}
              onChange={(e) =>
                setMember({ ...member, lastName: e.target.value })
              }
              fullWidth
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin='dense'
              label='Date of Birth'
              type='text'
              placeholder='YYYY-MM-DD'
              value={member.dateOfBirth}
              onChange={(e) =>
                setMember({ ...member, dateOfBirth: e.target.value })
              }
              fullWidth
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin='dense'
              label='Phone Number'
              type='text'
              placeholder='+12223334444'
              value={member.phoneNumber}
              onChange={(e) =>
                setMember({ ...member, phoneNumber: e.target.value })
              }
              fullWidth
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin='dense'
              label='Email'
              type='text'
              value={member.email}
              onChange={(e) => setMember({ ...member, email: e.target.value })}
              fullWidth
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin='dense'
              label='Password'
              type='text'
              value={member.password}
              onChange={(e) =>
                setMember({ ...member, password: e.target.value })
              }
              fullWidth
              variant='outlined'
            />
            {member.password && (
              <div>
                <ul>
                  <li>
                    {member.password.length >= 8
                      ? '✅ Password minimum length: 8 character(s)'
                      : '❌ Password minimum length: 8 character(s)'}
                  </li>
                  <li>
                    {/\d/.test(member.password)
                      ? '✅ Contains at least 1 number'
                      : '❌ Must contain at least 1 number'}
                  </li>
                  <li>
                    {/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(
                      member.password,
                    )
                      ? '✅ Contains at least 1 special character'
                      : '❌ Must contain at least 1 special character'}
                  </li>
                  <li>
                    {/[A-Z]/.test(member.password)
                      ? '✅ Contains at least 1 uppercase letter'
                      : '❌ Must contain at least 1 uppercase letter'}
                  </li>
                  <li>
                    {/[a-z]/.test(member.password)
                      ? '✅ Contains at least 1 lowercase letter'
                      : '❌ Must contain at least 1 lowercase letter'}
                  </li>
                </ul>
              </div>
            )}
          </Grid>
        </Grid>
        <Typography variant='h6'>Location</Typography>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <Grid item xs={12}>
            <TextField
              margin='dense'
              label='Address Line 1'
              type='text'
              fullWidth
              value={member.addressLine1}
              onChange={(e) =>
                setMember({ ...member, addressLine1: e.target.value })
              }
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin='dense'
              label='Address Line 2'
              type='text'
              fullWidth
              value={member.addressLine2 || ''}
              onChange={(e) =>
                setMember({ ...member, addressLine2: e.target.value })
              }
              variant='outlined'
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin='dense'
              label='City'
              type='text'
              fullWidth
              value={member.city || ''}
              onChange={(e) => setMember({ ...member, city: e.target.value })}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin='dense'
              label='State'
              type='text'
              fullWidth
              variant='outlined'
              value={member.state || ''}
              onChange={(e) => setMember({ ...member, state: e.target.value })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin='dense'
              label='Zip Code'
              type='text'
              fullWidth
              variant='outlined'
              value={member.zipCode || ''}
              onChange={(e) =>
                setMember({ ...member, zipCode: e.target.value })
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant='outlined'
          sx={{ borderRadius: '100px', textTransform: 'none' }}
        >
          Close
        </Button>
        <Button
          sx={{ borderRadius: '100px', textTransform: 'none' }}
          variant='contained'
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

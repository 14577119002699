/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react'
import {
  AppBar,
  Toolbar as MUIToolbar,
  IconButton,
  OutlinedInput,
  InputAdornment,
} from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
import logo from '../public/logos/leading-icon.svg'
import Place from '@mui/icons-material/Place'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { ToolbarProps } from './Toolbar'
import { useNavigate, useLocation } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import { fetchOneUser } from '../api/users/users'
import { useQuery } from '@tanstack/react-query'

export default function SearchToolbar(props: ToolbarProps): JSX.Element {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const radius = searchParams.get('radius') || ''
  const [zip, setZip] = useState('')
  const { setOpen } = props
  const { userId, organizationId } = useContext(AuthContext)
  const navigate = useNavigate()

  const { data: user } = useQuery({
    queryKey: ['users', userId],
    queryFn: () => fetchOneUser(userId || ''),
  })

  const handleProfileClick = (): void => {
    if (user?.userType === 'ADMIN') {
      navigate(`/organization/${organizationId}`)
      return
    }
    navigate('/profile/saves/applications')
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    if (zip) {
      if (radius) {
        navigate(`/search?zipcode=${zip}&radius=${radius}`)
        return
      }
      navigate(`/search?zipcode=${zip}`)
      return
    }
    navigate('/search')
  }

  return (
    <AppBar elevation={0} position='static' sx={{ backgroundColor: 'white' }}>
      <MUIToolbar>
        <IconButton
          size='large'
          edge='start'
          aria-label='menu'
          sx={{ mr: 2 }}
          onClick={() => setOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <picture>
          <img
            src={logo}
            alt='HealthOPX'
            style={{ height: '40px' }}
            onClick={() => navigate('/search')}
          />
        </picture>
        <form
          onSubmit={handleSubmit}
          style={{ width: '100%', margin: '8px', marginRight: '10px' }}
        >
          <OutlinedInput
            sx={{
              width: '100%',
              margin: '8px',
            }}
            placeholder='Search for services by zipcode'
            onChange={(e) => setZip(e.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton aria-label='location' edge='end'>
                  <Place />
                </IconButton>
              </InputAdornment>
            }
          />
        </form>
        <AccountCircleIcon
          sx={{ color: 'black', fontSize: '24px', cursor: 'pointer' }}
          onClick={handleProfileClick}
        />
      </MUIToolbar>
    </AppBar>
  )
}

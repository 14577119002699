import { jwtDecode } from 'jwt-decode'
import { BASE_API_URL } from '../constants'
import { UserInfo } from '../../types/users'

const signUp = async (userInfo: UserInfo): Promise<Response> => {
  userInfo.confirmPassword = userInfo.password
  return fetch(`${BASE_API_URL}/v1/auth/sign-up`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'null',
    },
    body: JSON.stringify(userInfo),
  }).then(async (response) => {
    if (!response.ok) {
      const error = await response.json()
      throw new Error(error.message)
    }
    return response.json()
  })
}

export async function SignUp(userInfo: UserInfo): Promise<Response> {
  try {
    return await signUp(userInfo)
  } catch (e) {
    console.error(`Error creating new system user: ${e}`)
    throw e
  }
}

// const clearSession = () => {
//   localStorage.clear()
// }

interface AuthContext {
  accessToken: string
  idToken: string
  refreshToken: string
  tokenType: string
  expiresIn: number
  userType: string
}

interface AuthResponse {
  'cognito:username': string
  'custom:organizationId': string
  'custom:userId': string
}
const isTokenExpired = (token: string): boolean => {
  const decodedToken = jwtDecode(token)
  if (!decodedToken) return false
  return (decodedToken?.exp as number) < Date.now() / 1000
}

export const isAuthenticated = (): boolean => {
  const token = localStorage.getItem('token')

  return !!token && !isTokenExpired(token)
}

const setAuthContext = (
  authObj: AuthContext,
  setRefreshToken: boolean,
): void => {
  localStorage.setItem('token', authObj.idToken)
  if (setRefreshToken) {
    localStorage.setItem('r=', authObj.refreshToken)
  }
  const decoded: AuthResponse = jwtDecode(localStorage.getItem('token') || '')
  localStorage.setItem('u=', decoded['custom:userId'])
  localStorage.setItem('t=', authObj.userType)
  localStorage.setItem('org=', decoded['custom:organizationId'])
}

export const authenticateUser = async (
  email: string,
  password: string,
): Promise<UserInfo | undefined> => {
  return fetch(`${BASE_API_URL}/v1/auth/login`, {
    method: 'POST',
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  })
    .then(async (response) => {
      if (!response.ok) {
        const error = await response.json()
        throw new Error(error.message)
      } else {
        let user
        await response.json().then((json) => {
          user = json.body
          setAuthContext(json, true)
        })
        return user
      }
    })
    .catch((error) => {
      throw error
    })
}

export async function LoginUser(
  email: string,
  password: string,
): Promise<{ user?: UserInfo | null; error: string | null }> {
  let user = null
  let error = null
  try {
    user = await authenticateUser(email, password)
  } catch (err) {
    error = err as string
  }
  return { user, error }
}

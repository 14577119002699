import React from 'react'
import { Drawer, List, ListItem, Grid } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import logoBlue from '../public/logos/logo-blue.svg'
import useMediaQuery from '@mui/material/useMediaQuery'

export interface MenuProps {
  open: boolean
  setOpen: (open: boolean) => void
}
export default function Menu(props: MenuProps): JSX.Element {
  const { open, setOpen } = props
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ sx: { width: isMobile ? '100%' : '25%' } }}
    >
      <List>
        <Grid container sx={{ marginBottom: '10px' }}>
          <Grid item xs={2}>
            <CloseIcon
              onClick={() => setOpen(false)}
              sx={{ padding: '5px', cursor: 'pointer' }}
            />
          </Grid>
          <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center' }}>
            <picture>
              <img src={logoBlue} alt='HealthOPX' style={{ width: '140px' }} />
            </picture>
          </Grid>
        </Grid>
        <ListItem
          sx={{ marginBottom: '8px', cursor: 'pointer' }}
          onClick={() => {
            navigate('/search')
            setOpen(false)
          }}
        >
          Search for Services
        </ListItem>
        <ListItem
          sx={{ marginBottom: '8px', cursor: 'pointer' }}
          onClick={() => {
            navigate('/signup')
            setOpen(false)
          }}
        >
          Create an Account
        </ListItem>
        <ListItem sx={{ marginBottom: '8px', cursor: 'pointer' }}>
          Become a Partner
        </ListItem>
        <ListItem
          sx={{ marginBottom: '8px', cursor: 'pointer' }}
          onClick={() => {
            navigate('/terms-of-service')
            setOpen(false)
          }}
        >
          Terms of Service
        </ListItem>
      </List>
    </Drawer>
  )
}

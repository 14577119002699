import { UserInfo } from '../../types/users'
import { BASE_API_URL } from '../constants'

export const fetchUsersByNameAndDOB = async (
  firstName: string,
  lastName: string,
  dob: string,
): Promise<UserInfo> => {
  let url = `${BASE_API_URL}/v1/users/findUserByNameAndDOB`
  if (firstName === undefined || lastName === undefined || dob === undefined) {
    throw new Error('filters required to fetch members')
  }
  const queryParams = new URLSearchParams({
    firstName,
    lastName,
    dateOfBirth: dob,
  })
  url += `?${queryParams}`
  const token = localStorage.getItem('token')
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch members')
  }
  return response.json()
}

export const fetchUsers = async (
  organizationId?: string,
  role?: string,
): Promise<UserInfo[]> => {
  let url = `${BASE_API_URL}/v1/users`
  if (organizationId) {
    const queryParams = new URLSearchParams({ organizationId })
    url += `?${queryParams}`
  }
  if (role) {
    if (url.includes('?')) {
      url += `&role=${role}`
    } else {
      url += `?role=${role}`
    }
  }
  const token = localStorage.getItem('token')
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch members')
  }
  return response.json()
}

export const fetchOneUser = async (id: string): Promise<UserInfo> => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_API_URL}/v1/users/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw new Error('Failed to fetch member')
  }
  return response.json()
}

export const postUser = async (user: UserInfo): Promise<UserInfo[]> => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_API_URL}/v1/users`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  })
  if (!response.ok) {
    throw new Error('Failed to create user')
  }
  return response.json()
}

export const putUser = async (user: UserInfo): Promise<UserInfo[]> => {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_API_URL}/v1/users/${user.id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  })
  if (!response.ok) {
    throw new Error('Failed to update user')
  }
  return response.json()
}

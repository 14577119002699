import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import React, { useState } from 'react'
import {
  getMembersReferred,
  getTotalReferralAgencies,
  getTotalReferredTo,
  getTotalRequestComplete,
  getTotalRequestReceived,
  getTotalRequestSent,
} from '../../api/analytics/analytics'
import { useQuery } from '@tanstack/react-query'

interface OrgAnalyticsProps {
  orgId: string
}

const getStartDateForPeriod = (monthsAgo: number): string => {
  const date = new Date()
  date.setMonth(date.getMonth() - monthsAgo)
  return date.toISOString()
}

const dateOptions = [
  { name: 'Last Month', value: '0', iso: getStartDateForPeriod(1) },
  { name: 'Last 3 Months', value: '1', iso: getStartDateForPeriod(3) },
  { name: 'Last 6 Months', value: '2', iso: getStartDateForPeriod(6) },
  { name: 'Last Year', value: '3', iso: getStartDateForPeriod(12) },
]
export default function OrgAnalytics(props: OrgAnalyticsProps): JSX.Element {
  const [date, setDate] = useState('0')
  const { data: requestsReceived } = useQuery({
    queryKey: ['requestsReceived' + dateOptions[Number(date)].iso],
    queryFn: () =>
      getTotalRequestReceived(props.orgId, dateOptions[Number(date)].iso),
  })
  const { data: membersReferred } = useQuery({
    queryKey: ['membersReferred' + dateOptions[Number(date)].iso],
    queryFn: () =>
      getMembersReferred(props.orgId, dateOptions[Number(date)].iso),
  })
  const { data: totalReferredTo } = useQuery({
    queryKey: ['totalReferredTo' + dateOptions[Number(date)].iso],
    queryFn: () =>
      getTotalReferredTo(props.orgId, dateOptions[Number(date)].iso),
  })
  const { data: totalRequestSent } = useQuery({
    queryKey: ['totalRequestSent' + dateOptions[Number(date)].iso],
    queryFn: () =>
      getTotalRequestSent(props.orgId, dateOptions[Number(date)].iso),
  })
  const { data: totalRequestComplete } = useQuery({
    queryKey: ['totalRequestComplete' + dateOptions[Number(date)].iso],
    queryFn: () =>
      getTotalRequestComplete(
        props.orgId,
        false,
        dateOptions[Number(date)].iso,
      ),
  })
  const { data: inboundRequestsComplete } = useQuery({
    queryKey: ['inboundRequestsComplete' + dateOptions[Number(date)].iso],
    queryFn: () =>
      getTotalRequestComplete(props.orgId, true, dateOptions[Number(date)].iso),
  })
  const { data: totalReferralAgencies } = useQuery({
    queryKey: ['totalReferralAgencies' + dateOptions[Number(date)].iso],
    queryFn: () =>
      getTotalReferralAgencies(props.orgId, dateOptions[Number(date)].iso),
  })

  // const average = totalReferredTo?.reduce(
  //   (avg, val) => {
  //     avg.completed += val.completedCount
  //     avg.totalMembers += val.totalCount
  //     return avg
  //   },
  //   { totalAvg: 0, completed: 0, totalMembers: 0 },
  // )
  console.log('total referred to', totalReferredTo)

  const requestsByService = requestsReceived?.reduce(
    (accumulator, app) => {
      if (accumulator[app.service.name]) {
        accumulator[app.service.name]++
      } else {
        accumulator[app.service.name] = 1
      }
      return accumulator
    },
    {} as Record<string, number>,
  )

  return (
    <Grid
      item
      container
      xs={12}
      sx={{ margin: '0 15px 15px 15px' }}
      spacing={3}
    >
      <Grid item xs={8} sx={{ marginBottom: '15px' }}>
        <Typography variant='h5'>Analytics</Typography>
        <Typography variant='subtitle1'>
          Here's what's happening with your organization today:
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth variant='outlined'>
          <InputLabel id='date-range-select-label'>
            Select Date Range
          </InputLabel>
          <Select
            labelId='date-range-select-label'
            id='date-range-select'
            value={date}
            onChange={(e) => setDate(e.target.value)}
            label='Select Date Range'
          >
            {dateOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <Card>
          <CardContent>Total Requests</CardContent>
          <CardHeader title={totalRequestSent?.length} />
        </Card>
      </Grid>
      <Grid item xs={3}>
        <Card>
          <CardContent>Total Requests Completed</CardContent>
          <CardHeader title={totalRequestComplete?.length} />
        </Card>
      </Grid>
      <Grid item xs={3}>
        <Card>
          <CardContent>Inbound Requests Received</CardContent>
          <CardHeader title={requestsReceived?.length} />
        </Card>
      </Grid>
      <Grid item xs={3}>
        <Card>
          <CardContent>Inbound Requests Completed</CardContent>
          <CardHeader title={inboundRequestsComplete?.length} />
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <CardContent
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Typography>Members Referred By Organization</Typography>
              {totalReferralAgencies?.map((ref, idx) => {
                if (ref.organization.name) {
                  return (
                    <Typography key={idx}>
                      {ref.organization.name}: {ref.membersServed}
                    </Typography>
                  )
                }
                return null // Ensure a fallback return to avoid undefined map item issue
              })}
            </div>
            <Typography
              variant='h5'
              sx={{ alignSelf: 'flex-start' }}
            >{`Total: ${membersReferred}`}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <CardContent
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Typography>Requests Received By Service</Typography>
              {Object.entries(requestsByService || []).map(
                ([serviceName, count]) => (
                  <Typography key={serviceName}>
                    {`${serviceName}: ${count}`}
                  </Typography>
                ),
              )}
            </div>
            <Typography
              variant='h5'
              sx={{ alignSelf: 'flex-start' }}
            >{`Total: ${membersReferred}`}</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

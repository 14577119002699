import { TableRow, TableCell, Skeleton } from '@mui/material'
import React from 'react'

interface TableSkeletonProps {
  columns: number
}
export function TableSkeleton(props: TableSkeletonProps): JSX.Element {
  const generateSkeletons = (): JSX.Element[] => {
    const cells: JSX.Element[] = []
    for (let i = 0; i < props.columns; i++) {
      cells.push(
        <TableCell component='th' scope='row' key={i}>
          <Skeleton animation='wave' variant='text' />
        </TableCell>,
      )
    }
    return cells
  }
  return <TableRow>{generateSkeletons()}</TableRow>
}

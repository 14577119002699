/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import Login from './Login'
import Signup from './Signup'
import logo from '../public/logos/logo-purple.svg'

export default function Account(): JSX.Element {
  const location = useLocation()
  const [openConfirm, setOpenConfirm] = useState(false)
  const [tab, setTab] = useState(0)

  const isEmployee = location.pathname.includes('employee')

  const signupPath = isEmployee ? '/employee/signup' : '/signup'
  const loginPath = '/login'
  const navigate = useNavigate()

  const handleCloseConfirm = (): void => {
    navigate(loginPath)
    setOpenConfirm(false)
  }

  useEffect(() => {
    if (!isEmployee) {
      if (location.pathname.includes('signup')) setTab(1)
      if (location.pathname.includes('login')) setTab(0)
    }
  }, [location, setTab, isEmployee])

  return (
    <Grid
      container
      sx={{
        backgroundColor: 'rgb(239,239,239)',
        padding: '32px 22px 32px 22px',
      }}
    >
      <Grid item xs={12} justifyContent='center' display='flex'>
        <Typography variant='h4' sx={{ color: '#6750A4' }}>
          Welcome to HealthOpX
        </Typography>
      </Grid>
      {isEmployee && (
        <Grid item xs={12} justifyContent='center' display='flex'>
          <Typography variant='h5' sx={{ color: '#6750A4' }}>
            Employee Sign Up
          </Typography>
        </Grid>
      )}
      <Dialog open={openConfirm} onClose={handleCloseConfirm}>
        <DialogTitle>Account Confirmation</DialogTitle>
        <DialogContent>
          Your account has successfully been created! You will recieve an email
          shortly to confirm your account. Once confirmed you will be able to
          access HealthOpX.
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleCloseConfirm}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {isEmployee ? (
        <Signup setOpen={setOpenConfirm} isEmployee={isEmployee} />
      ) : (
        <Grid item xs={12} sx={{ marginTop: '32px' }}>
          <Tabs
            value={tab}
            onChange={(_, newValue) => {
              if (newValue === 0) navigate(loginPath)
              if (newValue === 1) navigate(signupPath)
              setTab(newValue)
            }}
          >
            <Tab label='Sign In' sx={{ textTransform: 'none' }} />
            <Tab label='New Account' sx={{ textTransform: 'none' }} />
          </Tabs>
          {tab === 0 && <Login />}
          {tab === 1 && (
            <Signup setOpen={setOpenConfirm} isEmployee={isEmployee} />
          )}
        </Grid>
      )}
      <Grid item xs={12} sx={{ marginTop: '64px', marginBottom: '32px' }}>
        <picture
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={logo}
            alt='HealthOPX'
            style={{ width: '100px' }}
            onClick={() => navigate('/search')}
          />
        </picture>
      </Grid>
    </Grid>
  )
}

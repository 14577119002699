/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  Typography,
} from '@mui/material'
import React from 'react'
import { Application } from '../../types/applications'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { archiveApplication } from '../../api/applications/applications'

interface ArchiveApplicationDialogProps {
  open: boolean
  onClose: () => void
  app: Application
}

export function ArchiveApplicationDialog(
  props: ArchiveApplicationDialogProps,
): JSX.Element {
  const { open, onClose, app } = props
  const queryClient = useQueryClient()

  const archiveMutation = useMutation({
    mutationFn: archiveApplication,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['archiveApplication'] })
    },
  })

  const handleArchive = (): void => {
    archiveMutation.mutate({
      id: app.id,
      archived: Boolean(app.archivedAt),
    })
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        {!app.archivedAt ? (
          <Typography>
            Once archived, members will not be able to be assigned to this
            application.
          </Typography>
        ) : (
          <Typography>
            Unarchiving this application will allow members to once again have
            access to this application.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant='outlined'
          sx={{ borderRadius: '100px', textTransform: 'none' }}
        >
          Close
        </Button>
        <Button
          sx={{ borderRadius: '100px', textTransform: 'none' }}
          variant='contained'
          onClick={handleArchive}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
